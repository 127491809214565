import React, { useEffect } from 'react'
import MainNavBar from '../../../components/loggedIn/navigation/mainNavBar'
import SideNav from '../../../components/loggedIn/navigation/sideNav'
import Feed from '../../../components/loggedIn/matching/feed'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { vendureAPI } from '../../../logic/services/vendure'
import userAPI from '../../../logic/services/user';
import { appAtoms, storesAtoms } from '../../../logic/store/store';
import { useRecoilState } from 'recoil';
import StoreCarousel from '../../../components/loggedIn/store/deprecated.storeCarousel';
import { Input } from 'antd';
import ProductGrid from '../../../components/loggedIn/store/productGrid';
import { RESPONSIVENESS_PARAMETER } from '../../../constants';
import TopNavBar from '../../../components/loggedIn/navigation/topNav';
import { Loader } from '@chatscope/chat-ui-kit-react';
import { useLanguage } from '../../../lang/utils/utils';

// /user_id_1684414611875_500236

export default function StoreLanding() {
    const [stores, setStores] = React.useState([])
    const [searchParams] = useSearchParams();
    const [otherUserProfile, setOtherUserProfile] = useRecoilState(storesAtoms.otherUserProfileAtom)
    const [selectedStoreToken, setSelectedStoreToken] = useRecoilState(storesAtoms.selectedVendorAtom)
    const [storeProducts, setStoreProducts] = React.useState([])
    const [responsiveness, setResponsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)
    const pageLang = useLanguage("screens.store")
    const otherUser = searchParams.get('otherUser');

    const navigation = useNavigate();

    useEffect(() => {
        if (!otherUser) {
            return navigation('/')
        }

        userAPI.getUserProfile(otherUser)
            .then(res => {
                if (res) {
                    setOtherUserProfile(res)
                    vendureAPI.getStoresInCountry(res?.country).then(res => {
                        if (res) {
                            setStores(res)
                            setSelectedStoreToken(res[0]?.token)
                        }
                    })
                }
            })
    }, [otherUser])

    const width = window.innerWidth;
    const columnWidth = 150;
    const numberOfColumns = Math.floor(width / columnWidth);
    const calculatedColumnsMobile = Math.max(numberOfColumns, 1);


    useEffect(() => {
        if (!selectedStoreToken) return
        vendureAPI.getProducts(selectedStoreToken).then(res => {
            if (res) {
                setStoreProducts(res.map(product => ({
                    ...product,
                    store: stores.find(store => store.token === selectedStoreToken)
                })))
            }
        })
    }, [selectedStoreToken, stores])

    if (responsiveness == RESPONSIVENESS_PARAMETER.DESKTOP) {
        return (
            <div className='h-screen w-screen'>
                <div className='flex-1 flex flex-row h-full '>
                    <div className='h-full w-24'>
                        <SideNav />
                    </div>
                    <div className='flex-1 m-2 rounded-3xl my-6 max-w-7xl mx-auto'>
                        <h1 className='font-bold text-2xl text-gray-700'>{pageLang.title}</h1>
                        <h1 className='font-bold text-xl text-gray-400 mt-8 mb-2'>{pageLang.products_title}</h1>
                        <ProductGrid
                            items={storeProducts}
                        />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='h-screen w-screen'>
                <div className='flex-1 flex flex-col h-full '>
                    <TopNavBar
                        title={pageLang.title}
                        hasBack
                        hasSupport
                    />
                    <div className='flex-1 m-2 rounded-3xl my-6 max-w-7xl mx-auto p-4'>
                        {storeProducts.length == 0 && <div
                            className='flex flex-1 justify-center items-center'
                        >
                            <Loader />
                        </div>}
                        {storeProducts.length > 0 && <ProductGrid
                            columns={1}
                            items={storeProducts}
                        />}
                    </div>
                </div>
            </div>
        )
    }
}
