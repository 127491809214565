import React from 'react'
import MainNavBar from '../../components/loggedIn/navigation/mainNavBar'
import SideNav from '../../components/loggedIn/navigation/sideNav'
import Feed from '../../components/loggedIn/matching/feed'
import { RESPONSIVENESS_PARAMETER } from '../../constants'
import BottomNav from '../../components/loggedIn/navigation/bottomNav'
import { useRecoilState } from 'recoil'
import { appAtoms } from '../../logic/store/store'
import TopNavBar from '../../components/loggedIn/navigation/topNav'

export default function Home() {
    const [responsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)
    if (responsiveness == RESPONSIVENESS_PARAMETER.DESKTOP) {
        return (
            <div className='h-screen w-screen'>
                <div className='flex-1 flex flex-row h-full'>
                    <div className='h-full w-32'>
                        <SideNav />
                    </div>
                    <div className='flex-1 m-2 rounded-3xl my-6'>
                        <Feed />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='w-screen' style={{
                height:"calc(100vh - 8rem)"
            }}>
                <div className='flex-1 flex flex-col h-full'>
                    <div>
                        <TopNavBar hasLogo hasSettings hasLanguage />
                    </div>
                    <div className='flex-1 m-2' style={{
                        maxHeight:"calc(100vh - 7rem)"
                    }}>
                        <Feed />
                    </div>
                    <div className=''>
                        <BottomNav />
                    </div>
                </div>
            </div>
        )
    }
}
