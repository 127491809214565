import React, { useEffect, useState } from 'react'
import SideNav from '../../components/loggedIn/navigation/sideNav'
import ProgressBar from 'react-customizable-progressbar'
import userAPI from '../../logic/services/user'
import TextSCInput from '../../components/shared/inputs/selfContaining/textInput'
import DateSCInput from '../../components/shared/inputs/selfContaining/dateInput'
import GiftAddressScInput from '../../components/shared/inputs/selfContaining/giftAddressInput'
import InterestsSCInput from '../../components/shared/inputs/selfContaining/interestsInput'
import GenderSCSelect from '../../components/shared/inputs/selfContaining/multiGenderSelect'
import AgeRangeSCInput from '../../components/shared/inputs/selfContaining/ageRangeInput'
import LanguageBar from '../../components/shared/misc/languageBar'
import { Alert, Button, Skeleton } from 'antd'
import { useNavigate, useNavigation } from 'react-router-dom'
import { DETAIL_CATEGORIES, REGISTRATION_CHECKPOINT, RESPONSIVENESS_PARAMETER } from '../../constants'
import { detailSavers } from '../../logic/controllers/detailSavers'
import PhotoGrid from '../../components/shared/inputs/selfContaining/photoInput'
import UpgradeModalButton from '../../components/loggedIn/subscription/upgradeModal'
import { useRecoilState } from 'recoil'
import { appAtoms, userAtoms } from '../../logic/store/store'
import TopNavBar from '../../components/loggedIn/navigation/topNav'
import { List } from 'antd-mobile'
import { deleteUserAccessKey } from '../../logic/store/global'
import { useLanguage } from '../../lang/utils/utils'

function removeUndefinedValues(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, value]) => typeof value !== 'undefined'));
}



export default function Settings() {

    const [alldetails, setAllDetails] = useState({})
    const [userProgress, setUserProgress] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [newDetails, setNewDetails] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [changedFields, setChangedFields] = useState({})
    const [responsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)

    const pageLang = useLanguage("screens.settings")
    const componentLangs = useLanguage("components.self_containing_inputs")
    const detailCategoryLangs = useLanguage("common.detail_categories")

    const hasChanges = Object.keys(changedFields).length > 0

    const onSave = async () => {
        setIsSaving(true)
        const savePromises = Object.keys(changedFields).map(fieldKey => {
            return detailSavers[fieldKey](changedFields[fieldKey])
        })
        const saveResults = await Promise.all(savePromises)

        if (saveResults.some(result => !result)) {
            alert('Some fields failed to save')
            setIsSaving(false)
        } else {
            setIsSaving(false)
            setChangedFields({})
            userAPI.getCheckpoints().then(res => {
                if (res.success) {
                    setUserProgress(res.completionPercent)
                }
            })
        }

    }

    const getAlLDetails = () => {
        userAPI.getAllDetails().then(res => {
            if (res.success) {
                setAllDetails(res.data)
                setNewDetails(res.data)
                console.log(res.data);
                setIsLoading(false)
            }
        })
    }


    useEffect(() => {
        userAPI.getCheckpoints().then(res => {
            if (res.success) {
                setUserProgress(res.completionPercent)

            }
        })
        getAlLDetails()
    }, [])

    const navigate = useNavigate()
    const [isLoggedIn, setisLoggedIn] = useRecoilState(userAtoms.isLoggedInAtom)
    const handleLogout = () => {
        deleteUserAccessKey()
        setisLoggedIn(false)

    }

    if (responsiveness == RESPONSIVENESS_PARAMETER.DESKTOP) {
        if (isLoading) return (
            <div className='h-screen w-screen'>
                <div className='flex-1 flex flex-row h-full'>
                    <div className='h-full'>
                        <SideNav />
                    </div>
                    <div className='flex-1 m-2 rounded-3xl my-6'>
                        <div className='max-w-7xl w-full mx-auto rounded-3xl flex-1 p-4 space-y-2'>
                            <Skeleton active paragraph={{ rows: 4 }} />
                            <Skeleton active paragraph={{ rows: 4 }} />
                            <Skeleton active paragraph={{ rows: 4 }} />
                            <Skeleton active paragraph={{ rows: 4 }} />
                            <Skeleton active paragraph={{ rows: 4 }} />
                        </div>
                        <div className='mx-4 mt-2 flex justify-center'>
                            <LanguageBar dark />
                        </div>
                    </div>
                </div>
            </div >
        )
        return (
            <>
                {hasChanges && <div className='fixed -mt-16 z-40 w-screen left-0 p-4 flex justify-center items-center pb-8 top-full bg-red-500 border-t border-red-400'>
                    <Button style={{
                        background: "white"
                    }}
                        onClick={onSave}
                        loading={isSaving}
                    >
                        {pageLang.save_changes}
                    </Button>
                </div>}
                <div className='h-screen w-screen overflow-y-scroll'>
                    <div className='flex-1 flex flex-row h-full '>
                        <div className='h-full w-32'>
                            <SideNav />
                        </div>
                        <div className='flex-1 m-2 rounded-3xl my-6'>
                            <div className='max-w-7xl w-full mx-auto rounded-3xl flex-1 p-4'>
                                <div className='flex justify-center items-center flex-col'>
                                    <ProgressBar
                                        progress={userProgress}
                                        rotate={150}
                                        cut={120}
                                        radius={100}
                                        children={<div
                                            className='bg-cover transition-all absolute bg-center bg-no-repeat w-[160px] aspect-square rounded-full bg-red-200 top-0 m-10'
                                            style={{
                                                backgroundImage: `url(https://ichdich-images-prod.s3.eu-west-1.amazonaws.com/${alldetails?.otherImages?.["0"]?.s3Key})`,
                                            }}
                                        />}
                                    />
                                    <span className='m-auto font-bold text-2xl text-red-400 -mt-8 relative'>
                                        {userProgress}%
                                    </span>
                                </div>
                                <div className='mx-auto'>
                                    <h3 className='text-center font-semibold text-2xl'>
                                        {alldetails?.nickName}
                                    </h3>
                                    <p className='text-center'>
                                        {alldetails?.phone}
                                    </p>
                                </div>
                                <div className='my-8 flex justify-center items-center'>
                                    <UpgradeModalButton />
                                </div>
                            </div>
                            <div className='max-w-7xl w-full mx-auto rounded-3xl flex-1 p-4 mt-4'>
                                <h3 className='text-2xl font-bold text-gray-800'>
                                    {detailCategoryLangs[DETAIL_CATEGORIES.BASIC_INFO]}
                                </h3>
                            </div>
                            <div className='max-w-7xl bg-red-50 w-full mx-auto space-y-6 rounded-3xl flex-1 p-8 mt-4'>
                                <div className='space-x-6 flex-row flex flex-1'>
                                    <TextSCInput
                                        label={componentLangs[REGISTRATION_CHECKPOINT.NICKNAME].title}
                                        placeholder={componentLangs[REGISTRATION_CHECKPOINT.NICKNAME].placeholder}
                                        multiline={false}
                                        defaultValue={alldetails?.nickName}
                                        onChange={(value) => {
                                            setChangedFields({
                                                ...changedFields,
                                                [REGISTRATION_CHECKPOINT.NICKNAME]: value
                                            })
                                        }}
                                    />

                                    <TextSCInput
                                        label={componentLangs[REGISTRATION_CHECKPOINT.FULL_NAME].title}
                                        placeholder={componentLangs[REGISTRATION_CHECKPOINT.FULL_NAME].placeholder}
                                        onChange={(value) => {
                                            setChangedFields({
                                                ...changedFields,
                                                [REGISTRATION_CHECKPOINT.FULL_NAME]: value
                                            })
                                        }}
                                        defaultValue={alldetails?.fullName}
                                    />
                                </div>
                                <div className='space-x-6 flex-row flex flex-1'>
                                    <TextSCInput
                                        label={componentLangs[REGISTRATION_CHECKPOINT.EMAIL].title}
                                        placeholder={componentLangs[REGISTRATION_CHECKPOINT.EMAIL].placeholder}
                                        inputMode={"email"}
                                        onChange={(value) => {
                                            setChangedFields({
                                                ...changedFields,
                                                [REGISTRATION_CHECKPOINT.EMAIL]: value
                                            })
                                        }}
                                        defaultValue={alldetails?.email}
                                    />
                                    <DateSCInput
                                        label={componentLangs[REGISTRATION_CHECKPOINT.BIRTH_DATE].title}
                                        placeholder={componentLangs[REGISTRATION_CHECKPOINT.BIRTH_DATE].placeholder}
                                        onChange={(value) => {
                                            setChangedFields({
                                                ...changedFields,
                                                [REGISTRATION_CHECKPOINT.BIRTH_DATE]: value
                                            })
                                        }}
                                        defaultValue={alldetails?.birthdate}
                                    />


                                </div>
                            </div>
                            <div className='max-w-7xl w-full mx-auto rounded-3xl flex-1 p-4 mt-4'>
                                <h3 className='text-2xl font-bold text-gray-800'>
                                    {detailCategoryLangs["GIFTING"]}
                                </h3>
                            </div>
                            <div className='max-w-7xl bg-red-50 w-full mx-auto space-y-6 rounded-3xl flex-1 p-8 mt-4'>
                                <GiftAddressScInput
                                    label={componentLangs[REGISTRATION_CHECKPOINT.GIFT_ADDRESS].title}
                                    placeholder={componentLangs[REGISTRATION_CHECKPOINT.GIFT_ADDRESS].placeholder}
                                    onChange={(value) => {
                                        setChangedFields({
                                            ...changedFields,
                                            [REGISTRATION_CHECKPOINT.GIFT_ADDRESS]: removeUndefinedValues(value)
                                        })

                                    }}
                                    defaultValue={{
                                        country: alldetails?.giftAddress?.country,
                                        city: alldetails?.giftAddress?.city,
                                        zipCode: alldetails?.giftAddress?.zipCode,
                                        addressLine1: alldetails?.giftAddress?.addressLine1,
                                        addressLine2: alldetails?.giftAddress?.addressLine2,
                                    }}
                                />
                            </div>
                            <div className='max-w-7xl w-full mx-auto rounded-3xl flex-1 p-4 mt-4'>
                                <h3 className='text-2xl font-bold text-gray-800'>
                                    {detailCategoryLangs["ABOUT"]}
                                </h3>
                            </div>
                            <div className='max-w-7xl bg-red-50 w-full mx-auto rounded-3xl space-y-5 flex-1 p-8 mt-4'>
                                <TextSCInput
                                    defaultValue={alldetails?.bio}
                                    label={componentLangs[REGISTRATION_CHECKPOINT.BIO].title}
                                    placeholder={componentLangs[REGISTRATION_CHECKPOINT.BIO].placeholder}
                                    multiLine
                                    onChange={(value) => {
                                        setChangedFields({
                                            ...changedFields,
                                            [REGISTRATION_CHECKPOINT.BIO]: value
                                        })
                                    }}
                                />
                                <GenderSCSelect
                                    label={componentLangs[REGISTRATION_CHECKPOINT.GENDER].title}
                                    placeholder={componentLangs[REGISTRATION_CHECKPOINT.GENDER].placeholder}
                                    onChange={(value) => {
                                        setChangedFields({
                                            ...changedFields,
                                            [REGISTRATION_CHECKPOINT.GENDER]: value
                                        })
                                    }}
                                    defaultValue={alldetails?.gender}
                                />
                                <InterestsSCInput
                                    label={componentLangs[REGISTRATION_CHECKPOINT.INTERESTS].title}
                                    placeholder={componentLangs[REGISTRATION_CHECKPOINT.INTERESTS].placeholder}
                                    onChange={(value) => {
                                        setChangedFields({
                                            ...changedFields,
                                            [REGISTRATION_CHECKPOINT.INTERESTS]: value
                                        })
                                    }}
                                    defaultValue={alldetails?.interests}
                                />
                            </div>
                            <div className='max-w-7xl w-full mx-auto rounded-3xl flex-1 p-4 mt-4'>
                                <h3 className='text-2xl font-bold text-gray-800'>
                                    {detailCategoryLangs[DETAIL_CATEGORIES.PREFERENCES]}
                                </h3>
                            </div>
                            <div className='max-w-7xl space-y-3 bg-red-50 w-full mx-auto rounded-3xl flex-1 p-8 mt-4'>
                                <AgeRangeSCInput
                                    label={componentLangs[REGISTRATION_CHECKPOINT.AGE_PREF].title}
                                    placeholder={componentLangs[REGISTRATION_CHECKPOINT.AGE_PREF].placeholder}
                                    defaultValue={alldetails?.agePreferences}
                                    onChange={(value) => {
                                        setChangedFields({
                                            ...changedFields,
                                            [REGISTRATION_CHECKPOINT.AGE_PREF]: value
                                        })
                                    }}
                                />
                                <GenderSCSelect
                                    label={componentLangs[REGISTRATION_CHECKPOINT.GENDER].title}
                                    placeholder={componentLangs[REGISTRATION_CHECKPOINT.GENDER].placeholder}
                                    mode={"multiple"}
                                    onChange={(value) => {
                                        setChangedFields({
                                            ...changedFields,
                                            [REGISTRATION_CHECKPOINT.GENDER_PREF]: value
                                        })
                                    }}
                                    defaultValue={alldetails?.genderPreferences}
                                />
                            </div>
                            <div className='max-w-7xl w-full mx-auto rounded-3xl flex-1 p-4 mt-4'>
                                <h3 className='text-2xl font-bold text-gray-800'>
                                    {detailCategoryLangs[DETAIL_CATEGORIES.PHOTOS]}
                                </h3>
                            </div>
                            <div className='max-w-7xl mx-auto'>
                                <PhotoGrid
                                    flat
                                    onUpload={async (index, base64) => {
                                        const succes = (await userAPI.uploadPhoto(base64, userAPI.PHOTO_TYPES.OTHER, Number(index))).success
                                        if (succes) {
                                            getAlLDetails()
                                        }
                                        return succes

                                    }}
                                    images={alldetails?.otherImages}
                                    numberOfImages={9}
                                />
                            </div>
                            <div className='mx-4 mt-2 flex justify-center my-8'>
                                <LanguageBar dark />
                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    } else {
        if (isLoading) return (
            <div className='w-screen' style={{
                height: "calc(100vh - 8rem)"
            }}>
                <div className='flex-1 flex flex-col h-full '>
                    <TopNavBar
                        title={pageLang.title}
                        hasBack
                        hasSupport
                    />
                    <div className='flex-1 m-2 rounded-3xl my-6'>
                        <div className='max-w-7xl w-full mx-auto rounded-3xl flex-1 p-4 space-y-2'>
                            <Skeleton active paragraph={{ rows: 4 }} />
                            <Skeleton active paragraph={{ rows: 4 }} />
                            <Skeleton active paragraph={{ rows: 4 }} />
                            <Skeleton active paragraph={{ rows: 4 }} />
                            <Skeleton active paragraph={{ rows: 4 }} />
                        </div>
                        <div className='mx-4 mt-2 flex justify-center'>
                            <LanguageBar dark />
                        </div>
                    </div>
                </div>
            </div >
        )
        return (
            <>
                {hasChanges && <div className='fixed -mt-16 z-40 w-screen left-0 p-4 flex justify-center items-center pb-8 top-full bg-red-500 border-t border-red-400'>
                    <Button style={{
                        background: "white"
                    }}
                        onClick={onSave}
                        loading={isSaving}
                    >
                        {pageLang.save_changes}
                    </Button>
                </div>}
                <TopNavBar
                    title={pageLang.title}
                    hasBack
                    hasSupport
                />
                <div className=' w-screen overflow-y-scroll' style={{
                    height: "calc(100vh - 10rem)"
                }}>
                    <div className='flex-1 flex flex-col h-full '>
                        <div className='flex-1 m-2 rounded-3xl my-6 mb-12'>
                            <div className='max-w-7xl w-full mx-auto rounded-3xl flex-1 p-4'>
                                <div className='flex justify-center items-center flex-col'>
                                    <ProgressBar
                                        progress={userProgress}
                                        rotate={150}
                                        cut={120}
                                        radius={100}
                                        children={<div
                                            className='bg-cover transition-all absolute bg-center bg-no-repeat w-[160px] aspect-square rounded-full bg-red-200 top-0 m-10'
                                            style={{
                                                backgroundImage: `url(https://ichdich-images-prod.s3.eu-west-1.amazonaws.com/${alldetails?.otherImages?.["0"]?.s3Key})`,
                                            }}
                                        />}
                                    />
                                    <span className='-mt-6 font-bold text-2xl text-red-400 relative'>
                                        {userProgress}%
                                    </span>
                                </div>
                                <div className='mx-auto'>
                                    <h3 className='text-center font-semibold text-2xl'>
                                        {alldetails?.nickName}
                                    </h3>
                                    <p className='text-center'>
                                        {alldetails?.phone}
                                    </p>
                                </div>
                                <div className='my-8 flex justify-center items-center'>
                                    <UpgradeModalButton />
                                </div>
                            </div>
                            <List header={detailCategoryLangs[DETAIL_CATEGORIES.BASIC_INFO]}>
                                <List.Item>
                                    <TextSCInput
                                        label={componentLangs[REGISTRATION_CHECKPOINT.NICKNAME].title}
                                        placeholder={componentLangs[REGISTRATION_CHECKPOINT.NICKNAME].placeholder}
                                        multiline={false}
                                        defaultValue={alldetails?.nickName}
                                        onChange={(value) => {
                                            setChangedFields({
                                                ...changedFields,
                                                [REGISTRATION_CHECKPOINT.NICKNAME]: value
                                            })
                                        }}
                                    />
                                </List.Item>
                                <List.Item>
                                    <TextSCInput
                                        label={componentLangs[REGISTRATION_CHECKPOINT.FULL_NAME].title}
                                        placeholder={componentLangs[REGISTRATION_CHECKPOINT.FULL_NAME].placeholder}
                                        onChange={(value) => {
                                            setChangedFields({
                                                ...changedFields,
                                                [REGISTRATION_CHECKPOINT.FULL_NAME]: value
                                            })
                                        }}
                                        defaultValue={alldetails?.fullName}
                                    />
                                </List.Item>
                                <List.Item>
                                    <TextSCInput
                                        label={componentLangs[REGISTRATION_CHECKPOINT.EMAIL].title}
                                        placeholder={componentLangs[REGISTRATION_CHECKPOINT.EMAIL].placeholder}
                                        inputMode={"email"}
                                        onChange={(value) => {
                                            setChangedFields({
                                                ...changedFields,
                                                [REGISTRATION_CHECKPOINT.EMAIL]: value
                                            })
                                        }}
                                        defaultValue={alldetails?.email}
                                    />
                                </List.Item>
                                <List.Item>
                                    <DateSCInput
                                        label={componentLangs[REGISTRATION_CHECKPOINT.BIRTH_DATE].title}
                                        placeholder={componentLangs[REGISTRATION_CHECKPOINT.BIRTH_DATE].placeholder}
                                        onChange={(value) => {
                                            setChangedFields({
                                                ...changedFields,
                                                [REGISTRATION_CHECKPOINT.BIRTH_DATE]: value
                                            })
                                        }}
                                        defaultValue={alldetails?.birthdate}
                                    />
                                </List.Item>
                            </List>


                            <List header={detailCategoryLangs["GIFTING"]}>
                                <List.Item>
                                    <GiftAddressScInput
                                        flat
                                        label={componentLangs[REGISTRATION_CHECKPOINT.GIFT_ADDRESS].title}
                                        placeholder={componentLangs[REGISTRATION_CHECKPOINT.GIFT_ADDRESS].placeholder}
                                        onChange={(value) => {
                                            setChangedFields({
                                                ...changedFields,
                                                [REGISTRATION_CHECKPOINT.GIFT_ADDRESS]: removeUndefinedValues(value)
                                            })

                                        }}
                                        defaultValue={{
                                            country: alldetails?.giftAddress?.country,
                                            city: alldetails?.giftAddress?.city,
                                            zipCode: alldetails?.giftAddress?.zipCode,
                                            addressLine1: alldetails?.giftAddress?.addressLine1,
                                            addressLine2: alldetails?.giftAddress?.addressLine2,
                                        }}
                                    />
                                </List.Item>
                            </List>

                            <List header={detailCategoryLangs["ABOUT"]}>
                                <List.Item>
                                    <TextSCInput
                                        defaultValue={alldetails?.bio}
                                        label={componentLangs[REGISTRATION_CHECKPOINT.BIO].title}
                                        placeholder={componentLangs[REGISTRATION_CHECKPOINT.BIO].placeholder}
                                        multiLine
                                        onChange={(value) => {
                                            setChangedFields({
                                                ...changedFields,
                                                [REGISTRATION_CHECKPOINT.BIO]: value
                                            })
                                        }}
                                    />
                                </List.Item>
                                <List.Item>
                                    <GenderSCSelect
                                        label={componentLangs[REGISTRATION_CHECKPOINT.GENDER].title}
                                        placeholder={componentLangs[REGISTRATION_CHECKPOINT.GENDER].placeholder}
                                        onChange={(value) => {
                                            setChangedFields({
                                                ...changedFields,
                                                [REGISTRATION_CHECKPOINT.GENDER]: value
                                            })
                                        }}
                                        defaultValue={alldetails?.gender}
                                    />
                                </List.Item>
                                <List.Item>
                                    <InterestsSCInput
                                        centered
                                        label={componentLangs[REGISTRATION_CHECKPOINT.INTERESTS].title}
                                        placeholder={componentLangs[REGISTRATION_CHECKPOINT.INTERESTS].placeholder}
                                        onChange={(value) => {
                                            setChangedFields({
                                                ...changedFields,
                                                [REGISTRATION_CHECKPOINT.INTERESTS]: value
                                            })
                                        }}
                                        defaultValue={alldetails?.interests}
                                    />
                                </List.Item>
                            </List>


                            <List header={detailCategoryLangs[DETAIL_CATEGORIES.PREFERENCES]}>
                                <List.Item>
                                    <AgeRangeSCInput
                                        label={componentLangs[REGISTRATION_CHECKPOINT.AGE_PREF].title}
                                        placeholder={componentLangs[REGISTRATION_CHECKPOINT.AGE_PREF].placeholder}
                                        defaultValue={alldetails?.agePreferences}
                                        onChange={(value) => {
                                            setChangedFields({
                                                ...changedFields,
                                                [REGISTRATION_CHECKPOINT.AGE_PREF]: value
                                            })
                                        }}
                                    />
                                </List.Item>
                                <List.Item>
                                    <GenderSCSelect
                                        label={componentLangs[REGISTRATION_CHECKPOINT.GENDER_PREF].title}
                                        placeholder={componentLangs[REGISTRATION_CHECKPOINT.GENDER_PREF].placeholder}
                                        mode={"multiple"}
                                        onChange={(value) => {
                                            setChangedFields({
                                                ...changedFields,
                                                [REGISTRATION_CHECKPOINT.GENDER_PREF]: value
                                            })
                                        }}
                                        defaultValue={alldetails?.genderPreferences}
                                    />
                                </List.Item>
                            </List>

                            <List header={detailCategoryLangs[DETAIL_CATEGORIES.PHOTOS]}>
                                <List.Item>
                                    <PhotoGrid
                                        onUpload={async (index, base64) => {
                                            const succes = (await userAPI.uploadPhoto(base64, userAPI.PHOTO_TYPES.OTHER, Number(index))).success
                                            if (succes) {
                                                getAlLDetails()
                                            }
                                            return succes

                                        }}
                                        images={alldetails?.otherImages}
                                        numberOfImages={9}
                                    />
                                </List.Item>
                            </List>
                            <List header={pageLang.language}>
                                <List.Item clickable onClick={() => {
                                    navigate('/language')
                                }}>
                                    {pageLang.language}
                                </List.Item>
                            </List>
                            <List header={pageLang.logout}>
                                <List.Item clickable onClick={handleLogout}>
                                    {pageLang.logout}
                                </List.Item>
                            </List>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}

