import React, { useRef, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import registerAPI from '../../logic/services/register';
import { useLanguage } from '../../lang/utils/utils';
import ReactCodeInput from 'react-code-input'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button } from 'antd';
import authAPI from '../../logic/services/auth';
import { useRecoilState } from 'recoil';
import { appAtoms, userAtoms } from '../../logic/store/store';
import { RESPONSIVENESS_PARAMETER } from '../../constants';
import TopNavBar from '../../components/loggedIn/navigation/topNav';

let alreadyGotCode = false

export default function TwoFactorSms() {

    const [twoFactorTransactionId, setTwoFactorTransactionId] = React.useState('')
    const [responsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)

    const [isLoading, setIsLoading] = React.useState(false)
    const [isValid, setIsValid] = React.useState(false)
    const [error, setError] = useState(null)
    const [code, setCode] = React.useState('')

    const codeInputref = useRef(null)
    const pageLang = useLanguage("screens.two_factor_sms")

    const [searchParams] = useSearchParams();
    const phoneNumber = searchParams.get('phone');
    const flowType = searchParams.get('flowType');

    const navigation = useNavigate();

    if (!phoneNumber || !flowType) {
        navigation('/')
    }


    const handleGoBack = () => {
        navigation(-1)
    };

    const onSend = async (ovveride) => {
        let localCode = ovveride ?? code
        setIsLoading(true)
        if (localCode.length !== 4) {
            setIsValid(false)
            setIsLoading(false)
            return
        }
        const authRes = await authAPI.twoFactorSMSVeriy(
            twoFactorTransactionId,
            Number(localCode)
        )
        if (authRes?.twoFactorTransactionCode) {
            navigation('/password?twoFactorTransactionId=' + twoFactorTransactionId + '&flowType=' + flowType + '&twoFactorTransactionCode=' + authRes.twoFactorTransactionCode)
        } else {
            setError("invalid_credentials")
            setIsLoading(false)
        }
    }

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
    const getTwoFactorTransactionId = async (retryCount = 0) => {
        let localRetryCount = retryCount ?? 0
        if (isNaN(localRetryCount) || retryCount < 0) {
            localRetryCount = 0
        }

        console.log(retryCount);
        if (retryCount > 3) {
            return setError("server_error")
        }
        setSecondsTillNextCode(30)

        if (!phoneNumber) return
        alreadyGotCode = true

        const tidResult = await registerAPI.registerViaPhoneStepOneGetTransactionId(
            phoneNumber.replace(" ", "+")
        )
        if (tidResult?.twoFactorTransactionId) {
            setTwoFactorTransactionId(tidResult.twoFactorTransactionId)
        } else {
            getTwoFactorTransactionId(retryCount + 1)
        }
    }


    React.useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                onSend()
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []); // Empty dependency array ensures the effect runs only once on component mount

    const onCodeChange = (code) => {
        setCode(code)
        if (String(code).length > 3) {
            onSend(code)
        }
        setIsValid(true)

    }

    const [secondsTillNextCode, setSecondsTillNextCode] = React.useState(30)


    React.useEffect(() => {
        if (alreadyGotCode) return
        getTwoFactorTransactionId()
    }, [alreadyGotCode])

    React.useEffect(() => {
        if (secondsTillNextCode == 0) {
            return
        }
        setTimeout(() => {
            setSecondsTillNextCode(secondsTillNextCode - 1)
        }, 1000);
    }, [secondsTillNextCode])

    if (responsiveness == RESPONSIVENESS_PARAMETER.DESKTOP) {
        return (
            <div className='prose max-w-full w-screen h-screen flex justify-center items-center align-middle flex-row m-0 bg-red-500'>
                <div className='text-center w-80 m-0 p-8 rounded-2xl bg-white space-y-8'>
                    <div className="text-left" onClick={handleGoBack}>
                        <ArrowLeftOutlined className='text-gray-400 hover:text-blue-700 cursor-pointer' />
                    </div>
                    <span>
                        <h5 className='m-0 font-bold text-red-500'>{pageLang.navbar_title}</h5>
                        <h3 className='m-0'>{pageLang.navbar_subtitle}</h3>
                    </span>
                    <div className='flex flex-row justify-center items-center w-full mt-2'>
                        <ReactCodeInput
                            pattern='^[0-9]+$'
                            fields={4}
                            ref={codeInputref}
                            onChange={onCodeChange}
                        />
                    </div>
                    <div>
                        <span
                            onClick={secondsTillNextCode > 0 ? null : getTwoFactorTransactionId}
                            className={
                                `text-xs text-gray-400
                            ${secondsTillNextCode > 0 ?
                                    'cursor-not-allowed' :
                                    'cursor-pointer hover:text-blue-600'}`

                            }>
                            {pageLang.resend_code} {secondsTillNextCode}s
                        </span>
                    </div>
                    {error && <p className='text-center text-red-500'>
                        {pageLang.errors[error]}
                    </p>}
                    <Button
                        onClick={onSend}
                        loading={isLoading}
                        className='w-full mt-4'
                        danger
                        type='primary'
                        children={
                            pageLang.verify_button
                        } />

                    <a className="no-underline text-xs mt-4 hover:text-blue-600 cursor-pointer">
                        {pageLang.lost_access}
                    </a>
                </div>
            </div >
        )
    } else {
        return (
            <div className='h-screen w-screen flex-1 '>
                <TopNavBar
                    hasBack
                    hasSupport
                    hasLogo
                />
                <div className=' prose max-w-full h-full  w-screen flex-1 flex justify-center items-center align-middle flex-col m-0 bg-white'>
                    <div className='text-center flex-1 m-0 p-8 rounded-2xl bg-white space-y-8'>

                        <span>
                            <h5 className='m-0 font-bold text-red-500'>{pageLang.navbar_title}</h5>
                            <h3 className='m-0'>{pageLang.navbar_subtitle}</h3>
                        </span>
                        <div className='flex flex-row justify-center items-center w-full mt-2'>
                            <ReactCodeInput
                                pattern='^[0-9]+$'
                                fields={4}
                                ref={codeInputref}
                                onChange={onCodeChange}
                            />
                        </div>
                        <div>
                            <span
                                onClick={secondsTillNextCode > 0 ? null : getTwoFactorTransactionId}
                                className={
                                    `text-xs text-gray-400
                            ${secondsTillNextCode > 0 ?
                                        'cursor-not-allowed' :
                                        'cursor-pointer hover:text-blue-600'}`

                                }>
                                {pageLang.resend_code} {secondsTillNextCode}s
                            </span>
                        </div>
                        {error && <p className='text-center text-red-500'>
                            {pageLang.errors[error]}
                        </p>}
                        <Button
                            onClick={onSend}
                            loading={isLoading}
                            className='w-full mt-4'
                            danger
                            type='primary'
                            children={
                                pageLang.verify_button
                            } />

                        <a className="no-underline text-xs mt-4 hover:text-blue-600 cursor-pointer">
                            {pageLang.lost_access}
                        </a>
                    </div>
                </div >
            </div>
        )
    }

}
