import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import SideNav from '../../../components/loggedIn/navigation/sideNav';
import { vendureAPI } from '../../../logic/services/vendure';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import { removeHtmlTags } from '../../../logic/helpers/html';
import { Button } from 'antd';
import userAPI from '../../../logic/services/user';
import { chatAPI } from '../../../logic/services/chat';
import { useRecoilState } from 'recoil';
import { appAtoms } from '../../../logic/store/store';
import { RESPONSIVENESS_PARAMETER } from '../../../constants';
import TopNavBar from '../../../components/loggedIn/navigation/topNav';
import { useLanguage } from '../../../lang/utils/utils';
import { extractImageData } from '../../../logic/helpers/imageData';

export default function Product() {

    const [searchParams] = useSearchParams();
    const [product, setProduct] = React.useState({})
    const [selectedVariant, setSelectedVariant] = React.useState(null)
    const [store, setStore] = useState(null)
    const [responsiveness, setResponsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)

    const otherUser = searchParams.get('otherUser');
    const productId = searchParams.get('productId');
    const vChannelToken = searchParams.get('vChannelToken');
    const channelId = searchParams.get('channelId')
    const pageLang = useLanguage("screens.store")

    const navigation = useNavigate();


    const [isLoading, setIsLoading] = useState(false)
    const handleSendRequest = () => {

        setIsLoading(true)
        console.log(selectedVariant, product);
        const selectedVariantsku = product.variantList.items.find(x => x.id == selectedVariant).sku
        let selectedProduct = product
        //Simulate a request
        userAPI.createProductPrompt({
            productId: selectedProduct.id,
            sku: selectedVariantsku,
            otherUserId: otherUser,
            variantId: selectedProduct.variantList.items.find((item) => {
                return item.sku === selectedVariantsku
            }).id,
            sellerVendureToken: vChannelToken,
            sellerId: store.seller.id,
            channelId: channelId
        }).then((res) => {
            console.log(res);
            setIsLoading(false)
            navigation(-1)
        }).catch((err) => {
            setIsLoading(false)
            console.log(err)
        })

    }


    useEffect(() => {
        if (!otherUser || !productId || !vChannelToken || !channelId) {
            navigation('/')
        } else {
            vendureAPI.getProduct(productId, vChannelToken).then(res => {
                if (res) {
                    setProduct(res)
                    setSelectedVariant(res?.variants[0].id)
                }
            })
            vendureAPI.getStoreViaChannelId(vChannelToken).then(res => {
                setStore(res)
            })
        }
    }, [])

    const h = window.innerWidth
    if (responsiveness == RESPONSIVENESS_PARAMETER.DESKTOP) {
        return (
            <div className='h-[90vh] w-screen overflow-y-scroll'>
                <div className='flex-1 flex flex-row h-full justify-center items-center'>
                    <div className='h-full'>
                        <SideNav />
                    </div>
                    <div className='  rounded-3xl my-6 max-w-5xl m-auto bg-gray-100 p-8 h-min  '>
                        <h1 className='font-bold text-xl text-gray-400 mb-2'>Product</h1>
                        <Swiper
                            style={{
                                height: 350
                            }}
                            modules={[
                                Navigation,
                                Pagination
                            ]}
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation={true}
                            pagination={true}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            {product?.assets?.length > 0 && [
                                ...product?.assets,
                            ]?.map(asset => (
                                <SwiperSlide>
                                    <img
                                        className='h-full w-full object-contain'
                                        src={"https://ichdich-vendure.s3.amazonaws.com/" + extractImageData(asset?.preview)} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className=''>
                            <h1 className='font-bold text-xl text-gray-400 mt-2 mb-2'>{product?.name}</h1>
                            <p className='text-gray-500 text-sm'>{removeHtmlTags(product?.description)}</p>
                            <h5 className='mt-2 font-semibold'>Variants</h5>
                            <div className='flex flex-row flex-wrap mt-1'>
                                {product?.variants?.map(variant => (
                                    <div
                                        onClick={() => {
                                            setSelectedVariant(variant.id)
                                        }}
                                        className={`flex mr-2 flex-row items-center space-x-2 p-2 rounded-lg bg-white shadow-lg shadow-gray-100 cursor-pointer hover:border-red-700 border
                                    ${selectedVariant === variant?.id ? 'border-red-700' : 'border-gray-100'}
                                    `}>
                                        <p className='text-sm font-semibold'>{variant?.name}</p>
                                    </div>
                                ))}
                            </div>
                            <div className='flex flex-row space-x-8 justify-between'>
                                <div className=''>
                                    <div className='mb-2 mt-8'>
                                        <h3
                                            className='text-2xl font-bold mt-2'
                                        >{pageLang.from}</h3>
                                        <h5
                                            className='text-sm font-semibold'
                                        >{pageLang.inc_vat}</h5>
                                        <h3 className='text-2xl font-bold'>{product?.variants?.[0]?.price / 100} {product?.variants?.[0]?.currencyCode}</h3>
                                    </div>
                                    <div className='flex flex-row items-center space-x-2 mt-2'>
                                        <Button
                                            onClick={handleSendRequest}
                                            loading={isLoading}
                                            type='primary' className=' text-xs bg-blue-600'>
                                            {pageLang.send_gift}
                                        </Button>
                                    </div>
                                </div>
                                <div className='ml-auto bg-red-600 rounded-2xl mt-8 p-4 flex flex-col justify-around'>
                                    <h3
                                        className='text-sm font-semibold text-red-200'
                                    >
                                        {pageLang.send_gift}
                                    </h3>
                                    <h1
                                        className='text-2xl font-bold text-white'
                                    >
                                        {pageLang.salespoint_title}
                                    </h1>
                                    <p
                                        className='text-xs text-red-300'
                                    >
                                        {pageLang.salespoint_text}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    } else {
        return (
            <>
                <TopNavBar
                    hasBack
                    hasSupport
                    title={pageLang.product_title}
                />
                <div className='flex flex-col  justify-center items-center overflow-y-scroll'
                    style={{
                        height: "calc(100vh - 1rem)",
                        paddingTop:h
                    }}>

                    <div className='  rounded-3xl my-6 max-w-7xl m-auto bg-gray-100  h-min  '>
                        <Swiper
                            style={{
                                height: h,
                                width: h
                            }}
                            modules={[
                                Navigation,
                                Pagination
                            ]}
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation={true}
                            pagination={true}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            {product?.assets?.length > 0 && [
                                ...product?.assets,
                            ]?.map(asset => (
                                <SwiperSlide>
                                    <img
                                        className='h-[100vw] w-screen object-contain'
                                        src={"https://ichdich-vendure.s3.amazonaws.com/" + extractImageData(asset?.preview)} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className='p-5 flex-1 text-center'>
                            <h1 className='font-bold text-xl text-gray-400 mt-2 mb-2'>{product?.name}</h1>
                            <p className='text-gray-500 text-sm'>{removeHtmlTags(product?.description)}</p>
                            <h5 className='mt-2 font-semibold'>Variants</h5>
                            <div className='flex flex-row flex-wrap mt-4 justify-center'>
                                {product?.variants?.map(variant => (

                                    <div
                                        onClick={() => {
                                            setSelectedVariant(variant.id)
                                        }}
                                        className={`flex mb-2 flex-row items-center space-x-2 p-2 rounded-lg bg-white shadow-lg shadow-gray-100 cursor-pointer hover:border-red-700 border
                                    ${selectedVariant === variant?.id ? 'border-red-700' : 'border-gray-100'}
                                    `}>
                                        <p className='text-sm font-semibold'>{variant?.name}</p>
                                    </div>
                                ))}
                            </div>
                            <div className='flex flex-row space-x-8 justify-center items-center text-center '>
                                <div className=''>
                                    <div className='mb-2 mt-8'>
                                        <h3
                                            className='text-2xl font-bold mt-2'
                                        >{pageLang.from}</h3>
                                        <h5
                                            className='text-sm font-semibold'
                                        >{pageLang.inc_vat}</h5>
                                        <h3 className='text-2xl font-bold'>{product?.variants?.[0]?.price / 100} {product?.variants?.[0]?.currencyCode}</h3>
                                    </div>
                                    <div className='flex flex-row mx-auto justify-center items-center space-x-2 mt-2'>
                                        <Button
                                            classNames={"w-full"}
                                            onClick={handleSendRequest}
                                            loading={isLoading}
                                            type='primary' className=' text-xs bg-blue-600'>
                                            {pageLang.send_gift}
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

