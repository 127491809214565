import React, { useState } from 'react'
import { Form, Input, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom'
import { useLanguage } from '../../lang/utils/utils';
import PhoneInput from '../../components/shared/inputs/phone';
import LanguageBar from '../../components/shared/misc/languageBar';

import authAPI from '../../logic/services/auth';
import { setUserAccessKey } from '../../logic/store/global';
import { useRecoilState } from 'recoil';
import { appAtoms, userAtoms } from '../../logic/store/store';
import { RESPONSIVENESS_PARAMETER, TWO_FA_PROCESS_FLOW_TYPE } from '../../constants';
import * as AntdMobile from 'antd-mobile'
import registerAPI from '../../logic/services/register';
import MarketingStripe from '../../components/shared/marketingStripe/MarketingStripe';

export default function Login() {
    const [phone, setPhone] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [userId, setUserId] = useRecoilState(userAtoms.userIdAtom)
    const [isLoggedIn, setIsLoggedIn] = useRecoilState(userAtoms.isLoggedInAtom)
    const [responsiveness, setResponsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)
    const [error, setError] = useState(null)

    console.log(responsiveness);
    const navigation = useNavigate()
    const commonLang = useLanguage("common")
    const pageLang = useLanguage("screens.login")

    const onLogin = () => {
        setIsLoading(true)
        authAPI.loginUser(
            phone,
            password
        ).then((res) => {
            if (res.success) {
                console.log(res.data);
                setUserAccessKey(res.data.key)
                setUserId(res.data.userId)
                setIsLoggedIn(true)
            }
            setError("invalid_credentials")
            setIsLoading(false)

        }).finally(() => {
        })
    }

    const onRecovery = () => {
        if (!phone) return
        registerAPI.isPhoneUsed(phone).then((res) => {
            if (res == false || res == "truified") {
                setError("no_such_phone")
            } else {
                return navigation('/two-fa/sms?phone=' + phone + '&flowType=' + TWO_FA_PROCESS_FLOW_TYPE.RECOVERY)

            }
            setIsLoading(false)
        })

    }

    if (responsiveness == RESPONSIVENESS_PARAMETER.DESKTOP) {
        return (
            <>
                <div className='flex justify-center items-center mx-auto h-8 w-full'>
                    <LanguageBar dark />
                </div>
                <MarketingStripe />

                <div className="flex w-screen flex justify-center items-center" style={{
                    height: "calc(100vh - 7.5rem)",
                    overflow: "hidden"
                }}>
                    <div className='border-[0.5rem] border-red-300 bg-red-300 rounded-3xl p-2'>
                        <div className='border-[0.75rem] border-red-100 bg-red-100 rounded-2xl p-2'>
                            <div className="bg-white p-8  shadow-md w-96 rounded-lg">
                                <div className='flex-row flex justify-between'>
                                    <h2 className="text-2xl mb-4 font-bold text-red-700">{pageLang.navbar_title}</h2>
                                    <img
                                        className='mb-4 w-9 aspect-square rounded-sm'
                                        src='https://ichdich-website-static.s3.eu-central-1.amazonaws.com/logo%20%281%29.png'
                                    />
                                </div>
                                <Form layout="vertical" onFinish={onLogin}>
                                    <Form.Item label={commonLang.phone_number} className='mb-1'>
                                        <PhoneInput value={phone} onChange={setPhone} />
                                    </Form.Item>
                                    <div onClick={onRecovery} className='cursor-pointer'>
                                        <p className='text-right m-0'>{pageLang.forgot_password}</p>
                                    </div>
                                    <Form.Item label={commonLang.password}>
                                        <Input.Password onChange={(v) => {
                                            setPassword(v.target.value)
                                        }} value={password} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button loading={isLoading} onClick={onLogin} type="primary" danger block>{pageLang.login_button}</Button>
                                    </Form.Item>
                                    <Link to="/">
                                        <p className='text-center'>{pageLang.register_redirect_button}</p>
                                    </Link>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return (

            <div className="flex flex-col items-center justify-center h-screen bg-red-500 space-y-2">
                <MarketingStripe />
                <div className="bg-white p-8  shadow-md w-full  flex-1">
                    <div className='flex-row flex justify-between'>
                        <h2 className="text-2xl mb-4 font-bold text-red-700">{pageLang.navbar_title}</h2>
                        <img
                            className='mb-4 w-9 aspect-square rounded-sm'
                            src='https://ichdich-website-static.s3.eu-central-1.amazonaws.com/logo%20%281%29.png'
                        />
                    </div>
                    <Form layout="vertical" onFinish={onLogin}>
                        <Form.Item label={commonLang.phone_number} className='mb-1'>
                            <PhoneInput value={phone} onChange={setPhone} />
                        </Form.Item>
                        <div onClick={onRecovery} className='cursor-pointer'>
                            <p className='text-right m-0'>{pageLang.forgot_password}</p>
                        </div>
                        <Form.Item label={commonLang.password}>
                            <Input.Password onChange={(v) => {
                                setPassword(v.target.value)
                            }} value={password} />
                        </Form.Item>
                        <Form.Item>
                            {error && <p className='text-red-500 text-center font-medium mx-auto'>
                                {pageLang.errors[error]}
                            </p>}
                        </Form.Item>
                        <Form.Item>
                            <Button loading={isLoading} onClick={onLogin} type="primary" danger block>{pageLang.login_button}</Button>
                        </Form.Item>
                        <Link to="/">
                            <p className='text-center'>{pageLang.register_redirect_button}</p>
                        </Link>
                    </Form>
                    <div className='flex justify-center items-center mt-5'>
                        <LanguageBar dark />
                    </div>
                </div>
            </div>
        )
    }
}


