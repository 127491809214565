import React, { useEffect, useState } from 'react'
import { transformArray } from '../../../logic/helpers/arrayGroup'
import * as AntdIcons from '@ant-design/icons'
import { Button, Skeleton } from 'antd'
import recommendAPI from '../../../logic/services/recommendation'
import { useNavigate } from 'react-router-dom'
import WebLottie from '../../shared/misc/lottie'
import userAPI from '../../../logic/services/user'
import UpgradeModalButton from '../subscription/upgradeModal'
import { useRecoilState } from 'recoil'
import { appAtoms } from '../../../logic/store/store'
import { RESPONSIVENESS_PARAMETER } from '../../../constants'
import { JumboTabs, PullToRefresh } from 'antd-mobile'
import { useLanguage } from '../../../lang/utils/utils'
const generateRandomitems = (count) => {
    let items = []
    for (let i = 0; i < count; i++) {
        items.push({
            userId: i,
            otherImages: {
                0: "https://cataas.com/cat/says/hello" + i
            }
        })
    }
    return items
}


const FeedItem = ({ item }) => {
    const [isLiked, setIsLiked] = React.useState(item.wasUserAlreadyLiked)

    const handleLike = () => {
        if (isLiked) return
        setIsLiked(true)
        userAPI.likeUser(item.userId)

    }

    const navigate = useNavigate()

    console.log(item);
    return (
        <div
            className='w-full h-48 flex justify-center items-center flex-col'
        >
            <div
                onClick={() => {
                    navigate(`/profile?userId=${item?.userId}`)
                }}
                className={`
                flex-1 h-full aspect-square rounded-full
                bg-cover bg-center
                overflow-hidden
                
            `}
                style={{
                    backgroundImage: `url(https://ichdich-images-prod.s3.eu-west-1.amazonaws.com/${item?.userData?.otherImages?.[0]?.s3Key})`,
                }}>

            </div>
            <div className="w-48 relative bottom-10">
                <div className='flex flex-row space-between w-48  justify-between px-4'>
                    <div
                        onClick={() => {
                            navigate(`/profile?userId=${item?.userId}`)
                        }}
                        className='w-8 aspect-square bg-red-600 flex justify-center items-center rounded-full hover:bg-red-400 cursor-pointer'>
                        <AntdIcons.PlusOutlined className='text-white text-md' />
                    </div>
                    <div
                        onClick={handleLike}
                        className={`
                            w-8 aspect-square bg-red-600 flex justify-center items-center rounded-full hover:bg-red-400 cursor-pointer
                            ${isLiked || item?.wasUserAlreadyLiked ? "hidden" : ""}

                        `}

                    >
                        <AntdIcons.HeartOutlined className='text-white text-md' />
                    </div>
                </div>
                <div className=' w-48 flex justify-center relative -mt-2'>
                    <span className=' bg-white rounded-full px-3 py-0.5 shadow-xl shadow-red-50 text-[0.8rem]'>
                        {item?.userData?.nickName ?? item?.userData?.profile?.nickname}
                    </span>
                </div>
            </div>
        </div>
    )
}

const FeedHexaGrid = ({
    items,
    width = 4
}) => {
    //Group it by screen width item width is 150px
    //Get screen width first
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)

    const transformeditems = transformArray(
        items,
        width
    )

    return (
        <div className='flex flex-col '>
            {transformeditems.map((items) => {
                return <div className={`w-full flex flex-row justify-between ${items.groupShift ? "px-[12.5%]" : ""}`}>
                    {items.items.map((item) => {
                        return (
                            <FeedItem item={item} />
                        )
                    })}
                </div>
            }
            )}
        </div>
    )
}

const FEED_STATES = {
    LOADING: 'loading',
    ERROR: 'error',
    DISPLAY: 'display',
    EMPTY: 'empty'
}

const FEED_TYPES = {
    FEED: 'feed',
    DAILY_HOTTEST: 'daily_hottest',
    WEEKLY_HOTTEST: 'weekly_hottest',
    MONTHLY_HOTTEST: 'monthly_hottest'
}

const createRange = (start, end) => {
    let range = []
    for (let i = start; i < end; i++) {
        range.push(i)
    }
    return range
}

export default function Feed() {

    const componentLang = useLanguage("components.feed")

    const [items, setItems] = React.useState([])

    const getFeed = async () => {
        //Get feed
        await recommendAPI.getRecommendation().then(res => {
            if (res?.data?.length > 0) {
                setItems(res.data)
                setFeedState(FEED_STATES.DISPLAY)
            } else {
                setFeedState(FEED_STATES.EMPTY)
            }
        }).catch(() => {
            setFeedState(FEED_STATES.EMPTY)
        })
    }
    const getHottest = async (timeFrame) => {
        await recommendAPI.getHottest(timeFrame).then(res => {
            if (res?.length > 0) {
                setItems(res)
                setFeedState(FEED_STATES.DISPLAY)
            } else {
                setFeedState(FEED_STATES.EMPTY)
            }
        })
    }
    const [selectedfeedType, setSelectedFeedType] = React.useState(FEED_TYPES.FEED)

    const [responsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)

    const [feedState, setFeedState] = React.useState(
        FEED_STATES.LOADING
    )

    const refresh = async () => {
        setFeedState(FEED_STATES.LOADING)
        switch (selectedfeedType) {
            case FEED_TYPES.FEED:
                await getFeed()
                break;
            case FEED_TYPES.DAILY_HOTTEST:
                await getHottest("daily")
                break;
            case FEED_TYPES.WEEKLY_HOTTEST:
                await getHottest("weekly")
                break;
            case FEED_TYPES.MONTHLY_HOTTEST:
                await getHottest("monthly")
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        refresh()
    }, [selectedfeedType])

    const [isRefreshButtonLoading, setisRefreshButtonLoading] = useState(false)

    if (responsiveness == RESPONSIVENESS_PARAMETER.DESKTOP) {
        return (
            <div className='flex-1 space-y-2 h-full flex flex-col'>
                <div className='w-full py-4 bg-red-50 rounded-2xl flex px-4 flex-row max-w-7xl mx-auto'>
                    <div>
                        <h3 className='font-bold text-red-600'>{componentLang.title}</h3>
                        <div className='flex-row flex space-x-2  my-2'>
                            <div className='bg-red-600 rounded-md px-4 py-1 cursor-pointer hover:bg-red-300 transition' onClick={() => {
                                setFeedState(FEED_STATES.LOADING)
                                setSelectedFeedType(FEED_TYPES.FEED)
                            }}>
                                <h4 className='font-semibold text-[0.8rem] text-white '>
                                    {componentLang.tabs[FEED_TYPES.FEED].title}
                                </h4>
                            </div>
                            <div className='bg-red-600 rounded-md px-4 py-1 cursor-pointer hover:bg-red-300 transition' onClick={() => {
                                setFeedState(FEED_STATES.LOADING)
                                setSelectedFeedType(FEED_TYPES.DAILY_HOTTEST)
                            }}>
                                <h4 className='font-semibold text-[0.8rem] text-white '>
                                    {componentLang.tabs[FEED_TYPES.DAILY_HOTTEST].title}
                                </h4>
                            </div>
                            <div className='bg-red-600 rounded-md px-4 py-1 cursor-pointer hover:bg-red-300 transition' onClick={() => {
                                setFeedState(FEED_STATES.LOADING)
                                setSelectedFeedType(FEED_TYPES.WEEKLY_HOTTEST)
                            }}>
                                <h4 className='font-semibold text-[0.8rem] text-white '>
                                    {componentLang.tabs[FEED_TYPES.WEEKLY_HOTTEST].title}
                                </h4>
                            </div>
                            <div className='bg-red-600 rounded-md px-4 py-1 cursor-pointer hover:bg-red-300 transition' onClick={() => {
                                setFeedState(FEED_STATES.LOADING)
                                setSelectedFeedType(FEED_TYPES.MONTHLY_HOTTEST)
                            }}>
                                <h4 className='font-semibold text-[0.8rem] text-white '>
                                    {componentLang.tabs[FEED_TYPES.MONTHLY_HOTTEST].title}
                                </h4>
                            </div>
                        </div>
                        <p
                            className='text-xs text-gray-500'
                        >{componentLang.selling_point}</p>
                    </div>
                    <div
                        className='flex-1 flex justify-end items-center'
                    >
                        <Button
                            type='primary'
                            danger
                            loading={isRefreshButtonLoading}
                            onClick={async () => {
                                setisRefreshButtonLoading(true)
                                await refresh()
                                setisRefreshButtonLoading(false)
                            }}
                        >
                            {componentLang.refresh}
                        </Button>
                    </div>
                </div>
                <div
                    className=' pt-8 max-w-7xl flex-1 flex justify-center items-center flex-col w-full m-auto'>
                    {feedState == FEED_STATES.DISPLAY &&
                        <FeedHexaGrid items={items} />
                    }
                    {feedState == FEED_STATES.EMPTY &&
                        <div className='m-auto'>
                            <WebLottie
                                lottieStyle={{
                                    width: 300,
                                    height: 300,
                                }}
                                url={"https://ichdich-website-static.s3.eu-central-1.amazonaws.com/lf20_tlnwbaep.json"}
                            >
                                <img
                                    className={`rounded-full w-12 h-12`}
                                    src="https://ichdich-website-static.s3.eu-central-1.amazonaws.com/logo%20%281%29.png"
                                />
                            </WebLottie>
                            <div
                                className='flex flex-1 justify-center items-center'
                            >
                                <UpgradeModalButton />
                            </div>

                            <h1
                                className='text-center text-lg font-medium text-red-700 my-2
                            '
                            >
                                {componentLang.no_more}
                            </h1>
                            <div
                                className='flex-1 flex justify-center items-center'
                            >
                                <Button
                                    type='primary'
                                    danger
                                    loading={isRefreshButtonLoading}
                                    onClick={async () => {
                                        setisRefreshButtonLoading(true)
                                        await refresh()
                                        setisRefreshButtonLoading(false)
                                    }}
                                >
                                    {componentLang.refresh}
                                </Button>
                            </div>
                        </div>
                    }
                    {feedState == FEED_STATES.LOADING &&
                        createRange(0, 25).map((item) => {
                            return <Skeleton avatar active paragraph={{
                                rows: 1
                            }} />
                        })
                    }

                </div>
            </div>
        )
    } else {
        return (
            <div className='flex-1 space-y-2 h-full flex flex-col'>
                <JumboTabs onChange={(key) => {
                    setFeedState(FEED_STATES.LOADING)
                    setSelectedFeedType(key)
                }}>
                    <JumboTabs.Tab {...componentLang.tabs[FEED_TYPES.FEED]} key={FEED_TYPES.FEED} />
                    <JumboTabs.Tab {...componentLang.tabs[FEED_TYPES.DAILY_HOTTEST]} key={FEED_TYPES.DAILY_HOTTEST} />
                    <JumboTabs.Tab {...componentLang.tabs[FEED_TYPES.WEEKLY_HOTTEST]} key={FEED_TYPES.WEEKLY_HOTTEST} />
                    <JumboTabs.Tab {...componentLang.tabs[FEED_TYPES.MONTHLY_HOTTEST]} key={FEED_TYPES.MONTHLY_HOTTEST} />
                </JumboTabs>

                <div className=' pt-8 max-w-7xl flex-1  h-full flex justify-center items-center flex-col w-full m-auto'>
                    <PullToRefresh

                        onRefresh={async () => {
                            await refresh()
                        }}
                        renderText={status => {
                            return <div>{componentLang.loader_status_record[status]}</div>
                        }}
                    >
                        <div
                            className='flex-1 h-full w-screen min-h-[50vh]'
                        >
                            {feedState == FEED_STATES.DISPLAY &&
                                <FeedHexaGrid
                                    width={2}
                                    items={[
                                        ...items
                                    ]} />
                            }
                            {feedState == FEED_STATES.EMPTY &&
                                <div className='
                            m-auto
                        '>
                                    <WebLottie
                                        lottieStyle={{
                                            width: 300,
                                            height: 300,
                                        }}
                                        url={"https://ichdich-website-static.s3.eu-central-1.amazonaws.com/lf20_tlnwbaep.json"}
                                    >
                                        <img
                                            className={`rounded-full w-12 h-12`}
                                            src="https://ichdich-website-static.s3.eu-central-1.amazonaws.com/logo%20%281%29.png"
                                        />
                                    </WebLottie>
                                    <div
                                        className='flex flex-1 justify-center items-center'
                                    >
                                        <UpgradeModalButton />
                                    </div>

                                    <h1
                                        className='text-center text-lg font-medium text-red-700 my-1'
                                    >
                                        {componentLang.no_more}
                                    </h1>
                                    <p
                                        className='text-center'
                                    >{componentLang.pull_to_refresh}</p>
                                </div>
                            }
                            {feedState == FEED_STATES.LOADING &&
                                createRange(0, 5).map((item) => {
                                    return <Skeleton avatar active
                                        className='mx-auto'
                                        style={{
                                            width: 'calc(100vw - 5rem)'
                                        }}
                                        paragraph={{
                                            rows: 1,
                                        }} />
                                })
                            }
                        </div>
                    </PullToRefresh>

                </div>
            </div>
        )
    }
}
