import Cookies from 'js-cookie';

export const getDeviceId = () => {
  let deviceId = Cookies.get('deviceId');
  if (deviceId) {
    return Promise.resolve(deviceId);
  } else {
    const newDeviceId = generateDeviceId();
    Cookies.set('deviceId', newDeviceId);
    return Promise.resolve(newDeviceId);
  }
};

export const setVendureCookies = (cookies) => {
  Cookies.set('vendureCookies', JSON.stringify(cookies));
};

export const getVendureCookies = () => {
  try {
    const cookies = JSON.parse(Cookies.get('vendureCookies'));
    return Promise.resolve(cookies);
  } catch (error) {
    return Promise.resolve(null);
  }
};

const generateDeviceId = () => {
  const newDeviceId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
  return newDeviceId;
};

export const setUserAccessKey = (userAccessKey) => {
  Cookies.set('userAccessKey', userAccessKey);
};

export const deleteUserAccessKey = () => {
  Cookies.remove('userAccessKey');
};

export const getUserAccessKey = () => {
  const userAccessKey = Cookies.get('userAccessKey');
  return Promise.resolve(userAccessKey);
};

export const setAppLanguage = (appLanguage) => {
  Cookies.set('appLanguage', appLanguage);
};

export const getAppLanguage = () => {
  const appLanguage = Cookies.get('appLanguage');
  return Promise.resolve(appLanguage);
};
