import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { useLanguage } from '../../../lang/utils/utils';
import CheckoutForm from '../stripe/chechkout';
import { Elements, PaymentElement, useStripe } from '@stripe/react-stripe-js';
import { REGISTRATION_CHECKPOINT, STRIPE_PUBLIC_KEY } from '../../../constants';
import { loadStripe } from '@stripe/stripe-js';
import { Loader } from '@chatscope/chat-ui-kit-react';
import paymentAPI from '../../../logic/services/payment'
import userAPI from '../../../logic/services/user'
import TextSCInput from '../../shared/inputs/selfContaining/textInput';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

const UpgradeModalButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const componentLang = useLanguage("screens.upgrade")
    const inputlang = useLanguage("components.self_containing_inputs")
    const [clientSecret, setClientSecret] = useState(null)
    const [isStripeLoaded, setIsStripeLoaded] = useState(false)
    const [isMoreDataRequired, setIsMoreDataRequired] = useState(false)
    const [allDetails, setAlldetails] = useState({})
    const [detailInputs, setDetailInputs] = useState({})

    const purchase = () => {
        paymentAPI.purchaseSubscription("PREMIUM").then(x => {
            if (x.success == false && (x.status == 400 || x.data.msg == "Missing user data")) {
                userAPI.getAllDetails().then(res => {
                    setAlldetails(res.data)
                    if (res?.data?.fullName) {
                        setDetailInputs({
                            ...detailInputs,
                            [REGISTRATION_CHECKPOINT.FULL_NAME]: true
                        })
                    }
                    if (res?.data?.email) {
                        setDetailInputs({
                            ...detailInputs,
                            [REGISTRATION_CHECKPOINT.EMAIL]: true
                        })
                    }
                    setIsMoreDataRequired(true)
                })
            } else {
                setClientSecret(x.data.clientSecret)
                setIsStripeLoaded(true)
            }

        })
    }
    useEffect(() => {
        purchase()
    }, [])

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onSave = async () => {
        const promises = Object.keys(detailInputs).map(async detailInputkey => {
            const input = detailInputs[detailInputkey]
            if (input == true) return true //Meaning a flag which is not saved
            switch (detailInputkey) {
                case REGISTRATION_CHECKPOINT.FULL_NAME:
                    return (await userAPI.setDetail("name", "fullName", input)).success
                    break;
                case REGISTRATION_CHECKPOINT.EMAIL:
                    return (await userAPI.setDetail("email", "email", input)).success
                    break;
                default:
                    return false
                    break;
            }
        })
        if ((await Promise.all(promises)).some(x => x == false)) {
            alert(componentLang.errors.error_saving)
        } else {
            purchase()
            setDetailInputs({})
            setIsMoreDataRequired(false)
        }
    }

    return (
        <>
            <Button type="primary" className='bg-blue-600 mx-8 text-xs flex flex-wrap break-words '  onClick={showModal}>
                {componentLang.want_more}
            </Button>
            <Modal title="IchDich Plus" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        {componentLang.cancel_button}
                    </Button>
                ]}
            >
                {componentLang.benefits.map(benefit => {
                    return <div className='space-y-1 mb-4'>
                        <h2
                            className='text-lg font-bold'
                        >
                            {benefit.benefitTitle} {benefit.emoji}
                        </h2>
                        <p>
                            {benefit.benefitDescription}
                        </p>
                    </div>
                })}
                {isStripeLoaded && <Elements stripe={stripePromise} options={{
                    clientSecret
                }}>
                    <CheckoutForm />
                </Elements>}
                {!isStripeLoaded && !isMoreDataRequired && <div className='flex justify-center items-center flex-1 flex-col my-8'>
                    <Loader />
                    <h3
                        className='text-lg font-bold text-red-500'
                    >
                        {componentLang.loading}
                    </h3>
                </div>}
                {isMoreDataRequired && <div>
                    <TextSCInput
                        placeholder={inputlang[REGISTRATION_CHECKPOINT.FULL_NAME].placeholder}
                        label={inputlang[REGISTRATION_CHECKPOINT.FULL_NAME].title}
                        defaultValue={allDetails?.fullName}
                        onChange={(value) => {
                            setDetailInputs({
                                ...detailInputs,
                                [REGISTRATION_CHECKPOINT.FULL_NAME]: value
                            })
                        }}
                    />
                    <TextSCInput
                        placeholder={inputlang[REGISTRATION_CHECKPOINT.EMAIL].placeholder}
                        label={inputlang[REGISTRATION_CHECKPOINT.EMAIL].title}
                        defaultValue={allDetails?.email}
                        onChange={(value) => {
                            setDetailInputs({
                                ...detailInputs,
                                [REGISTRATION_CHECKPOINT.EMAIL]: value
                            })
                        }}
                    />
                    <div className='flex-1 flex justify-center items-center w-full'>
                        <Button
                            children={componentLang.save}
                            type="primary"
                            className='bg-blue-500 mx-auto my-2'
                            disabled={Object.keys(detailInputs).length !== 2}
                            onClick={onSave}
                        />
                    </div>
                </div>}
            </Modal>
        </>
    );
};

export default UpgradeModalButton;