import axios from "axios";
import { API_BASE_URL } from "../../constants";
import { getDeviceId, getUserAccessKey } from "../store/global";

const PAYMENT_API_BASE = "/payment/v1"

const getCustomerPortalSessionUrl = async () => {
    const myHeaders = {
        "x-device-id": await getDeviceId(),
        "x-key": await getUserAccessKey(),
        "Content-Type": "application/json"
    };

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const res = await axios.get(API_BASE_URL + PAYMENT_API_BASE + "/customer-portal", requestOptions);

        return {
            success: true,
            data: res.data.url
        }
    } catch (error) {
        console.log("Get customer portal error", error);
        return {
            success: false,
            code: "NO_PAYMENT_YET"
        }
    }
}
const getSubscription = async () => { }

const purchaseSubscription = async (planId) => {


    console.log(API_BASE_URL + PAYMENT_API_BASE + "/subscription/" + planId);
    try {
        const res = await axios.post(API_BASE_URL + PAYMENT_API_BASE + "/subscription/" + planId,{}, {
            headers: {
                "x-device-id": await getDeviceId(),
                "x-key": await getUserAccessKey(),
                "Content-Type": "application/json"
            }
        });

        return {
            success: true,
            data: res.data
        }
    } catch (error) {
        console.log(error, "purchaseSubscription");
        return {
            success: false,
            status:error.response.status,
            data:error.response.data
        }
    }

}

const paymentAPI = {
    postRegisterPurchase: () => { },
    generatePaymentStripe: () => { },
    getCustomerPortalSessionUrl,
    purchaseSubscription
}

export default paymentAPI
