import React, { useState, useRef } from 'react';
import { readAndCompressImage } from 'browser-image-resizer';
import { EditOutlined } from '@ant-design/icons';
import { useLanguage } from '../../../../lang/utils/utils';
import { REGISTRATION_CHECKPOINT } from '../../../../constants';

const config = {
    quality: 0.8,
    maxWidth: 1920,
    maxHeight: 1080,
    autoRotate: true,
    debug: true,
    mimeType: 'image/jpeg',
    initialAspectRatio: 9 / 16
};

export default function PhotoGrid({
    images: initialImages,
    onUpload,
    numberOfImages,
    flat = false
}) {
    function fillMissingKeys(obj) {
        const result = {};

        for (let i = 0; i <= 8; i++) {
            if (obj?.hasOwnProperty(i.toString())) {
                result[i] = obj[i];
            } else {
                result[i] = null;
            }
        }

        return result;
    }

    const [images, setImages] = useState(fillMissingKeys(initialImages) ?? {});
    const emptyGrids = numberOfImages - Object.keys(images).length;
    const fileInputRef = useRef(null);



    const handleUploadClick = index => {
        fileInputRef.current.index = index;
        fileInputRef.current.click();
    };

    const componentLang = useLanguage("components.self_containing_inputs")[REGISTRATION_CHECKPOINT.OTHER_PHOTOS]
    const handleFileChange = async event => {
        const file = event.target.files[0];
        try {
            const resizedImageBlob = await readAndCompressImage(file, config);
            const reader = new FileReader();
            reader.readAsDataURL(resizedImageBlob);
            reader.onloadend = async () => {
                const base64data = reader.result;
                setImages(prevImages => ({
                    ...prevImages,
                    [fileInputRef.current.index]: {
                        s3Key: base64data,
                        state: 'loading'
                    }
                }));
                const success = await onUpload(fileInputRef.current.index, base64data);
                setImages(prevImages => ({
                    ...prevImages,
                    [fileInputRef.current.index]: {
                        s3Key: base64data,
                        state: success ? 'display' : 'error'
                    }
                }));
            };
        } catch (err) {
            console.error(err);
        }
    };

    const getImageSrc = (image) => {
        return image.s3Key.startsWith('data:') ? image.s3Key : `https://ichdich-images-prod.s3.eu-west-1.amazonaws.com/${image.s3Key}`;
    };

    return (
        <div className={`${flat ? `grid-cols-6 grid gap-4` : "grid-cols-3 grid gap-4 "}`}>
            <input type="file" accept="image/*" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
            {Object.entries(images).map(([index, image]) => {
                if (image == null) {
                    return (
                        <div key={index} className="border-2 border-dashed border-gray-200 p-2 rounded-lg aspect-[9/16] relative">
                            <button
                                className="w-full h-full bg-gray-100 flex items-center justify-center absolute inset-0 text-xs"
                                onClick={() => handleUploadClick(index)}
                            >
                                {componentLang.upload}
                            </button>
                        </div>
                    )
                }

                return (
                    <div key={index} className="border-2 border-dashed border-gray-200 p-2 rounded-lg aspect-[9/16] relative overflow-hidden">
                        <img src={getImageSrc(image)} alt="Grid content" className="w-full h-full object-cover absolute inset-0" />
                        {image.state === 'loading' && <div className="absolute inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 text-xs text-white">{componentLang.loading}</div>}
                        {image.state === 'error' && (
                            <div className="absolute inset-0 flex items-center justify-center bg-red-500 bg-opacity-50 text-white flex flex-col">
                                <p>{componentLang.errors['error_loading']}</p>
                                <button onClick={() => handleUploadClick(index)} className="mt-2 px-4 py-2 bg-white text-red-500 rounded-md">{componentLang.retry}</button>
                            </div>
                        )}
                        {(image.state === 'display' || typeof images[index] == 'object') && (
                            <div onClick={() => handleUploadClick(index)} className="z-50 absolute bottom-2 cursor-pointer hover:bg-red-500 transition right-2 bg-red-700 rounded-full p-2 w-10 h-10 flex justify-center items-center">
                                <EditOutlined
                                    className='text-white'
                                />
                            </div>
                        )}
                    </div>
                )
            })}

        </div>
    );
}
