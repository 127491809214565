import { Button, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { removeHtmlTags } from '../../../logic/helpers/html'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useLanguage } from '../../../lang/utils/utils'
import { extractImageData } from '../../../logic/helpers/imageData'


export default function ProductGrid({
    items,
    columns = 4
}) {

    const navigate = useNavigate()
    const [search, setSearch] = useState(null)

    const [searchParams] = useSearchParams()
    const componentLang = useLanguage("components.product_grid")

    const otherUser = searchParams.get('otherUser');
    const channelId = searchParams.get('channelId')
    const f = (item) => {
        try {
            return item.name.toLowerCase().includes(search.toLowerCase())
        } catch (error) {
            return item
        }
    }


    const filteredItems = items.filter(f)


    return (
        <div>
            <Input.Search
                placeholder={componentLang.search}
                allowClear
                style={{ width: '100%' }}
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value)
                }}
            />
            <div className='flex overflow-y-scroll flex-wrap mt-4' style={{
                maxHeight: "calc(100vh - 10rem)"
            }}>
                {filteredItems.map((item) => (
                    <div className={`flex flex-col w-1/${columns} p-2`} onClick={() => {
                        const channelId = searchParams.get('channelId')
                        const openproduct = async () => {
                            if (!(item?.store?.token)) {
                                setTimeout(openproduct, 250)
                            } else {
                                navigate(`/product?otherUser=${otherUser}&productId=${item.id}&vChannelToken=${item.store.token}&channelId=${channelId}`)
                            }
                        }
                        openproduct()
                    }}>
                        <div className='flex-1 flex flex-col rounded-3xl bg-white shadow-lg shadow-gray-100 cursor-pointer hover:border-red-700 border p-4'>
                            <div className='flex-1 mb-4'>
                                <img
                                    className='rounded-3xl'

                                    src={"https://ichdich-vendure.s3.amazonaws.com/" + extractImageData(item?.featuredAsset?.preview)}
                                />
                            </div>
                            <div className='flex-1 p-2'>
                                <p className='text-sm font-semibold text-gray-500 mb-1 ml-0.5 '>
                                    {item.name}
                                </p>
                                <p className='text-xs font-semibold text-gray-500 mb-1 ml-0.5 '>
                                    {removeHtmlTags(item.description)?.slice(0, 100)}
                                </p>
                                <p>{componentLang.from}</p>
                                <p className='text-xs font-semibold text-gray-500 mb-1 ml-0.5 '>
                                    {
                                        Number(item.variantList.items[0].price / 100).toFixed(2)
                                    }
                                    {" "}
                                    {
                                        item.variantList.items[0].currencyCode
                                    }
                                </p>
                            </div>
                            <Button type='primary' danger children={componentLang.see_this} className='rounded-3xl' />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
