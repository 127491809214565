import axios from 'axios';
import { deleteUserAccessKey, getDeviceId, getUserAccessKey, setUserAccessKey } from '../store/global'

import { API_BASE_URL } from '../../constants';
import { getDeviceCountry } from './ip';

const REGISTER_API_BASE = "/register/v1"

const isPhoneUsed = async (phoneNumber) => {
    try {
        console.log(API_BASE_URL + REGISTER_API_BASE + '/register/check-phone');
        const response = await axios.post(
            API_BASE_URL + REGISTER_API_BASE + '/register/check-phone',
            {
                phoneNumber
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return response.data.data.isPhoneUsed;
    } catch (error) {
        console.error(error, "isPhoneUsed");
        return "truified";
    }
};

const registerViaPhoneStepOneGetTransactionId = async (phoneNumber) => {
    try {
        const data = JSON.stringify({ phoneNumber });
        const deviceId = await getDeviceId();

        const config = {
            method: 'POST',
            url: API_BASE_URL + REGISTER_API_BASE + '/register/via-phone/verify',
            headers: {
                'x-device-id': deviceId,
                'Content-Type': 'application/json'
            },
            data
        };
        console.log(config);

        const response = await axios(config);
        return {
            twoFactorTransactionId: response.data.data.twoFactorTransactionId
        };
    } catch (error) {
        console.log(error);
        return null;
    }
};

const registerViaPhoneStwpTwoSetPassword = async (twoFactorTransactionId, twoFactorTransactionCode, password) => {
    let countryCode = "UNKNOWN";

    try {
        countryCode = await getDeviceCountry()
    } catch (error) {
        //It is ok to fail here as it is not critical to the registration process
    }

    const data = JSON.stringify({
        password,
        twoFactorTransactionId,
        twoFactorTransactionCode,
        country: countryCode
    });

    const deviceId = await getDeviceId();

    const config = {
        method: 'post',
        url: API_BASE_URL + REGISTER_API_BASE + '/register/via-phone',
        headers: {
            'x-device-id': deviceId,
            'Content-Type': 'application/json'
        },
        data
    };

    try {
        const res = (await axios(config)).data.data;
        await deleteUserAccessKey();
        await setUserAccessKey(res.key);
        return res;
    } catch (error) {
        return false;
    }
};

const registerAPI = {
    isPhoneUsed,
    registerViaPhoneStepOneGetTransactionId,
    registerViaPhoneStwpTwoSetPassword,
};


export default registerAPI