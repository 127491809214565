export function extractImageData(url) {
    const regex = /^(.*\/)([^\/?]+)(\?.*)?$/;
    const matches = url.match(regex);

    if (matches && matches.length >= 3) {
        const imagePath = matches[1];
        const fileName = matches[2];
        const query = matches[3] || '';

        const extension = fileName.substr(fileName.lastIndexOf('.') + 1);

        return  fileName + query;
    }

    return null; // Invalid URL
}
