import axios from "axios";
import { getDeviceId, getUserAccessKey } from "../store/global";
import { API_BASE_URL } from "../../constants";

const USER_API_BASE = "/user/v1"

const PHOTO_TYPES = {
    RAW: 'raw',
    OTHER: 'other'
}
const uploadPhoto = async (photoBase64, photoType, pos) => {
    try {
        const headers = {
            'x-device-id': await getDeviceId(),
            'x-key': await getUserAccessKey(),
            'Content-Type': 'application/json',
        };

        const data = JSON.stringify({
            type: photoType,
            position: pos,
            base64: photoBase64,
        });

        try {
            const response = await axios.post(
                API_BASE_URL + USER_API_BASE + '/details/d/photo',
                data,
                { headers }
            );
            console.log(response.data);
            return {
                success: true,
            }
        } catch (error) {
            console.log(error, "IMAGE UPLOAD ERROR");
            if (error.response.status === 403) {
                return {
                    success: false,
                    error: 'forbidden'
                }
            }
            else {
                console.log(error.response.status, API_BASE_URL + USER_API_BASE + '/details/d/photo');
                return {
                    success: false,
                    error: 'unknown'
                }
            }
        }
    }
    catch (error) {
        console.log(error);
    }
}

const getCheckpoints = async () => {
    try {
        const data = JSON.stringify({});

        const config = {
            method: 'get',
            url: API_BASE_URL + USER_API_BASE + '/user/progress',
            headers: {
                'x-device-id': await getDeviceId(),
                'x-key': await getUserAccessKey(),
                'Content-Type': 'application/json',
            },
            data,
        };

        console.log(config, "Config");
        const response = await axios(config);
        const progressData = response.data.data;

        console.log(progressData, "User Progress");

        return {
            success: true,
            missingCheckpoints: progressData.missingSteps,
            completionPercent: progressData.completion,
            subscription: progressData.subscription,
            skippedCheckpoints: progressData.skippedCheckpoints,
        };
    } catch (error) {
        console.log(error);
    }
};

const getDetail = async (detailName) => {
    try {
        const config = {
            headers: {
                'x-key': await getUserAccessKey(),
                'x-device-id': await getDeviceId()
            }
        };
        const url = `${API_BASE_URL}${USER_API_BASE}/details/d/${detailName}`;
        console.log(config);
        console.log(url);

        try {
            const response = await fetch(url, config);
            const data = await response.json();
            return {
                success: true,
                isSet: data.data.isSet,
                value: data.data?.value
            };
        } catch (error) {
            console.error(JSON.stringify(error, null, 2));
            return {
                success: false
            };
        }
    } catch (error) {
        console.error(error);
    }
}

const getAllDetails = async () => {
    try {
        const headers = {
            'x-device-id': await getDeviceId(),
            'x-key': await getUserAccessKey(),
            'Content-Type': 'application/json',
        };


        try {
            const response = await axios.get(
                API_BASE_URL + USER_API_BASE + '/details/all',
                { headers }
            );
            return {
                success: true,
                data: response.data.data
            }
        } catch (error) {
            console.log(error, "IMAGE UPLOAD ERROR")
            return {
                success: false,
            }
        }
    }
    catch (error) {
        console.log(error);
    }
}


const setDetail = async (detailName, detailValueId, value) => {
    console.log(detailName, detailValueId, value);
    try {
        const headers = {
            'x-device-id': await getDeviceId(),
            'x-key': await getUserAccessKey(),
            'Content-Type': 'application/json',
        };

        const data = JSON.stringify({
            [detailValueId]: value
        });

        try {
            const response = await axios.post(
                API_BASE_URL + USER_API_BASE + '/details/d/' + detailName,
                data,
                { headers }
            );
            return {
                success: true,
            }
        } catch (error) {
            console.error(error, "IMAGE UPLOAD ERROR")
            return {
                success: false,
            }
        }
    }
    catch (error) {
        console.log(error);
    }
}
const setLocation = async (lng, lat) => {
    try {
        const headers = {
            'x-device-id': await getDeviceId(),
            'x-key': await getUserAccessKey(),
            'Content-Type': 'application/json',
        };

        const data = JSON.stringify({
            lng: lng,
            lat: lat
        });

        try {
            const response = await axios.post(
                API_BASE_URL + USER_API_BASE + '/details/d/location',
                data,
                { headers }
            );
            return {
                success: true,
            }
        } catch (error) {
            console.log(JSON.stringify(error), "setLocation");
            return {
                success: false,
            }
        }
    }
    catch (error) {
        console.log(error, "setLocation");
    }
}

const setGiftAddress = async ({
    country,
    city,
    zipCode,
    adressLineOne,
    adressLineSecond
}) => {
    try {
        const headers = {
            'x-device-id': await getDeviceId(),
            'x-key': await getUserAccessKey(),
            'Content-Type': 'application/json',
        };

        const data = JSON.stringify({
            address: {
                country,
                city,
                zipCode,
                adressLineOne,
                adressLineSecond
            }
        });

        try {
            const response = await axios.post(
                API_BASE_URL + USER_API_BASE + '/details/d/address',
                data,
                { headers }
            );
            return {
                success: true,
            }
        } catch (error) {
            console.log(JSON.stringify(error), "setGiftAddress");
            return {
                success: false,
            }
        }
    }
    catch (error) {
        console.log(error);
    }
}
async function getUserProfile(userId) {
    const deviceId = await getDeviceId();
    const userAccessKey = await getUserAccessKey();



    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://d14szcjletu2tu.cloudfront.net/user/v1/user/profile/' + userId,
        headers: {
            'x-device-id': deviceId,
            'x-key': userAccessKey,
        }
    };


    try {
        const response = await axios.request(config)

        console.log(API_BASE_URL + USER_API_BASE + `/user/profile/${userId}`);
        console.log(response.data.data, "User Profile");
        return response.data.data;
    } catch (error) {
        console.log('getUserProfile', JSON.stringify(error, null, 2));
    }
}

async function getSelfProfile() {
    const deviceId = await getDeviceId();
    const userAccessKey = await getUserAccessKey();

    const headers = {
        'x-device-id': deviceId,
        'x-key': userAccessKey,
    };

    try {
        const response = await axios.get(
            API_BASE_URL + USER_API_BASE + '/user/profile',
            { headers }
        );

        console.log(response.data.data, "Self Profile");
        return response.data.data;
    } catch (error) {
        console.log('getSelfProfile', error);
    }
}

const skipCheckpoint = async (checkpointId) => {

    const headers = {
        'x-device-id': await getDeviceId(),
        'x-key': await getUserAccessKey(),
        'Content-Type': 'application/json'
    };

    const config = {
        method: 'post',
        url: API_BASE_URL + USER_API_BASE + '/details/skip-checkpoint/' + checkpointId,
        headers: headers
    };

    try {
        const response = await axios(config);
        return { success: true };
    } catch (error) {
        return { success: false, error };
    }
}


async function getHeaders() {
    const deviceId = await getDeviceId();
    const userAccessKey = await getUserAccessKey();

    return {
        'x-device-id': deviceId,
        'x-key': userAccessKey,
    };
}

async function createProductPrompt(payload) {
    try {
        const response = await axios.post(
            API_BASE_URL + USER_API_BASE + "/promt" + '/product',
            payload,
            { headers: await getHeaders() }
        );

        return response.data;
    } catch (error) {
        console.error('createProductPrompt:', JSON.stringify(error, null, 2));
    }
}

async function getPrompt(id) {
    try {
        const response = await axios.get(
            API_BASE_URL + USER_API_BASE + "/promt" + `/${id}`,
            { headers: await getHeaders() }
        );

        return response.data;
    } catch (error) {
        console.error('getPrompt:', JSON.stringify(error, null, 2));
    }
}

async function setPromptStatus(id, status) {
    try {
        const response = await axios.post(
            API_BASE_URL + USER_API_BASE + "/promt" + `/${id}/${status}`,
            {},
            { headers: await getHeaders() }
        );

        return response.data;
    } catch (error) {
        console.error('setPromptStatus:', JSON.stringify(error, null, 2));
    }
}

async function getPromptsSentToUser(otherUserId) {
    try {
        const response = await axios.get(
            API_BASE_URL + USER_API_BASE + "/promt" + `/product/to-user/${otherUserId}`,
            { headers: await getHeaders() }
        );

        return response.data;
    } catch (error) {
        console.error('getPromptsSentToUser:', JSON.stringify(error, null, 2));
    }
}

async function getPromptsSentByUser(otherUserId) {
    try {
        const response = await axios.get(
            API_BASE_URL + USER_API_BASE + "/promt" + `/product/from-user/${otherUserId}`,
            { headers: await getHeaders() }
        );

        return response.data;
    } catch (error) {
        console.error('getPromptsSentByUser:', JSON.stringify(error, null, 2));
    }
}

async function getAllIncomingPrompts() {
    try {
        const response = await axios.get(
            API_BASE_URL + USER_API_BASE + "/promt",
            { headers: await getHeaders() }
        );

        return response.data;
    } catch (error) {
        console.error('getAllIncomingPrompts:', JSON.stringify(error, null, 2));
    }
}




async function deleteMe() {
    try {
        const response = await axios.delete(
            API_BASE_URL + USER_API_BASE + '/user',
            { headers: await getHeaders() }
        );

        return response.data;
    } catch (error) {
        console.error('deleteMe:', JSON.stringify(error, null, 2));
    }
}

async function revertDeletion() {
    try {
        const response = await axios.post(
            API_BASE_URL + USER_API_BASE + '/user/undo-delete',
            {},
            { headers: await getHeaders() }
        );

        return response.data;
    }
    catch (error) {
        console.error(error, "revertDeletion");
    }

}

async function likeUser(userId) {
    try {
        const response = await axios.post(
            API_BASE_URL + USER_API_BASE + '/matches/like',
            { userId },
            { headers: await getHeaders() }
        );

        return response.data;
    } catch (error) {
        console.error('likeUser:', JSON.stringify(error, null, 2));
    }
}

const userAPI = {
    createProductPrompt,
    getPrompt,
    setPromptStatus,
    getPromptsSentToUser,
    getPromptsSentByUser,
    getAllIncomingPrompts,
    getCheckpoints,
    getDetail,
    setDetail,
    getUserProfile,
    getSelfProflie: getSelfProfile, //TODO: remove this line after fixing the typo
    getSelfProfile,
    PHOTO_TYPES,
    uploadPhoto,
    setLocation,
    setGiftAddress,
    skipCheckpoint,
    getAllDetails,
    deleteMe,
    revertDeletion,
    likeUser
}

export default userAPI