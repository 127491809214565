import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

import { useRecoilState } from 'recoil';
import { userAtoms, appAtoms } from './logic/store/store';
import { useState } from 'react';
import LoggedOutRouter from './routers/loggedOut';
import LoggedInRouter from './routers/loggedIn';
import { Loader } from '@chatscope/chat-ui-kit-react';
import { getAppLanguage, getUserAccessKey, setUserAccessKey } from './logic/store/global';
import authAPI from './logic/services/auth';
import { RESPONSIVENESS_PARAMETER } from './constants';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useRecoilState(userAtoms.isLoggedInAtom)
  const [selfUserId, setSelfUserId] = useRecoilState(userAtoms.userIdAtom)
  const [isLoading, setIsLoading] = useState(true);
  const [appLanguage, setAppLanguage] = useRecoilState(appAtoms.langAtom);
  const [responsiveness, setResponsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)
  const [isMobile, setIsMobile] = useState(false);

  //Setting app language
  useEffect(() => {
    getAppLanguage().then(lang => {
      setAppLanguage(lang ?? "en")
    })
  }, [])


  useEffect(() => {
    function checkIsMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobileDevice = /mobi|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

      setIsMobile(isMobileDevice);
    }

    // Call checkIsMobile initially to set the initial value
    checkIsMobile();

    // Clean up the event listener on component unmount (optional for this case)
    return () => {
      // No cleanup needed in this case
    };
  }, []);



  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      const aspect = width / window.innerHeight;

      if (aspect < 1 || width <= 500 || isMobile) {
        setResponsiveness(RESPONSIVENESS_PARAMETER.MOBILE);
      } else {
        setResponsiveness(RESPONSIVENESS_PARAMETER.DESKTOP);
      }
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call handleResize initially to set the initial responsiveness
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const chechkLogin = () => {
    setIsLoading(true)
    getUserAccessKey().then(async (userAccessKey) => {

      const authRes = await authAPI.checkAuth(userAccessKey);
      if (authRes.success) {
        setIsLoggedIn(true);
        setSelfUserId(authRes.data.userId)
      } else {
        setIsLoggedIn(false);
      }
      setIsLoading(false)
    })
  }

  useEffect(() => {
    chechkLogin()
  }, [])

  if (isLoading) {
    return <div className='
      w-screen h-screen flex justify-center items-center
    '>
      <Loader />
    </div>
  }

  if (isLoggedIn) {
    return <LoggedInRouter />
  } else {
    return <LoggedOutRouter />
  }
}

export default App;
