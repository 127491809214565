import { Button } from 'antd'
import React, { useEffect } from 'react'
import * as AntdIcons from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { deleteUserAccessKey } from '../../../logic/store/global'
import { useRecoilState } from 'recoil'
import { userAtoms } from '../../../logic/store/store'
import { TabBar } from 'antd-mobile'
import { useLanguage } from '../../../lang/utils/utils'

export default function BottomNav() {
    const [isOpen, setIsOpen] = React.useState(false)
    const selectedMenuKey = window.location.pathname.replaceAll("/", "")
    const navigate = useNavigate()
    const componentLang = useLanguage("components.bottom_navigation")

    const tabs = [
        {
            icon: <AntdIcons.HomeOutlined />,
            title: componentLang.feed,
            key: '',
            onClick: () => {
                navigate('/')
            }
        },
        {
            icon: <AntdIcons.MessageOutlined />,
            title: componentLang.messages,
            key: 'chat',
            onClick: () => {
                navigate('/chat')
            }
        },
    ]

    useEffect(() => {
        const handleScroll = () => {
            console.log('User is scrolling');
            const sideNav = document.getElementById('sideNav')
            const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
            if (sideNav) {
                //Here adust top according to scroll positon via animation smooth
                //Make it follow the screen center
                if (scrollY > 100) {
                    sideNav.style.top = `${scrollY}px`
                } else {
                    sideNav.style.top = `0px`
                }
            }
            // You can put your scroll logic here
        };

        // Adding the event listener
        window.addEventListener('scroll', handleScroll);

        // Returning a cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array ensures this effect runs once on mount and unmount

    const [isLoggedIn, setIsLoggedIn] = useRecoilState(userAtoms.isLoggedInAtom)

    const handleLogout = () => {
        deleteUserAccessKey()
        setIsLoggedIn(false)
    }

    return (
        <TabBar
            activeKey={selectedMenuKey}
            onChange={(key) => {
                navigate(`/${key}`)
            }}>
            {tabs.map(item => (
                <TabBar.Item
                    key={item.key}
                    icon={item.icon}
                    title={item.title}
                />
            ))}
        </TabBar>
    )
}
