import React, { useEffect, useState } from 'react'
import MainNavBar from '../../components/loggedIn/navigation/mainNavBar'
import SideNav from '../../components/loggedIn/navigation/sideNav'
import Feed from '../../components/loggedIn/matching/feed'
import { chatAPI } from '../../logic/services/chat'
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { appAtoms, userAtoms } from '../../logic/store/store'
import authAPI from '../../logic/services/auth'
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    ConversationList,
    Conversation,
    Avatar,
    Loader
} from '@chatscope/chat-ui-kit-react';
import { useRecoilState } from 'recoil'
import userAPI from '../../logic/services/user'
import { getUserAccessKey } from '../../logic/store/global'
import { useLanguage } from '../../lang/utils/utils'
import Prompt from '../../components/loggedIn/chat/prompt'
import { Channel } from '../../components/loggedIn/chat/channel'
import Converstaions from '../../components/loggedIn/chat/converstaions'
import { Button } from 'antd'
import { Tabs } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import { RESPONSIVENESS_PARAMETER } from '../../constants'
import TopNavBar from '../../components/loggedIn/navigation/topNav'
import BottomNav from '../../components/loggedIn/navigation/bottomNav'





export default function Chat() {
    const [channels, setChannels] = React.useState([])
    const [selfUserId, setSelfuserId] = useRecoilState(userAtoms.userIdAtom)
    const [selectedChannel, setSelectedChannel] = React.useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [responsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)
    const [mobileTabActivekey, setMobileTabActiveKey] = useState("p")

    const pageLang = useLanguage("screens.chat")

    const navigate = useNavigate()

    const onOpenFeed = () => {
        navigate("/")
    }

    const getChannels = async () => {
        let selfUid = selfUserId
        if (!selfUid) {
            await authAPI.checkAuth(await getUserAccessKey()).then(res => {
                setSelfuserId(res.data.userId)
                selfUid = res.data.userId
            })
        }

        chatAPI.getChannels().then(async res => {
            if (res.succes) {

                const imagesProimses = res.data.map(async channel => {

                    if (channel.channelType !== 0) {
                        return channel
                    };

                    const otherUserId = channel.users.find(userId => userId !== selfUserId)
                    const otherUserData = await userAPI.getUserProfile(otherUserId)
                    return {
                        ...channel,
                        otherUserData
                    }
                })

                const channelsWithImages = await Promise.all(imagesProimses)
                setChannels(channelsWithImages)
                setSelectedChannel(channelsWithImages[0])
                setIsLoading(false)
            }
        })
    }
    React.useEffect(() => {
        getChannels()
    }, [])



    if (responsiveness == RESPONSIVENESS_PARAMETER.DESKTOP) {
        return (
            <div className='h-screen w-screen'>
                <div className='flex-1 flex flex-row h-full'>
                    <div className='h-full w-24'>
                        <SideNav />
                    </div>
                    <div className='flex flex-1 flex-col justify-center items-center pr-4 w-full'>
                        <div className='flex-1 m-2 rounded-3xl my-6 overflow-hidden border-4 border-red-300 w-full'>
                            {channels.length == 0 && !isLoading &&
                                <div
                                    className='flex-1 flex justify-center items-center w-full h-full flex-col space-y-2'
                                >
                                    <h3
                                        className='text-red-400 text-lg font-bold'
                                    >{pageLang.no_messages_yet.title}</h3>
                                    <h5
                                        className='text-red-600 text-xl font-bold'
                                    >{pageLang.no_messages_yet.large_title}</h5>
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={onOpenFeed}
                                    >{pageLang.no_messages_yet.button}</Button>
                                </div>
                            }
                            {isLoading && <div
                                className="flex-1 flex h-full justify-center items-center"
                            >
                                <Loader />
                            </div>}
                            {channels.length > 0 && !isLoading && <MainContainer>
                                {channels.length > 0 && <Converstaions channels={channels} setSelectedChannel={setSelectedChannel} selectedChannel={selectedChannel} />}
                                {selectedChannel && <Channel channel={selectedChannel} />}
                            </MainContainer>}
                        </div>
                        {selectedChannel?.users?.length > 0 && <div className='h-32 p-4 bg-red-700 text-white m-2 mr-3 rounded-3xl flex justify-between flex-col'>
                            <div className='flex flex-col space-y-2'>
                                <h2
                                    className='text-semibold text-red-100'
                                >{pageLang.store_selling_point_title}</h2>
                                <h3
                                    className="text-bold text-red-50 text-lg"
                                >
                                    {pageLang.store_selling_point_text}
                                </h3>
                                <Button
                                    onClick={() => {
                                        navigate(`/store?otherUser=${selectedChannel.users.filter(x => x !== selfUserId)[0]}&channelId=${selectedChannel.channelId}`)
                                    }}
                                    className='bg-white rounded-xl'
                                >
                                    {pageLang.open_store_button}
                                </Button>
                            </div>
                        </div>}
                    </div>
                </div>
            </div >
        )
    } else {
        return (
            <div className=' w-screen h-screen' id='head' style={{
                height: "calc(100vh - 8rem)",
                maxHeight: "calc(100vh - 8rem)"
            }}>
                <div className='flex-1 flex flex-col h-full'>
                    <TopNavBar
                        title={"Chat"}
                        hasLanguage
                        hasSupport
                    />
                    <div className='flex-1 flex flex-col'>
                        {channels.length == 0 && !isLoading &&
                            <div
                                className='flex-1 flex justify-center items-center w-full h-full flex-col space-y-2'
                            >
                                <h3
                                    className='text-red-400 text-lg font-bold'
                                >
                                    {pageLang.no_messages_yet.title}
                                </h3>
                                <h5
                                    className='text-red-600 text-xl font-bold'
                                >{pageLang.no_messages_yet.large_title}</h5>
                                <Button
                                    type="primary"
                                    danger
                                    onClick={onOpenFeed}
                                >{pageLang.no_messages_yet.button}</Button>
                            </div>
                        }
                        {isLoading && <div
                            className="flex-1 flex h-full justify-center items-center"
                        >
                            <Loader />
                        </div>}

                        {channels.length > 0 && !isLoading &&
                            <>

                                <Tabs activeKey={mobileTabActivekey} onChange={(k) => {
                                    setMobileTabActiveKey(k)
                                }} className='w-screen  m-0 '>
                                    <Tabs.Tab title="People" key="p" className=''>
                                        <div></div>
                                    </Tabs.Tab>
                                    <Tabs.Tab title="Chat" key="c" className=''>
                                        <div></div>
                                    </Tabs.Tab>
                                </Tabs>
                                <MainContainer style={{
                                    border: 0
                                }}>
                                    <div className='flex-col flex flex-1 w-full w-screen'>
                                        {channels.length > 0 && mobileTabActivekey == 'p' && <Converstaions
                                            onChannel={() => {
                                                setMobileTabActiveKey("c")
                                            }}
                                            channels={channels} setSelectedChannel={setSelectedChannel} selectedChannel={selectedChannel} />}
                                        {selectedChannel && mobileTabActivekey == 'c' && <Channel channel={selectedChannel} />}
                                        {selectedChannel?.users?.length > 0 && mobileTabActivekey == 'c' && <Button
                                            danger
                                            type='primary'
                                            className='w-[95%]  z-50 mx-3'
                                            onClick={() => {
                                                navigate(`/store?otherUser=${selectedChannel.users.filter(x => x !== selfUserId)[0]}&channelId=${selectedChannel.channelId}`)
                                            }}
                                        >
                                            {pageLang.open_store_button}
                                        </Button>}
                                    </div>

                                </MainContainer>
                            </>
                        }

                    </div>
                    {mobileTabActivekey !== "c" && <BottomNav />}
                </div>
            </div >
        )
    }


}
