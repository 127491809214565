import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { appAtoms, regsterAtoms, userAtoms } from '../../logic/store/store'
import { DETAIL_CATEGORIES, MUST_HAVE_REGISTRATION_CHECKPOINTS, RESPONSIVENESS_PARAMETER } from '../../constants'
import { REGISTRATION_CHECKPOINT } from '../../constants'
import TextSCInput from '../../components/shared/inputs/selfContaining/textInput'
import GenderSCSelect from '../../components/shared/inputs/selfContaining/multiGenderSelect'
import DateSCInput from '../../components/shared/inputs/selfContaining/dateInput'
import PhotoGrid from '../../components/shared/inputs/selfContaining/photoInput'
import userAPI from '../../logic/services/user'
import { Button, Divider } from 'antd'
import LocationSetter from '../../components/shared/inputs/selfContaining/locationInput'
import { Loader } from '@chatscope/chat-ui-kit-react'
import { detailSavers } from '../../logic/controllers/detailSavers'
import { deleteUserAccessKey } from '../../logic/store/global'
import TopNavBar from '../../components/loggedIn/navigation/topNav'
import { List } from 'antd-mobile'
import { useLanguage } from '../../lang/utils/utils'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Language from '../shared/language'

export default function Checkpoint() {

    const [missingCheckpoints, setMissingCheckpoints] = useRecoilState(regsterAtoms.missingCheeckpointsAtom)
    const [isLoggedIn, setIsLoggedIn] = useRecoilState(userAtoms.isLoggedInAtom)

    const misingCrutialCheckpoints = [...Object.values(MUST_HAVE_REGISTRATION_CHECKPOINTS)].flat().filter(cp => missingCheckpoints.includes(cp))
    const [checkpointInputs, setcheckpointInputs] = useState({})
    const [isPreLoading, setIsPreLoading] = useState(true)
    const [isSaving, setIsSaving] = useState(false)
    const [error, setError] = useState(null)
    const componentLang = useLanguage("components.self_containing_inputs")
    const pageLang = useLanguage("screens.setup_details")
    const [responsiveness, setResponsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)

    useEffect(() => {
        console.log(checkpointInputs);
    }, [checkpointInputs])

    const [alLDetails, setAllDetails] = useState(null)

    useEffect(() => {
        userAPI.getAllDetails().then((res) => {
            if (!res.success) return
            setAllDetails(res.data)
            console.log(res.data);
        }).then(() => {
            setIsPreLoading(false)
        })
    }, [])

    if (isPreLoading) {
        return <div className='flex-1 flex justify-center items-center w-screen h-screen'>
            <Loader />
        </div>
    }

    const onSave = async () => {
        setIsSaving(true);
        const saveAttempts = 3;
        const delayRange = 1000; // in milliseconds

        for (let i = 0; i < saveAttempts; i++) {
            await new Promise(resolve => setTimeout(resolve, (i + 1) * 1000 + Math.random() * delayRange));

            const savePromises = Object.keys(checkpointInputs).map(fieldKey => {
                if (typeof detailSavers[fieldKey] !== 'function') return true;
                return detailSavers[fieldKey](checkpointInputs[fieldKey]);
            });

            const saveResults = await Promise.all(savePromises);

            if (saveResults.every(result => result)) {
                setIsSaving(false);
                userAPI.getCheckpoints().then(res => {
                    if (res.success) {
                        setMissingCheckpoints(res.missingCheckpoints);
                    }
                });
                return; // Successful save, exit the function
            }
        }

        alert('Failed to save after multiple attempts');
        setIsSaving(false);
    };

    const handleLogout = () => {
        deleteUserAccessKey()
        setIsLoggedIn(false)
    }

    if (responsiveness == RESPONSIVENESS_PARAMETER.DESKTOP) {
        return (
            <div className='flex justify-center items-center h-screen w-screen flex-1 p-8 '>
                <div className='flex-1 max-w-5xl rounded-3xl h-full p-8'>
                    <div className='flex flex-row items-center space-x-4'>
                        <img
                            className={`rounded-xl h-14 aspect-square`}
                            src="https://ichdich-website-static.s3.eu-central-1.amazonaws.com/logo%20%281%29.png"
                        />
                        <div>
                            <h1
                                className='font-medium text-xl text-gray-800'
                            >
                                {pageLang.title}
                            </h1>

                        </div>
                    </div>
                    <div className='flex flex-row space-x-8 flex-1 w-full'>
                        <div className='flex-1 flex flex-col py-8 space-y-1'>
                            <TextSCInput
                                placeholder={componentLang[REGISTRATION_CHECKPOINT.NICKNAME].placeholder}
                                label={componentLang[REGISTRATION_CHECKPOINT.NICKNAME].title}
                                defaultValue={alLDetails?.nickName}
                                onChange={(value) => {
                                    setcheckpointInputs({
                                        ...checkpointInputs,
                                        [REGISTRATION_CHECKPOINT.NICKNAME]: value
                                    })
                                }}
                            />
                            <TextSCInput
                                   placeholder={componentLang[REGISTRATION_CHECKPOINT.FULL_NAME].placeholder}
                                   label={componentLang[REGISTRATION_CHECKPOINT.FULL_NAME].title}
                                defaultValue={alLDetails?.fullName}
                                onChange={(value) => {
                                    setcheckpointInputs({
                                        ...checkpointInputs,
                                        [REGISTRATION_CHECKPOINT.FULL_NAME]: value
                                    })
                                }}
                            />
                            <GenderSCSelect
                                 placeholder={componentLang[REGISTRATION_CHECKPOINT.GENDER].placeholder}
                                 label={componentLang[REGISTRATION_CHECKPOINT.GENDER].title}
                                defaultValue={alLDetails?.gender}
                                onChange={(value) => {
                                    setcheckpointInputs({
                                        ...checkpointInputs,
                                        [REGISTRATION_CHECKPOINT.GENDER]: value
                                    })
                                }}
                            />
                            <DateSCInput
                              placeholder={componentLang[REGISTRATION_CHECKPOINT.BIRTH_DATE].placeholder}
                              label={componentLang[REGISTRATION_CHECKPOINT.BIRTH_DATE].title}
                                defaultValue={alLDetails?.birthdate}
                                onChange={(value) => {
                                    setcheckpointInputs({
                                        ...checkpointInputs,
                                        [REGISTRATION_CHECKPOINT.BIRTH_DATE]: value
                                    })
                                }}
                            />
                            <GenderSCSelect
                                   placeholder={componentLang[REGISTRATION_CHECKPOINT.GENDER_PREF].placeholder}
                                   label={componentLang[REGISTRATION_CHECKPOINT.GENDER_PREF].title}
                                mode={"multiple"}
                                defaultValue={alLDetails?.genderPreferences}
                                onChange={(value) => {
                                    setcheckpointInputs({
                                        ...checkpointInputs,
                                        [REGISTRATION_CHECKPOINT.GENDER_PREF]: value
                                    })
                                }}
                            />
                            <LocationSetter
                                onLocation={(loc) => {
                                    setcheckpointInputs({
                                        ...checkpointInputs,
                                        [REGISTRATION_CHECKPOINT.LOCATION]: loc
                                    })
                                }}
                            />
                        </div>
                        <div className='h-full pt-[3.25rem] w-2/5'>
                            <PhotoGrid
                                numberOfImages={9}
                                onUpload={async (index, base64) => {
                                    console.log(index, base64);
                                    const succes = (await userAPI.uploadPhoto(base64, userAPI.PHOTO_TYPES.OTHER, Number(index))).success
                                    if (succes) {
                                        setcheckpointInputs({
                                            ...checkpointInputs,
                                            [REGISTRATION_CHECKPOINT.OTHER_PHOTOS]: {
                                                ...checkpointInputs[REGISTRATION_CHECKPOINT.OTHER_PHOTOS],
                                                [index]: base64
                                            }
                                        })
                                    }
                                    return succes
                                }}
                                images={alLDetails?.otherImages}
                            />

                        </div>
                    </div>
                    <Divider
                        className='my-2'
                    />
                    <div className='flex-1 flex justify-center items-center mt-8 flex-col space-y-1'>
                        <Button
                            onClick={onSave}
                            loading={isSaving}
                            danger type="primary" disabled={misingCrutialCheckpoints.every(mccp => Object.keys(checkpointInputs).includes(mccp)) == false}
                        >
                            {pageLang.save}
                        </Button>
                        <p className='text-red-500 text-xs font-medium'>
                            {error}
                        </p>
                        <span onClick={handleLogout} className='text-xs text-red-500 cursor-pointer hover:text-red-400 transition'>
                            Logout
                        </span>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='flex-1 rounded-3xl h-screen '>
                <TopNavBar
                    hasSupport
                    title={pageLang.title}
                />
                <div
                    className='p-4 overflow-scroll '
                    style={{
                        height: "calc(100vh - 10rem)",
                        maxHeight: "calc(100vh - 10rem)"
                    }}
                >
                    <div className='flex flex-col flex-1 w-full '>
                        <List header={pageLang.categories[DETAIL_CATEGORIES.BASIC_INFO]}>
                            <List.Item>
                                <TextSCInput
                                    placeholder={componentLang[REGISTRATION_CHECKPOINT.NICKNAME].placeholder}
                                    label={componentLang[REGISTRATION_CHECKPOINT.NICKNAME].title}
                                    defaultValue={alLDetails?.nickName}
                                    onChange={(value) => {
                                        setcheckpointInputs({
                                            ...checkpointInputs,
                                            [REGISTRATION_CHECKPOINT.NICKNAME]: value
                                        })
                                    }}
                                />
                            </List.Item>
                            <List.Item>
                                <TextSCInput
                                    placeholder={componentLang[REGISTRATION_CHECKPOINT.FULL_NAME].placeholder}
                                    label={componentLang[REGISTRATION_CHECKPOINT.FULL_NAME].title}
                                    defaultValue={alLDetails?.fullName}
                                    onChange={(value) => {
                                        setcheckpointInputs({
                                            ...checkpointInputs,
                                            [REGISTRATION_CHECKPOINT.FULL_NAME]: value
                                        })
                                    }}
                                />
                            </List.Item>
                            <List.Item>
                                <GenderSCSelect
                                    placeholder={componentLang[REGISTRATION_CHECKPOINT.GENDER].placeholder}
                                    label={componentLang[REGISTRATION_CHECKPOINT.GENDER].title}
                                    defaultValue={alLDetails?.gender}
                                    onChange={(value) => {
                                        setcheckpointInputs({
                                            ...checkpointInputs,
                                            [REGISTRATION_CHECKPOINT.GENDER]: value
                                        })
                                    }}
                                />
                            </List.Item>
                            <List.Item>
                                <DateSCInput
                                    placeholder={componentLang[REGISTRATION_CHECKPOINT.BIRTH_DATE].placeholder}
                                    label={componentLang[REGISTRATION_CHECKPOINT.BIRTH_DATE].title}
                                    defaultValue={alLDetails?.birthdate}
                                    onChange={(value) => {
                                        setcheckpointInputs({
                                            ...checkpointInputs,
                                            [REGISTRATION_CHECKPOINT.BIRTH_DATE]: value
                                        })
                                    }}
                                />
                            </List.Item>
                        </List>

                        <List header={pageLang.categories[DETAIL_CATEGORIES.PREFERENCES]}>
                            <List.Item>
                                <GenderSCSelect
                                    placeholder={componentLang[REGISTRATION_CHECKPOINT.GENDER_PREF].placeholder}
                                    label={componentLang[REGISTRATION_CHECKPOINT.GENDER_PREF].title}
                                    mode={"multiple"}
                                    defaultValue={alLDetails?.genderPreferences}
                                    onChange={(value) => {
                                        setcheckpointInputs({
                                            ...checkpointInputs,
                                            [REGISTRATION_CHECKPOINT.GENDER_PREF]: value
                                        })
                                    }}
                                />
                            </List.Item>
                        </List>

                        <List header={pageLang.categories[DETAIL_CATEGORIES.LOCATION]}>
                            <List.Item>
                                <LocationSetter
                                    onLocation={(loc) => {
                                        setcheckpointInputs({
                                            ...checkpointInputs,
                                            [REGISTRATION_CHECKPOINT.LOCATION]: loc
                                        })
                                    }}
                                />
                            </List.Item>
                            <List.Item>
                                <Button
                                    danger
                                    type="primary"
                                    className='w-full'
                                    onClick={() => {
                                        window.open('https://youtu.be/hITcJIwU4YY', '_blank');
                                    }}
                                >
                                    {componentLang[REGISTRATION_CHECKPOINT.LOCATION].video_button}
                                </Button>
                            </List.Item>
                            <List.Item>
                                <p
                                    className='text-xs text-gray-500 text-center'
                                >
                                    {componentLang[REGISTRATION_CHECKPOINT.LOCATION].desktop_notice}
                                </p>
                            </List.Item>
                        </List>

                        <List header={pageLang.categories[DETAIL_CATEGORIES.PHOTOS]}>
                            <List.Item>
                                <PhotoGrid
                                    numberOfImages={9}
                                    onUpload={async (index, base64) => {
                                        console.log(index, base64);
                                        const succes = (await userAPI.uploadPhoto(base64, userAPI.PHOTO_TYPES.OTHER, Number(index))).success
                                        if (succes) {
                                            setcheckpointInputs({
                                                ...checkpointInputs,
                                                [REGISTRATION_CHECKPOINT.OTHER_PHOTOS]: {
                                                    ...checkpointInputs[REGISTRATION_CHECKPOINT.OTHER_PHOTOS],
                                                    [index]: base64
                                                }
                                            })
                                        }
                                        return succes
                                    }}
                                    images={alLDetails?.otherImages}
                                />
                            </List.Item>
                            <List.Item>
                                <Button
                                    onClick={onSave}
                                    loading={isSaving}
                                    className='w-full h-12'
                                    danger type="primary" disabled={misingCrutialCheckpoints.every(mccp => Object.keys(checkpointInputs).includes(mccp)) == false}
                                >
                                    {pageLang.save}
                                </Button>
                            </List.Item>

                        </List>
                    </div>
                </div>
            </div>
        )
    }
}
