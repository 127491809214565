import { Input, Slider } from 'antd'
import React, { useState } from 'react'

export default function AgeRangeSCInput({
    defaultValue,
    placeholder,
    onChange,
    inputMode,
    label,
}) {
    const [value, setValue] = useState(defaultValue ?? [18, 100])



    return (
        <div className='flex-1'>
            <p className='text-xs font-semibold text-gray-500 mb-1 ml-0.5 '>
                {label}
            </p>
            <Slider
                defaultValue={[
                    value.min ? value.min : 18,
                    value.max ? value.max : 100,
                ]}
                min={18}
                marks={{
                    18: '18',
                    25: '25',
                    30: '30',
                    50: '50',
                    100: '100',
                }}
                onChange={(value) => {
                    onChange({
                        min: value[0],
                        max: value[1],
                    })
                }}
                range={{
                    draggableTrack: true,
                }}
            />
        </div>
    )
}
