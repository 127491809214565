import axios from "axios";
import { getDeviceId, getUserAccessKey } from "../store/global";
import { API_BASE_URL } from "../../constants";

const CHAT_API_BASE = '/chat/v1'

const getChannels = async () => {
    try {
        const config = {
            method: 'get',
            url: API_BASE_URL + CHAT_API_BASE + '/chat/channels',
            maxBodyLength: Infinity,
            headers: {
                'x-key': await getUserAccessKey(),
                'x-device-id': await getDeviceId(),
            }
        };

        const response = await axios(config);

        return {
            succes: true,
            data: response.data.data
        }
    } catch (error) {
        return {
            succes: false,
        }
    }
}
const getChannel = async (channelId) => {
    console.log(channelId, "channelId");
    try {
        const config = {
            method: 'get',
            url: API_BASE_URL + CHAT_API_BASE + '/chat/channel/' + channelId,
            maxBodyLength: Infinity,
            headers: {
                'x-key': await getUserAccessKey(),
                'x-device-id': await getDeviceId(),
            }
        };

        const response = await axios(config);
        console.log(response.data, "channelssssdsd");
        return {
            succes: true,
            data: response.data.data
        }
    } catch (error) {
        console.error(error);
        return {
            succes: false,
        }
    }
}
const getLastMessages = async (channelId, pagnation) => {
    try {
        const config = {
            method: 'get',
            url: API_BASE_URL + CHAT_API_BASE + '/chat/channels/' + channelId + '/messages/' + pagnation,
            maxBodyLength: Infinity,
            headers: {
                'x-key': await getUserAccessKey(),
                'x-device-id': await getDeviceId(),
            }
        };

        const response = await axios(config);

        return {
            succes: true,
            data: response.data.data
        }

    } catch (error) {
        console.log(error);
        return {
            succes: false,
        }
    }
}
const refreshChat = async (channelId, pagnation) => {
    try {
        const config = {
            method: 'get',
            url: API_BASE_URL + CHAT_API_BASE + '/chat/channels/' + channelId + '/refresh',
            maxBodyLength: Infinity,
            headers: {
                'x-key': await getUserAccessKey(),
                'x-device-id': await getDeviceId(),
            }
        };

        const response = await axios(config);

        return {
            succes: true,
            data: response.data.data
        }

    } catch (error) {
        console.log(error);
        return {
            succes: false,
        }
    }
}
const sendMessage = async (channelId, messageType, content) => {
    try {
        const data = JSON.stringify({
            message: {
                messageType, content
            }
        });

        const config = {
            method: 'post',
            url: API_BASE_URL + CHAT_API_BASE + '/chat/channels/' + channelId + '/message',
            headers: {
                'x-key': await getUserAccessKey(),
                'x-device-id': await getDeviceId(),
                'Content-Type': 'application/json',
            },
            data,
        };


        const response = await axios(config);

        return {
            succes: true,
            data: response.data.data
        }
    } catch (error) {
        console.log(error);
        return {
            succes: false,
        }
    }
}
const getPresignedUrl = async (channelId, mime) => {

    console.log(API_BASE_URL + CHAT_API_BASE + '/chat/channels/' + channelId + '/media/presigned-url/' + String(mime).replace('/', '-'));
    try {
        const config = {
            method: 'get',
            url: API_BASE_URL + CHAT_API_BASE + '/chat/channels/' + channelId + '/media/presigned-url/' + String(mime).replace('/', '-'),
            maxBodyLength: Infinity,
            headers: {
                'x-key': await getUserAccessKey(),
                'x-device-id': await getDeviceId(),
            }
        };

        const response = await axios(config);

        return {
            succes: true,
            data: response.data.data
        }


    } catch (error) {
        return {
            succes: false,
        }
    }
}

export const chatAPI = {
    getChannels,
    getChannel,
    getLastMessages,
    sendMessage,
    getPresignedUrl,
    refreshChat
}