import React from 'react'
import TopNavBar from '../../components/loggedIn/navigation/topNav'
import { CheckList } from 'antd-mobile'
import { LANGUAGES } from '../../constants'
import { useRecoilState } from 'recoil'
import { appAtoms } from '../../logic/store/store'
import { useNavigate } from 'react-router-dom'
import { setAppLanguage } from '../../logic/store/global'

export default function Language() {
    const [selectedlanguage, setSelectedLanguage] = useRecoilState(appAtoms.langAtom)

    return (
        <div className='h-screen w-screen flex-1'>
            <TopNavBar
                hasLogo
                hasSupport
                hasBack
            />
            <div className='flex-1'>
                <CheckList title="Language"
                    value={selectedlanguage}
                    onChange={(value) => {
                        setSelectedLanguage(value)
                        setAppLanguage(value)
                        window.history.back();
                        window.location.reload();
                    }}
                >
                    {LANGUAGES.map(lang => {
                        return <CheckList.Item
                            value={lang.code}
                            children={lang.nativeWord}
                        />
                    })}
                </CheckList>
            </div>
        </div>
    )
}
