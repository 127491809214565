import React from 'react'
import { useRecoilState } from 'recoil'
import { appAtoms } from '../../../logic/store/store'
import { RESPONSIVENESS_PARAMETER } from '../../../constants'
import { useLanguage } from '../../../lang/utils/utils'

//Ukraine peace banner


export default function MarketingStripe({
    label,
    subTitle
}) {
    const [responsiveness, setResponsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)
    const lang = useLanguage("marketing.free_stripe")
    if (responsiveness == RESPONSIVENESS_PARAMETER.DESKTOP) {
        return (
            < div className='flex flex-col justify-center items-center p-4 flex-1 w-full  bg-red-700' >
                <span className='text-white text-center w-full text-md font-medium relative'>
                    {lang.title ?? "Discover Ichdich - The Stylish Love"}
                </span>
                <h3 className='text-white text-center w-full text-3xl font-extrabold relative'>
                    {lang.subtitle ?? "Register now and get free access forever."}
                </h3>
            </div >
        )
    } else {
        return (
            < div className='flex flex-col justify-center items-center p-4 w-full  bg-red-700 ' >
                <span className='text-white text-center w-full text-md font-medium relative'>
                    {lang.title ?? "Discover Ichdich - The Stylish Love"}
                </span>
                <h3 className='text-white text-center w-full text-xl font-extrabold relative'>
                    {lang.subtitle ?? "Register now and get free access forever."}
                </h3>
            </div >
        )
    }
}
