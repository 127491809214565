import { DatePicker, Input } from 'antd'
import React, { useState } from 'react'

import locale from 'antd/es/date-picker/locale/en_GB';
import dayjs from 'dayjs';

export default function DateSCInput({
    defaultValue,
    placeholder,
    onChange,
    inputMode,
    label
}) {
    const defaultDate = dayjs(new Date(defaultValue));
    const fallbackDate = dayjs().subtract(18, 'year');

    const [value, setValue] = useState(defaultValue ? defaultDate : fallbackDate);

    return (
        <div className='flex-1'>
            <p className='text-xs font-semibold text-gray-500 mb-1 ml-0.5 '>
                {label}
            </p>
            <DatePicker
                className='w-full'
                value={value}
                locale={locale}
                placeholder={placeholder}
                clearIcon={false}
                onChange={(e) => {
                    setValue(e)
                    onChange(e.unix())
                }}

                inputMode={inputMode ? inputMode : "text"}

            />
        </div>
    )
}
