import { Button } from 'antd'
import React, { useEffect } from 'react'
import * as AntdIcons from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { deleteUserAccessKey } from '../../../logic/store/global'
import { useRecoilState } from 'recoil'
import { userAtoms } from '../../../logic/store/store'
import { useLanguage } from '../../../lang/utils/utils'

export default function SideNav() {
    const [isOpen, setIsOpen] = React.useState(false)
    const selectedMenuKey = window.location.pathname.replaceAll("/", "")
    const navigate = useNavigate()

    const menuItems = [
        {
            icon: (props) => (<AntdIcons.HomeOutlined {...props} />),
            text: 'Home',
            key: '',
            onClick: () => {
                navigate('/')
            }
        },
        {
            icon: (props) => (<AntdIcons.MessageOutlined {...props} />),
            text: 'Chat',
            key: 'chat',
            onClick: () => {
                navigate('/chat')
            }
        },
        {
            icon: (props) => (<AntdIcons.SettingOutlined {...props} />),
            text: 'Settings',
            key: 'settings',
            onClick: () => {
                navigate('/settings')
            }
        },
    ]

    useEffect(() => {
        const handleScroll = () => {
            console.log('User is scrolling');
            const sideNav = document.getElementById('sideNav')
            const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
            if (sideNav) {
                //Here adust top according to scroll positon via animation smooth
                //Make it follow the screen center
                if (scrollY > 100) {
                    sideNav.style.top = `${scrollY}px`
                } else {
                    sideNav.style.top = `0px`
                }
            }
            // You can put your scroll logic here
        };

        // Adding the event listener
        window.addEventListener('scroll', handleScroll);

        // Returning a cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array ensures this effect runs once on mount and unmount

    const [isLoggedIn, setIsLoggedIn] = useRecoilState(userAtoms.isLoggedInAtom)
    const commongLang = useLanguage("common.logout")

    const handleLogout = () => {
        deleteUserAccessKey()
        setIsLoggedIn(false)
    }

    return (
        <div className='h-screen flex items-stretch absolute transition-all ' id='sideNav'>
            <div className={` bg-red-400 flex-col flex  m-2 p-2 rounded-3xl ${isOpen ? "" : "w-20 justify-center items-center"}`}>
                <div className='m-2 p-2 bg-red-500 rounded-2xl flex-row flex'>
                    <Link to='/'>
                        <img
                            className={`rounded-xl ${!isOpen ? "h-8 w-8" : "h-16 w-16"}`}
                            src="https://ichdich-website-static.s3.eu-central-1.amazonaws.com/logo%20%281%29.png"
                        />
                    </Link>
                    <div className='px-3' hidden={!isOpen}>
                        <span className='flex flex-row space-x-1 justify-center items-center'>
                            <h2 className='text-white font-medium text-lg'>Nagy Ervin Istvan</h2>
                            <AntdIcons.EditFilled
                                className='text-white cursor-pointer hover:text-red-300'
                            />
                        </span>
                        <span>
                            <Link to='/settings' className='text-blue-200 hover:text-blue-500'>
                                Settings
                            </Link>
                        </span>
                    </div>

                </div>
                <div className='flex flex-1'>
                    <div className={`pr-0 space-y-2 flex-1 justify-between pb-4 h-full flex space-between flex-col ${isOpen ? "" : "mt-2"}`}>
                        <div className='space-y-2'>
                            {menuItems.map((item) => {
                                return (
                                    <>
                                        <>{selectedMenuKey == item.key}</>
                                        {item.icon(isOpen ? {
                                            onClick: item.onClick,
                                            className: `w-10 h-10 rounded-xl bg-red-400 flex justify-center items-center text-white cursor-pointer hover:bg-red-500 ${selectedMenuKey == item.key ? '  bg-red-600' : ""}`
                                        } : {
                                            onClick: item.onClick,
                                            className: `w-10 h-10 rounded-xl bg-red-400 flex justify-center items-center text-white cursor-pointer hover:bg-red-500 ${selectedMenuKey == item.key ? '  bg-red-600' : ""}`
                                        })}
                                    </>
                                )
                            })}
                        </div>
                        <div className='mt-auto'
                            onClick={() => {
                                handleLogout()
                            }}
                        >
                            <AntdIcons.LogoutOutlined
                                className={`w-10 h-10 rounded-xl bg-red-400 flex justify-center items-center text-white cursor-pointer hover:bg-red-500`}
                            />
                            <p
                                className='text-white text-xs text-center cursor-pointer hover:text-red-300'
                            >
                                {commongLang.logout}
                            </p>
                        </div>
                    </div>
                    <div
                        className={" bg-red-500 w-full mt-2 text-white p-5"}
                        hidden={!isOpen}>
                        asdhttps://s3.amazonaw
                    </div>
                </div>
            </div>
        </div>
    )
}
