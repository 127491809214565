import { ChatContainer, Message, MessageInput, MessageList } from "@chatscope/chat-ui-kit-react"
import { chatAPI } from "../../../logic/services/chat"
import { useRecoilState } from "recoil"
import { appAtoms, chatAtoms, userAtoms } from "../../../logic/store/store"
import React, { useEffect, useRef, useState } from "react"
import Prompt from "./prompt"
import userAPI from "../../../logic/services/user"
import { vendureAPI } from "../../../logic/services/vendure"
import { CHAT_WEBSOCKET_URL, RESPONSIVENESS_PARAMETER } from "../../../constants"
import { getDeviceId, getUserAccessKey } from "../../../logic/store/global"
import * as AntdIcons from '@ant-design/icons'
import { useLanguage } from "../../../lang/utils/utils"
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
let ws = null
let wsCid = null

export const Channel = ({ channel }) => {
    const [pagination, setPagination] = React.useState(0)
    const [messages, setMessages] = React.useState([])
    const [selfUserId, setSelfuserId] = useRecoilState(userAtoms.userIdAtom)
    const [otherUserData, setOtherUserData] = React.useState(null)
    const [otherUserStores, setOtherUserStores] = React.useState(null)
    const [promptEventBus, setPromptEventBus] = useRecoilState(chatAtoms.promptEventBusAtom)
    const [socket, setSocket] = useState(null)
    const [responsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)
    const hasGift = messages.some(x => x.messageType == 3)

    useEffect(() => {
        console.log(hasGift, "hasGift");
        if (responsiveness == RESPONSIVENESS_PARAMETER.MOBILE) {
            const storeButton = document.getElementById('storeButton');
            if (storeButton) {
                storeButton.style.position = 'absolute';
                storeButton.style.marginTop = '3.25rem';
                storeButton.style.width = 'calc(100vw - 3rem)'
            }
        }
    }, [hasGift, responsiveness]);

    React.useEffect(() => {
        chatAPI.getLastMessages(channel.channelId, pagination).then(res => {
            if (res.succes) {
                setMessages(res.data)
                const otheruserId = channel.users.find(userId => userId !== selfUserId)
                userAPI.getUserProfile(otheruserId).then(profRes => {
                    if (profRes) {
                        setOtherUserData(profRes)
                        vendureAPI.getStoresInCountry(profRes.country).then(storeRes => {
                            if (storeRes.succes) {
                                setOtherUserStores(storeRes.data)
                            }
                        })
                    }
                })
            }
        })
    }, [channel])


    const socketRef = useRef(null);
    const cachedOtherUserDataRef = useRef(otherUserData);

    useEffect(() => {
        Promise.all([getUserAccessKey(), getDeviceId()])
            .then(([accessKey, deviceId]) => {
                console.log(accessKey, deviceId, "ACKS");
                let CHAT_WEBSOCKET_URL_WITH_AUTH = `${CHAT_WEBSOCKET_URL}?x-key=${accessKey}&x-device-id=${deviceId}`;

                if (!ws && wsCid !== channel.channelId) {
                    wsCid = channel.channelId
                    ws = new WebSocket(CHAT_WEBSOCKET_URL_WITH_AUTH);

                    ws.onopen = (o) => {

                        console.log(o, "OPENED");
                    };

                    ws.onmessage = (event) => {

                        console.log(event.data, "OnMessage");
                        const parsed = JSON.parse(event.data)

                        if (parsed.messageType == 4) {
                            console.log("Utility", parsed.content);
                            switch (parsed.content.data.type) {
                                case "prompt-status":
                                    console.log("prompt-status", parsed.content.data);
                                    if (promptEventBus?.length > 0) {
                                        setPromptEventBus([...promptEventBus, parsed])
                                    } else {
                                        setPromptEventBus([parsed])
                                    }
                                    break;
                                default:
                                    break;
                            }
                            return;
                        }

                        if (parsed.channelId !== channel.channelId) return;

                        setMessages(prevMessages => [parsed, ...prevMessages]);
                        console.log(parsed, "convertedMessage");
                    };

                    ws.onerror = (error) => {
                        console.error('WebSocket error:', JSON.stringify(error, null, 2));
                    };

                    ws.onclose = (event) => {
                        console.log('WebSocket closed:', event);
                    };

                    socketRef.current = ws;
                }
            })
            .catch((error) => {
                console.error('Error connecting to WebSocket:', error);
            });

        return () => {
            ws = null
            wsCid = null
            if (socketRef.current && socketRef.current.readyState === 1) { // Check if the socket is connected before trying to close
                socketRef.current.close();
            }
        };

    }, [channel, cachedOtherUserDataRef, socketRef]);

    const [chatInputValue, setchatInputValue] = useState(null)

    const renderMessages = () => {
        const promptCount = messages.filter(message => message.messageType === 3).length
        let promptIndex = 0
        messages.sort((a, b) => a.timestamp - b.timestamp)
        return messages.map((message, index) => {
            if (message.messageType === 0) {
                return <Message model={{
                    message: message.content.plainText,
                    sentTime: message.createdAt,
                    sender: message.fromUserId,
                    direction: message.fromUserId === selfUserId ? 'outgoing' : 'incoming',
                }} />
            } else if (message.messageType == 3) {
                return <Message
                    model={{
                        sentTime: message.createdAt,
                        sender: message.fromUserId,
                        direction: message.fromUserId === selfUserId ? 'outgoing' : 'incoming',
                        payload: <Message.CustomContent>
                            <Prompt
                                message={message}
                                otherUserData={otherUserData}
                                otherUserStores={otherUserStores}
                                timedDelay={(
                                    ((promptCount - promptIndex++)) * 250
                                )}
                            />
                        </Message.CustomContent>
                    }}
                />
            }
        })
    }
    const componentLang = useLanguage("screens.chat")
    return <ChatContainer style={{
    }}>
        <MessageList >
            {renderMessages()}
        </MessageList>
        <MessageInput
            
            attachDisabled
            attachButton={false}
            placeholder={componentLang.type_messaes_here}
            value={chatInputValue}
            onSend={() => {
                chatAPI.sendMessage(
                    channel.channelId,
                    0,
                    {
                        plainText: chatInputValue
                    }
                ).then(result => {
                    if (result.succes) {
                        setchatInputValue(null)
                    }
                })

            }}
            onChange={(e) => {
                setchatInputValue(e)
            }} />
    </ChatContainer>
}