import React from 'react'
import { Form, Input, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom'
import { useLanguage } from '../../lang/utils/utils';
import PhoneInput from '../../components/shared/inputs/phone';
import LanguageBar from '../../components/shared/misc/languageBar';

import authAPI from '../../logic/services/auth';
import registerAPI from '../../logic/services/register';
import { RESPONSIVENESS_PARAMETER, TWO_FA_PROCESS_FLOW_TYPE } from '../../constants';
import { appAtoms } from '../../logic/store/store';
import { useRecoilState } from 'recoil';
import MarketingStripe from '../../components/shared/marketingStripe/MarketingStripe';

export default function Register() {
    const [phone, setPhone] = React.useState('')
    const [error, setError] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [responsiveness, setResponsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)

    const pageLang = useLanguage("screens.register")
    const commonLang = useLanguage("common")

    const navigation = useNavigate();

    const onRegister = () => {
        setIsLoading(true)
        if (phone.length == 0) {
            setIsLoading(false)
            return
        }

        registerAPI.isPhoneUsed(phone).then((res) => {
            if (res == false || res == "truified") {
                return navigation('/two-fa/sms?phone=' + phone + '&flowType=' + TWO_FA_PROCESS_FLOW_TYPE.REGISTER)
            } else {
                setError("phone_number_already_exists")
            }
            setIsLoading(false)
        })
    }

    if (responsiveness == RESPONSIVENESS_PARAMETER.DESKTOP) {
        return (
            <>
                <div className='flex justify-center items-center mx-auto h-8 w-full'>
                    <LanguageBar dark />
                </div>
                <MarketingStripe />

                <div className="flex flex-row w-screen" style={{
                    height: "calc(100vh - 7.5rem)",
                    overflow: "hidden"
                }}>
                    <div className='w-1/2 h-full bg-red-500 flex justify-end items-end'>
                        <div
                            className='max-w-xl m-auto my-auto mr-8'
                        >
                            <img
                                className='rounded-2xl'
                                src="https://ichdich-website-static.s3.eu-central-1.amazonaws.com/feature1.png"
                            />
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <div className="flex flex-col items-start justify-center h-full space-y-2 ml-8 w-full">
                            <div className='border-[0.5rem] border-red-300 bg-red-300 rounded-3xl p-2'>
                                <div className='border-[0.75rem] border-red-100 bg-red-100 rounded-2xl p-2'>
                                    <div className="bg-white p-8  shadow-md w-96 rounded-lg">
                                        <div className='flex-row flex justify-between'>
                                            <h2 className="text-2xl mb-4 font-bold text-red-700">{pageLang.navbar_title}</h2>
                                            <img
                                                className='mb-4 w-9 aspect-square rounded-sm'
                                                src='https://ichdich-website-static.s3.eu-central-1.amazonaws.com/logo%20%281%29.png'
                                            />
                                        </div>
                                        <Form layout="vertical" onFinish={onRegister}>
                                            <Form.Item label={commonLang.phone_number}>
                                                <PhoneInput value={phone} onChange={setPhone} />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button loading={isLoading} onClick={onRegister} type="primary" danger block>{pageLang.register_button}</Button>
                                            </Form.Item>
                                            <a href="https://ichdich.eu/support" className='text-xs text-center '>
                                                <p className='text-center mb-3'>
                                                    {pageLang.legal_disclosure}
                                                </p>
                                            </a>
                                            <Link to="/login">
                                                <p className='text-center'>{pageLang.login_redirect_button}</p>
                                            </Link>
                                        </Form>
                                        <p className='text-center mt-4 mb-0'>
                                            {pageLang.errors[error]}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        )
    } else {
        return (
            <div className="flex flex-col items-center h-screen w-screen">
                <MarketingStripe />
                <div className="bg-white p-8        ">
                    <div className='flex-row flex justify-between'>
                        <h2 className="text-2xl mb-4 font-bold text-red-700">{pageLang.navbar_title}</h2>
                        <img
                            className='mb-4 w-9 aspect-square rounded-sm'
                            src='https://ichdich-website-static.s3.eu-central-1.amazonaws.com/logo%20%281%29.png'
                        />
                    </div>
                    <Form layout="vertical" onFinish={onRegister}>
                        <Form.Item label={commonLang.phone_number}>
                            <PhoneInput value={phone} onChange={setPhone} />
                        </Form.Item>
                        <Form.Item>
                            <Button loading={isLoading} onClick={onRegister} type="primary" danger block>{pageLang.register_button}</Button>
                        </Form.Item>
                        <a href="https://ichdich.eu/support" className='text-xs text-center '>
                            <p className='text-center mb-3'>
                                {pageLang.legal_disclosure}
                            </p>
                        </a>
                        <Link to="/login">
                            <p className='text-center'>{pageLang.login_redirect_button}</p>
                        </Link>
                    </Form>
                    <p className='text-center mt-4 mb-0'>
                        {pageLang.errors[error]}
                    </p>
                </div>
                <LanguageBar dark />
            </div>
        )
    }
}


