import React, { useState } from 'react'
import { INTERESTS, REGISTRATION_CHECKPOINT } from '../../../../constants'
import { useLanguage } from '../../../../lang/utils/utils'

export default function InterestsSCInput({
    defaultValue,
    placeholder,
    onChange,
    label,
    centered
}) {
    const [value, setValue] = useState(defaultValue ?? [])
    const componentLang = useLanguage("components.self_containing_inputs")[REGISTRATION_CHECKPOINT.INTERESTS]

    return (
        <div className='flex-1'>
            <p className='text-xs font-semibold text-gray-500 mb-1 ml-0.5 '>
                {label}
            </p>
            <div className={`flex-row flex flex-wrap ${centered ? "justify-center" : ""}`}>
                {INTERESTS.map((val) => {
                    return <div
                        key={val.value}
                        className={`
                            mr-2 mb-2 bg-red-300 text-xs 
                            text-red-50 font-medium py-1 px-2 rounded-full
                            cursor-pointer hover:bg-red-400 transition
                            ${value?.includes(val.value) ? "bg-red-500" : ""}
                        `}
                        onClick={() => {
                            if (value?.includes(val.value)) {
                                setValue(value.filter((v) => {
                                    return v !== val.value
                                }))
                                onChange(value.filter((v) => {
                                    return v !== val.value
                                }))
                            } else if (value?.length < 5) {
                                setValue([...value, val.value])
                                onChange([...value, val.value])
                            }
                        }}
                    >
                       {componentLang.options[val.value]} {val.emoji}
                    </div>
                })}
            </div>
        </div >
    )
}
