import React from 'react'
import TextSCInput from './textInput'
import { AutoComplete, Select } from 'antd'
import { COUNTRIES_WITH_CODE, REGISTRATION_CHECKPOINT } from '../../../../constants'
import { useLanguage } from '../../../../lang/utils/utils'


export default function GiftAddressScInput({
    defaultValue,
    placeholder,
    onChange,
    inputMode,
    label,
    flat
}) {
    const [searchQuery, setSearchQuery] = React.useState('')
    const [value, setValue] = React.useState(defaultValue)
    const componentLang = useLanguage("components.self_containing_inputs")[REGISTRATION_CHECKPOINT.GIFT_ADDRESS]

    const options = COUNTRIES_WITH_CODE.map((val) => {
        return {
            label: val.title,
            value: val.value,
        }
    })

    if (!flat) {
        return (
            <div className='space-y-5'>
                <div className='flex w-full'>
                    <div className='flex flex-col flex-1'>
                        <p className='text-xs font-semibold text-gray-500 mb-1 ml-0.5 '>
                            Country
                        </p>
                        <Select
                            style={{ width: '100%' }}
                            options={options}
                            showArrow
                            value={value?.country}
                            onChange={(value) => {
                                onChange({ ...defaultValue, country: value })
                                setValue({ ...defaultValue, country: value })
                            }}
                            onSearch={(value) => { setSearchQuery(value) }}
                        />
                    </div>
                </div>
                <div className='flex space-x-5'>
                    <TextSCInput
                        defaultValue={value?.zipCode}
                        placeholder={"Zip Code"}
                        label={"Zip Code"}
                        onChange={(value) => {
                            onChange({ ...defaultValue, zipCode: value })
                            setValue({ ...defaultValue, zipCode: value })
                        }}
                    />
                    <TextSCInput
                        defaultValue={value?.addressLine1}
                        placeholder={"Address Line 1"}
                        label={"Address Line 1"}
                        onChange={(value) => {
                            onChange({ ...defaultValue, addressLine1: value })
                            setValue({ ...defaultValue, addressLine1: value })
                        }}
                    />
                </div>
                <div className='flex space-x-5'>
                    <TextSCInput
                        defaultValue={value?.addressLine2}
                        placeholder={"Address Line 2"}
                        label={"Address Line 2"}
                        onChange={(value) => {
                            onChange({ ...defaultValue, addressLine2: value })
                            setValue({ ...defaultValue, addressLine2: value })
                        }}
                    />
                    <TextSCInput
                        defaultValue={value?.city}
                        placeholder={"City"}
                        label={"City"}
                        onChange={(value) => {
                            onChange({ ...defaultValue, city: value })
                            setValue({ ...defaultValue, city: value })
                        }}
                    />
                </div>
            </div>
        )
    } else {
        return (
            <div className='space-y-5'>
                <div className='flex w-full'>
                    <div className='flex flex-col flex-1'>
                        <p className='text-xs font-semibold text-gray-500 mb-1 ml-0.5 '>
                            {componentLang.placeholders.country}
                        </p>
                        <Select
                            style={{ width: '100%' }}
                            options={options}
                            showArrow
                            value={value?.country}
                            onChange={(value) => {
                                onChange({ ...defaultValue, country: value })
                                setValue({ ...defaultValue, country: value })
                            }}
                            onSearch={(value) => { setSearchQuery(value) }}
                        />
                    </div>
                </div>
                <div className='flex flex-col space-y-4'>
                    <TextSCInput
                        defaultValue={value?.zipCode}
                        placeholder={componentLang.placeholders.zipCode}
                        label={componentLang.placeholders.zipCode}
                        onChange={(value) => {
                            onChange({ ...defaultValue, zipCode: value })
                            setValue({ ...defaultValue, zipCode: value })
                        }}
                    />
                    <TextSCInput
                        defaultValue={value?.addressLine1}
                        placeholder={componentLang.placeholders.addressLine1}
                        label={componentLang.placeholders.addressLine1}
                        onChange={(value) => {
                            onChange({ ...defaultValue, addressLine1: value })
                            setValue({ ...defaultValue, addressLine1: value })
                        }}
                    />
                    <TextSCInput
                        defaultValue={value?.addressLine2}
                        placeholder={componentLang.placeholders.addressLine2}
                        label={componentLang.placeholders.addressLine2}
                        onChange={(value) => {
                            onChange({ ...defaultValue, addressLine2: value })
                            setValue({ ...defaultValue, addressLine2: value })
                        }}
                    />
                    <TextSCInput
                        defaultValue={value?.city}
                        placeholder={componentLang.placeholders.city}
                        label={componentLang.placeholders.city}
                        onChange={(value) => {
                            onChange({ ...defaultValue, city: value })
                            setValue({ ...defaultValue, city: value })
                        }}
                    />
                </div>
            </div>
        )
    }
}
