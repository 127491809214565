import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import registerAPI from '../../logic/services/register';
import { useLanguage } from '../../lang/utils/utils';
import ReactCodeInput from 'react-code-input'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Input, Progress } from 'antd';
import authAPI from '../../logic/services/auth';
import { REGISTRATION_CHECKPOINT, RESPONSIVENESS_PARAMETER, TWO_FA_PROCESS_FLOW_TYPE } from '../../constants';
import { useRecoilState } from 'recoil';
import { appAtoms, userAtoms } from '../../logic/store/store';
import { checkPasswordStrength } from '../../logic/helpers/password';
import { deleteUserAccessKey } from '../../logic/store/global';

export default function PasswordInput() {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = React.useState(false)
    const [isValid, setIsValid] = React.useState(false)
    const [code, setCode] = React.useState('')
    const [error, setError] = React.useState(null)
    const [passwordOnce, setPasswordOnce] = React.useState('')
    const [passwordTwice, setPasswordTwice] = React.useState('')
    const [responsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)
    const passWordComponentLang = useLanguage('components.password_helper')


    const pageLang = useLanguage("components.self_containing_inputs")[REGISTRATION_CHECKPOINT.PASSWORD]
    const componentLang = useLanguage("components.details_frame")
    const passwordLang = useLanguage("screens.register_set_password")

    const phoneNumber = searchParams.get('phone');
    const flowType = searchParams.get('flowType');
    const twoFactorTransactionId = searchParams.get('twoFactorTransactionId');
    const twoFactorTransactionCode = searchParams.get('twoFactorTransactionCode');
    const commonLang = useLanguage("common")
    useEffect(() => {
        if (passwordOnce !== passwordTwice) {
            setError("password_match")
        } else {
            setError(null)
        }
    }, [passwordOnce, passwordTwice])

    const navigation = useNavigate();

    if (!flowType) {
        navigation('/')
    }

    const [isLoggedIn, setIsLoggedIn] = useRecoilState(userAtoms.isLoggedInAtom)
    const onSend = async () => {
        if (passwordOnce !== passwordTwice) return
        setIsLoading(true)
        if (flowType == TWO_FA_PROCESS_FLOW_TYPE.REGISTER) {
            const res = await registerAPI.registerViaPhoneStwpTwoSetPassword(
                twoFactorTransactionId,
                twoFactorTransactionCode,
                passwordOnce
            )
            if (res) {
                setIsLoggedIn(true)
            }
        }
        if (flowType == TWO_FA_PROCESS_FLOW_TYPE.RECOVERY) {
            const res = await authAPI.recoverySetPassword(
                twoFactorTransactionId,
                twoFactorTransactionCode,
                passwordOnce
            )
            if (res) {
                setIsLoggedIn(true)
            }
        }
        setIsLoading(false)
    }

    const doLogout = () => {
        deleteUserAccessKey()
        setIsLoggedIn(false)
        navigation('/')
    }

    React.useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                onSend()
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []); // Empty dependency array ensures the effect runs only once on component mount

    const onCodeChange = (code) => {
        setCode(code)
        setIsValid(true)
    }

    React.useEffect(() => {
        //getTwoFactorTransactionId()
    }, [])

    if (responsiveness == RESPONSIVENESS_PARAMETER.DESKTOP) {
        return (
            <div className='prose max-w-full w-screen h-screen flex justify-center items-center align-middle flex-row m-0 bg-red-500'>
                <div className='text-center w-80 m-0 p-8 rounded-2xl bg-white space-y-8'>
                    <span>
                        <h5 className='m-0 font-bold text-red-500'>{pageLang.title}</h5>
                        <h3 className='m-0'>{pageLang.placeholder}</h3>
                    </span>
                    <div className='flex flex-col space-y-2 justify-center items-center w-full mt-2'>
                        <Input.Password placeholder={pageLang.placeholder}
                            onChange={(e) => {
                                setPasswordOnce(e.target.value)
                            }}
                        />
                        <Input.Password placeholder={pageLang.placeholder}
                            onChange={(e) => {
                                setPasswordTwice(e.target.value)
                            }}
                        />
                    </div>
                    <div className='my-2' hidden={!error}>
                        {error !== null && <p className='text-red-500 text-xs'>{passwordLang.errors[error]}</p>}
                    </div>
                    <div className='my-4'>
                        <Progress percent={Math.ceil(checkPasswordStrength(passwordOnce ?? "", passWordComponentLang).strengthPercent)} />
                        <p className=''>
                            {checkPasswordStrength(passwordOnce ?? "", passWordComponentLang).helpertext}
                        </p>
                    </div>
                    <Button
                        disabled={
                            Math.ceil(checkPasswordStrength(passwordOnce ?? "", passWordComponentLang).strengthPercent) < 99
                        }
                        onClick={onSend}
                        loading={isLoading}
                        className='w-full mt-4'
                        danger
                        type='primary'
                        children={
                            componentLang.save
                        } />

                    <a className="no-underline text-xs mt-4 hover:text-blue-600 cursor-pointer" onClick={doLogout}>
                        {commonLang.logout}
                    </a>
                </div>
            </div>
        )
    } else {
        return (
            <div className='prose max-w-full w-screen h-screen flex justify-center items-center align-middle flex-row m-0 bg-white '>
                <div className='text-center flex-1 m-0 p-8 rounded-2xl bg-white space-y-8'>
                    <span>
                        <h5 className='m-0 font-bold text-red-500'>{pageLang.title}</h5>
                        <h3 className='m-0'>{pageLang.placeholder}</h3>
                    </span>
                    <div className='flex flex-col space-y-2 justify-center items-center w-full mt-2'>
                        <Input.Password placeholder={pageLang.placeholder}
                            onChange={(e) => {
                                setPasswordOnce(e.target.value)
                            }}
                        />
                        <Input.Password placeholder={pageLang.placeholder}
                            onChange={(e) => {
                                setPasswordTwice(e.target.value)
                            }}
                        />
                    </div>

                    <div className='my-4'>
                        <Progress percent={Math.ceil(checkPasswordStrength(passwordOnce ?? "", passWordComponentLang).strengthPercent)} />
                        <p className=''>
                            {checkPasswordStrength(passwordOnce ?? "", passWordComponentLang).helpertext}
                        </p>
                    </div>
                    <Button
                        disabled={
                            Math.ceil(checkPasswordStrength(passwordOnce ?? "", passWordComponentLang).strengthPercent) < 99
                        }
                        onClick={onSend}
                        loading={isLoading}
                        className='w-full mt-4'
                        danger
                        type='primary'
                        children={
                            componentLang.save
                        } />
                    <div className='my-2 h-12' >
                        {error !== null && <p className='text-red-500 text-xs'>{passwordLang.errors[error]}</p>}
                    </div>

                    <a className="no-underline text-xs mt-4 hover:text-blue-600 cursor-pointer" onClick={doLogout}>
                        {commonLang.logout}
                    </a>
                </div>
            </div>
        )
    }

}
