import React, { useEffect, useState } from 'react';
import { AutoComplete, InputNumber } from 'antd';
import { useLanguage } from '../../../lang/utils/utils';
import { COUNTRIES_WITH_E164CODE } from '../../../constants';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { getDeviceCountry } from '../../../logic/services/ip';

export default function PhoneInput({ onChange }) {
    const [options, setOptions] = useState(COUNTRIES_WITH_E164CODE);
    const [selectedCountryCode, setSelectedCountryCode] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        getDeviceCountry().then(res => {
            console.log(COUNTRIES_WITH_E164CODE.find(x => x.countryCode == res).e164Code);
            setSelectedCountryCode(COUNTRIES_WITH_E164CODE.find(x => x.countryCode == res).e164Code)
        })
    }, [])

    const onSelect = (e164Code) => {
        setSelectedCountryCode(e164Code);
        const phone = parsePhoneNumber(e164Code + phoneNumber);
        onChange(phone);
    };

    const onPhoneNumberChange = (number) => {
        setPhoneNumber(number);
        if (selectedCountryCode) {
            const phone = parsePhoneNumber(selectedCountryCode + number);
            onChange(phone.number.e164);
        }
    };

    return (
        <div className='flex flex-row space-x-2'>
            <AutoComplete
                options={options.map((country) => {
                    return {
                        label: country.e164Code,
                        value: country.e164Code,
                    };
                })}
                onSearch={(value) => {
                    setSelectedCountryCode(value)
                    setOptions(COUNTRIES_WITH_E164CODE.filter((country) => {
                        return country.e164Code.includes(value);
                    }));
                }}
                value={selectedCountryCode}
                style={{ width: 80 }}
                onSelect={onSelect}
                placeholder="+1"
            />
            <InputNumber
                style={{ flex: 1 }}
                onChange={onPhoneNumberChange}
                value={phoneNumber}
            />
        </div>
    );
}
