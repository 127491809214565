import React from 'react'
import { appAtoms } from '../../../logic/store/store'
import { LANGUAGES } from '../../../constants'

import { useRecoilState } from 'recoil'
import { setAppLanguage } from '../../../logic/store/global'

export default function LanguageBar({
    dark = false
}) {
    const [langeuage, setLanguage] = useRecoilState(appAtoms.langAtom)

    return (
        <div className='space-x-2 justify-center flex flex-wrap '>
            {LANGUAGES.map((lang) => {
                return <button
                    className={dark ? 'text-gray' : 'text-white' + ' hover:text-blue-500'}
                    key={lang.code} onClick={() => {
                        setLanguage(lang.code)
                        setAppLanguage(lang.code)
                    }}>{lang.nativeWord}</button>
            })}
        </div>
    )
}
