import { REGISTRATION_CHECKPOINT } from "../../constants";
import userAPI from "../services/user";

export const detailSavers = {
    [REGISTRATION_CHECKPOINT.AGE_PREF]: async (data) => {
        return (await userAPI.setDetail('age_pref', "age", data)).success;
    },
    [REGISTRATION_CHECKPOINT.BIO]: async (data) => {
        return (await userAPI.setDetail('bio', "bio", data)).success;
    },
    [REGISTRATION_CHECKPOINT.BIRTH_DATE]: async (data) => {
        return (await userAPI.setDetail('birth_date', "birthDate", data)).success;
    },
    [REGISTRATION_CHECKPOINT.EMAIL]: async (data) => {
        return (await userAPI.setDetail('email', "email", data)).success;
    },
    [REGISTRATION_CHECKPOINT.FULL_NAME]: async (data) => {
        return (await userAPI.setDetail('name', "fullName", data)).success;
    },
    [REGISTRATION_CHECKPOINT.GENDER]: async (data) => {
        return (await userAPI.setDetail('gender_id', "gender", data)).success;
    },
    [REGISTRATION_CHECKPOINT.GENDER_PREF]: async (data) => {
        return (await userAPI.setDetail('gender_pref', "genders", data)).success;
    },
    [REGISTRATION_CHECKPOINT.GIFT_ADDRESS]: async (data) => {
        return (await userAPI.setGiftAddress(data)).success;
    },
    [REGISTRATION_CHECKPOINT.INTERESTS]: async (data) => {
        return (await userAPI.setDetail('interests', "interests", data)).success;
    },
    [REGISTRATION_CHECKPOINT.NICKNAME]: async (data) => {
        return (await userAPI.setDetail('nickname', "nickName", data)).success;
    },
    [REGISTRATION_CHECKPOINT.LOCATION]: async (data) => {
        return (await userAPI.setLocation(data?.lng,data?.lat)).success
    },
    [REGISTRATION_CHECKPOINT.OTHER_PHOTOS]: null,
    [REGISTRATION_CHECKPOINT.PASSWORD]: null,
    [REGISTRATION_CHECKPOINT.RAW_PHOTO]: null,
    [REGISTRATION_CHECKPOINT.PREFERENCES]: null
}
