import { Input } from 'antd'
import React, { useState } from 'react'

export default function TextSCInput({
    defaultValue,
    placeholder,
    onChange,
    inputMode = null,
    label,
    multiline
}) {
    const [value, setValue] = useState(defaultValue ?? null)



    return (
        <div className='flex-1'>
            <p className='text-xs font-semibold text-gray-500 mb-1 ml-0.5 '>
                {label}
            </p>

            {multiline ? <Input.TextArea
                value={value}
                placeholder={placeholder}
                inputMode={inputMode ? inputMode : "text"}
                onChange={(e) => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                }}
            /> : <Input
                value={value}
                placeholder={placeholder}
                inputMode={inputMode ? inputMode : "text"}
                onChange={(e) => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                }}
            />}
        </div>
    )
}
