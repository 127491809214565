import React, { useEffect } from 'react'
import * as AntdIcons from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'

import { NavBar, Space, Toast } from 'antd-mobile'
export default function TopNavBar({
    title,
    hasLogo,
    hasBack,
    hasLanguage,
    hasSettings,
    hasSupport,
    navDisabled
}) {
    let navigate = (to) => {
        if (to === 'back') {
            window.history.back(); // Go back to the previous page
        } else if (to === 'forward') {
            window.history.forward(); // Go forward to the next page
        } else if (to === 'reload') {
            window.location.reload(); // Reload the current page
        } else if (typeof to === 'string') {
            window.location.href = to; // Navigate to the specified URL
        } else {
            console.error('Invalid navigation action:', to);
        }
    };

    const back = () => {
        navigate('back')
    }
    const lang = () => {
        navigate("/language")
    }


    return (
        <NavBar
            className='flex justify-center items-center'
            onBack={hasBack ? back : hasLanguage ? lang : null}
            backArrow={hasLanguage ? <AntdIcons.TranslationOutlined /> : hasBack ? <AntdIcons.LeftOutlined /> : null}
            right={
                hasSettings
                    ?
                    <div onClick={() => { navigate("/settings") }} className='text-[24px]'>
                        <AntdIcons.SettingOutlined />
                    </div>
                    :
                    hasSupport
                        ?
                        <div onClick={() => { navigate("https://ichdich.eu/support") }} className='text-[24px]'>
                            <AntdIcons.QuestionCircleOutlined />
                        </div>
                        :
                        null
            }
        >
            {!hasLogo && <p className='mt-2'>{title}</p>}
            {hasLogo && <div className='flex flex-row space-x-2 justify-center items-center'>
                <img
                    className={`rounded-lg h-8 w-8`}
                    src="https://ichdich-website-static.s3.eu-central-1.amazonaws.com/logo%20%281%29.png"
                />
                <h2
                    className='m-0 font-bold p-0 '
                >IchDich</h2>
            </div>}
        </NavBar>
    )
}
