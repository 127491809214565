import { atom } from 'recoil';
import { RESPONSIVENESS_PARAMETER } from '../../constants';


export let langAtom = atom({
  key: 'lang',
  default: 'en',
});

export let responsiveNessSwitchAtom = atom({
  key: "responsiveNessSwitch",
  default: RESPONSIVENESS_PARAMETER.DESKTOP
})

const isFacebookLoginEnabledAtom = atom({
  key: 'isFacebookLoginEnabled',
  default: false,
});

export const appAtoms = {
  langAtom,
  isFacebookLoginEnabledAtom,
  responsiveNessSwitchAtom
}

///AUTH ATOMS
const hasPaidAtom = atom({
  key: 'hasPaid',
  default: false,
});

const userIdAtom = atom({
  key: 'deviceId',
  default: null,
});

const isLoggedInAtom = atom({
  key: 'isLoggedIn',
  default: false,
});


export const userAtoms = {
  hasPaidAtom,
  isLoggedInAtom,
  userIdAtom
}




///REGISTER ATOMS


const missingCheeckpointsAtom = atom({
  key: "missingCheeckpoints",
  default: []
})

const registerCompletionPercent = atom({
  key: "registerCompletionPercent",
  default: 0
})

export const regsterAtoms = {
  missingCheeckpointsAtom,
  registerCompletionPercent
}


///?CHAT


const chatChannelAtom = atom({
  key: 'chatChannel',
  default: null,
});

const otherUserIdAtom = atom({
  key: 'otherUserId',
  default: null,
});

const promptEventBusAtom = atom({
  key: 'promptEventBus',
  default: []
});

export const chatAtoms = {
  chatChannelAtom,
  otherUserIdAtom,
  promptEventBusAtom
}


const otherUserCountryCodeAtom = atom({
  key: 'otherUserCountryCode',
  default: null,
});
const otherUserProfileAtom = atom({
  key: 'otherUserProfile',
  default: null,
});

const avalibleStoresAtom = atom({
  key: 'avalibleStores',
  default: [],
})

const cartAtom = atom({
  key: 'cart',
  default: [],
})

const selectedVendorAtom = atom({
  key: 'selectedVendor',
  default: null,
})

const selectedCategoryAtom = atom({
  key: 'selectedCategory',
  default: null,
})

const selectedProductAtom = atom({
  key: 'selectedProduct',
  default: null,
})

export const storesAtoms = {
  otherUserCountryCodeAtom,
  otherUserProfileAtom,
  avalibleStoresAtom,
  cartAtom,
  selectedVendorAtom,
  selectedCategoryAtom,
  selectedProductAtom
}