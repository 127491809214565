import userAPI from "./user";
import { getDeviceId, getUserAccessKey } from "../store/global";
import axios from "axios";
import { API_BASE_URL } from "../../constants";


const RECOMMENDATION_API_BASE = "/recommendations/v1";
const USER_API_BASE = "/user/v1";

async function getRecommendation() {
  const myHeaders = new Headers();
  myHeaders.append("x-device-id", await getDeviceId());
  myHeaders.append("x-key", await getUserAccessKey());

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
  };

  try {
    const response = await fetch(API_BASE_URL + RECOMMENDATION_API_BASE + '/recommend', requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log('error', error);
  }
}


async function getPendingMatches() {
  var myHeaders = new Headers();
  myHeaders.append("x-device-id", await getDeviceId());
  myHeaders.append("x-key", await getUserAccessKey());

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    var res = await fetch(API_BASE_URL + USER_API_BASE + "/matches/pending", requestOptions)
    var dat = (await res.json()).data

    console.log(API_BASE_URL + USER_API_BASE + "/matches/pending");
    if (dat.length === 0) return []

    var d = await Promise.all(dat.map(async p => {
      return {
        ...(await userAPI.getUserProfile(p.userId)),
        ...p
      }
    }))

    return d
  } catch (error) {
    return []
  }
}

const hottestIntervals = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly'
}

async function getHottest(interval) {
  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: API_BASE_URL + RECOMMENDATION_API_BASE + `/top/${interval}`,
    headers: {
      'x-device-id': await getDeviceId(),
      'x-key': await getUserAccessKey(),
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios.request(config);
    return response.data

  } catch (error) {
    console.log(error, "getHottest");
  }
}

const recommendAPI = {
  getRecommendation,
  getPendingMatches,
  dismissMatch: null,
  likeUser: null,
  disLikeUser: null,
  getHottest,
  hottestIntervals
}

export default recommendAPI
