import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from '../pages/loggedIn/home';
import Profile from '../pages/loggedIn/profile';
import Settings from '../pages/loggedIn/settings';
import Chat from '../pages/loggedIn/chat';
import StoreLanding from '../pages/loggedIn/store/storeLanding';
import Product from '../pages/loggedIn/store/product';
import { useRecoilState } from 'recoil';
import { regsterAtoms } from '../logic/store/store';
import userAPI from '../logic/services/user';
import isCrutialCheckpointsMissing from '../logic/helpers/chechkpoints';
import Checkpoint from '../pages/loggedIn/checkpoint';
import Language from '../pages/shared/language';
import GiftAddress from '../pages/loggedIn/giftAddress';


export default function LoggedInRouter() {
  const [missingCheckpoints, setMissingCheckpoints] = useRecoilState(regsterAtoms.missingCheeckpointsAtom)

  useEffect(() => {
    userAPI.getCheckpoints().then(checpoints => {
      if (checpoints.success) {
        setMissingCheckpoints(checpoints.missingCheckpoints)
      }
    })
  }, [])

  if (isCrutialCheckpointsMissing(missingCheckpoints)) {
    return <Checkpoint />
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/profile' element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path='/chat' element={<Chat />} />
        <Route path='/store' element={<StoreLanding />} />
        <Route path='/product' element={<Product />} />
        <Route path="/language" element={<Language />} />
        <Route path='*' element={<Home />} />
      </Routes>
    </Router>
  )
}