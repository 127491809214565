import React from 'react'
import { BrowserRouter as Router, Routes, Route, Link, Redirect } from 'react-router-dom';
import Login from '../pages/loggedOut/login';
import Register from '../pages/loggedOut/register';
import TwoFactorSms from '../pages/shared/twoFactorSms';
import PasswordInput from '../pages/shared/passwordInput';
import Language from '../pages/shared/language';


export default function LoggedOutRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path='/support' element={null} />
        <Route path='/two-fa/sms' element={<TwoFactorSms />} />
        <Route path='/password' element={<PasswordInput />} />
        <Route path="/language" element={<Language />} />
        <Route path='*' element={<Login />} />
      </Routes>
    </Router>
  )
}