

export function checkPasswordStrength(password,componentLang) {
    let strengthPercent = 0;
    let helpertext = '';

    // Calculate strengthPercent based on length, capped at 6 characters, each contributing 16.67% 
    strengthPercent = Math.min(password.length, 6) * (90 / 6);

    if (/\d/.test(password)) {
        // Add 10% to strengthPercent if there's a number in the password
        strengthPercent += 90 / 6;
    }

    if (password.length < 6) {
        // Helpertext for short password
        helpertext = componentLang["too_short"];
    } else if (!/\d/.test(password)) {
        // Helpertext for password without numbers
        helpertext = componentLang["no_number"];
    } else {
        // Helpertext for strong password
        helpertext = componentLang["strong"];
    }

    return { strengthPercent, helpertext };
}