export function transformArray(array, baseRows) {
    let bucketed = [];
    array.forEach((item, index) => {
        if (bucketed.length == 0) {
            bucketed.push([item]);
            return;
        }

        const currentBucket = bucketed[bucketed.length - 1];
        const isEvenBucket = (bucketed.length - 1) % 2 === 0;
        const bucketLength = currentBucket.length;
        const maxBucketLength = isEvenBucket ? baseRows : baseRows - 1;

        if (bucketLength < maxBucketLength) {
            currentBucket.push(item);
        } else {
            bucketed.push([item]);
        }
    });
    return bucketed.map((bucket, index) => {
        return {
            groupShift: index % 2 === 0 ? false : true,
            items: bucket
        }
    });
}
