import axios from 'axios';

export const getDeviceCountry = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://x5um37pu7b5rw56yxfvxardx7m0rckzz.lambda-url.eu-west-1.on.aws/',
    };

    try {
        return (await axios.request(config)).data
    } catch (error) {
        return "AT"
    }
}