import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';
import { Button } from 'antd';
import 'leaflet/dist/leaflet.css';
import * as AntdIcons from '@ant-design/icons'
import { useLanguage } from '../../../../lang/utils/utils';
import { REGISTRATION_CHECKPOINT } from '../../../../constants';

function LocationMarker({ position }) {
    const map = useMap();

    useEffect(() => {
        if (position) {
            map.setZoom(10)
            map.flyTo(position)
        }
    }, [position, map]);

    const customIcon = new Icon({
        iconUrl: 'https://ichdich-website-static.s3.eu-central-1.amazonaws.com/marker.png', // specify the path to your image
        iconSize: [65, 81], // specify the size of your icon if you wish
    });

    return position === null ? null : (
        <Marker position={position} icon={customIcon}>
            <Popup>Current location</Popup>
        </Marker>
    );
}

export default function LocationSetter({ onLocation }) {
    const [location, setLocation] = useState({
        lat: 50,
        lng: 15,
        zoom: 2,
    });

    const [error, setError] = useState(null)

    const updateLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const newLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    zoom: 13,
                };
                setError(null)
                setLocation(newLocation);
                onLocation(newLocation);
            },
            (e) => {
                if(e.code == 1){
                    setError("permission")
                }else{
                    setError("other")
                }
            }
        )
    };

    useEffect(() => {
        updateLocation();
    }, []);

    const componentLang = useLanguage("components.self_containing_inputs")[REGISTRATION_CHECKPOINT.LOCATION]
    const position = [location.lat, location.lng];

    return (
        <div className="rounded-lg overflow-hidden mt-5 flex flex-col">
            <MapContainer center={position} zoom={location.zoom} style={{ width: '100%', height: '400px' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <LocationMarker position={position} />
                <Button
                    style={{ position: 'absolute', bottom: 10, right: 10, zIndex: 1000 }}
                    type="primary"
                    danger
                    onClick={updateLocation}
                >
                    {componentLang.retry}
                </Button>
            </MapContainer>
            <p hidden={!error} className='text-xs text-red-600 font-bold text-center mt-3'>
                {componentLang.errors[error]}
            </p>
        </div>
    );
}
