export const GENDERS = [
  { label: 'Female', value: 'female' },
  { label: 'Male', value: 'male' },
  { label: 'Non-binary', value: 'nonbinary' },
  { label: 'Genderqueer', value: 'genderqueer' },
  { label: 'Transgender', value: 'transgender' },
  { label: 'Genderfluid', value: 'genderfluid' },
  { label: 'Agender', value: 'agender' },
  { label: 'Other', value: 'other' }
]
export const INTERESTS = [
  { name: 'Soccer', value: 'soccer', emoji: '⚽' },
  { name: 'Gamer', value: 'gamer', emoji: '🎮' },
  { name: 'Climbing', value: 'climbing', emoji: '🧗‍♂️' },
  { name: 'Fishing', value: 'fishing', emoji: '🎣' },
  { name: 'Photography', value: 'photography', emoji: '📷' },
  { name: 'Volunteering', value: 'volunteering', emoji: '🤝' },
  { name: 'Comedy', value: 'comedy', emoji: '😂' },
  { name: 'Tea', value: 'tea', emoji: '🍵' },
  { name: 'Disney', value: 'disney', emoji: '🐭' },
  { name: 'Dog lover', value: 'dog-lover', emoji: '🐶' },
  { name: 'Walking', value: 'walking', emoji: '🚶' },
  { name: 'Cooking', value: 'cooking', emoji: '🍳' },
  { name: 'Outdoors', value: 'outdoors', emoji: '🏞️' },
  { name: 'Dancing', value: 'dancing', emoji: '💃' },
  { name: 'Picnicking', value: 'picnicking', emoji: '🧺' },
  { name: 'Board games', value: 'board-games', emoji: '🎲' },
  { name: 'Shopping', value: 'shopping', emoji: '🛍️' },
  { name: 'Working out', value: 'working-out', emoji: '💪' },
  { name: 'Baking', value: 'baking', emoji: '🥐' },
  { name: 'Gardening', value: 'gardening', emoji: '🌻' },
  { name: 'Movies', value: 'movies', emoji: '🎬' },
  { name: 'Coffee', value: 'coffee', emoji: '☕' },
  { name: 'Blogging', value: 'blogging', emoji: '✍️' },
  { name: 'Yoga', value: 'yoga', emoji: '🧘‍♀️' },
  { name: 'Running', value: 'running', emoji: '🏃' },
  { name: 'Golf', value: 'golf', emoji: '⛳' },
  { name: 'Sprittuality', value: 'sprittuality', emoji: '🌿' },
  { name: 'Grab a drink', value: 'grab-a-drink', emoji: '🍻' },
  { name: 'Travel', value: 'travel', emoji: '✈️' },
  { name: 'Swimming', value: 'swimming', emoji: '🏊' },
  { name: 'DIY', value: 'diy', emoji: '🔨' },
  { name: 'Hiking', value: 'hiking', emoji: '🥾' },
  { name: 'Astrology', value: 'astrology', emoji: '♈' },
  { name: 'Instagram', value: 'instagram', emoji: '📸' },
  { name: 'Music', value: 'music', emoji: '🎵' },
  { name: 'Museum', value: 'museum', emoji: '🏛️' },
  { name: 'Wine', value: 'wine', emoji: '🍷' },
  { name: 'Brunch', value: 'brunch', emoji: '🍳' },
  { name: 'Reading', value: 'reading', emoji: '📚' },
  { name: 'Foodie', value: 'foodie', emoji: '🍽️' },
  { name: 'Writer', value: 'writer', emoji: '✍️' },
  { name: 'Trivia', value: 'trivia', emoji: '🧠' },
  { name: 'Language Exchange', value: 'language-exchange', emoji: '🗣️' },
  { name: 'Vlogging', value: 'vlogging', emoji: '📹' },
  { name: 'Netflix', value: 'netflix', emoji: '🎥' },
  { name: 'Surfing', value: 'surfing', emoji: '🏄' },
  { name: 'Cycling', value: 'cycling', emoji: '🚴' },
  { name: 'Fashion', value: 'fashion', emoji: '👗' },
  { name: 'Athlete', value: 'athlete', emoji: '🏅' },
  { name: 'Politics', value: 'politics', emoji: '🗳️' },
  { name: 'Craft Beer', value: 'craft-beer', emoji: '🍺' }
]
export const INTERESTS_FEATURED = [
  { name: 'Sailing', value: 'sailing', image: 'sailing.png' },
  { name: 'Movies', value: 'movies', image: 'cinema.png' },
  { name: 'Coffee', value: 'coffee', image: 'coffe.png' },
  { name: 'Travel', value: 'travel', image: 'travel.png' },
  { name: 'Sports', value: 'sports', image: 'tennis.png' },
  { name: 'Dining', value: 'foodie', image: 'restaurant.png' },
]
export const NON_FEATURED_INTERESTS = [
  { name: 'Soccer', value: 'soccer' },
  { name: 'Gamer', value: 'gamer' },
  { name: 'Climbing', value: 'climbing' },
  { name: 'Fishing', value: 'fishing' },
  { name: 'Photography', value: 'photography' },
  { name: 'Volunteering', value: 'volunteering' },
  { name: 'Comedy', value: 'comedy' },
  { name: 'Tea', value: 'tea' },
  { name: 'Disney', value: 'disney' },
  { name: 'Dog lover', value: 'dog-lover' },
  { name: 'Walking', value: 'walking' },
  { name: 'Cooking', value: 'cooking' },
  { name: 'Outdoors', value: 'outdoors' },
  { name: 'Dancing', value: 'dancing' },
  { name: 'Picnicking', value: 'picnicking' },
  { name: 'Board games', value: 'board-games' },
  { name: 'Shopping', value: 'shopping' },
  { name: 'Working out', value: 'working-out' },
  { name: 'Sports', value: 'sports' },
  { name: 'Baking', value: 'baking' },
  { name: 'Gardening', value: 'gardening' },
  { name: 'Blogging', value: 'blogging' },
  { name: 'Yoga', value: 'yoga' },
  { name: 'Running', value: 'running' },
  { name: 'Golf', value: 'golf' },
  { name: 'Sprittuality', value: 'sprittuality' },
  { name: 'Grab a drink', value: 'grab-a-drink' },
  { name: 'Swimming', value: 'swimming' },
  { name: 'DIY', value: 'diy' },
  { name: 'Hiking', value: 'hiking' },
  { name: 'Astrology', value: 'astrology' },
  { name: 'Instagram', value: 'instagram' },
  { name: 'Music', value: 'music' },
  { name: 'Museum', value: 'museum' },
  { name: 'Wine', value: 'wine' },
  { name: 'Brunch', value: 'brunch' },
  { name: 'Reading', value: 'reading' },
  { name: 'Foodie', value: 'foodie' },
  { name: 'Writer', value: 'writer' },
  { name: 'Trivia', value: 'trivia' },
  { name: 'Language Exchange', value: 'language-exchange' },
  { name: 'Vlogging', value: 'vlogging' },
  { name: 'Netflix', value: 'netflix' },
  { name: 'Surfing', value: 'surfing' },
  { name: 'Cycling', value: 'cycling' },
  { name: 'Fashion', value: 'fashion' },
  { name: 'Athlete', value: 'athlete' },
  { name: 'Politics', value: 'politics' },
  { name: 'Craft Beer', value: 'craft-beer' }
]
export const REGISTRATION_CHECKPOINT = {
  BIO: "REGISTRATION:BIO",
  GENDER: "REGISTRATION:GENDER_IDENTITY",
  PREFERENCES: "REGISTRATION:PREFERENCES",
  INTERESTS: "REGISTRATION:INTERESTS",
  RAW_PHOTO: "REGISTRATION:RAW_PHOTO",
  OTHER_PHOTOS: "REGISTRATION:OTHER_PHOTOS",
  LOCATION: "REGISTRATION:LOCATION",
  EMAIL: "REGISTRATION:EMAIL",
  GIFT_ADDRESS: "REGISTRATION:GIFT_ADDRESS",
  AGE_PREF: "REGISTRATION:AGE_PREF",
  GENDER_PREF: "REGISTRATION:GENDER_PREF",
  PASSWORD: "REGISTRATION:PASSWORD",
  NICKNAME: "REGISTRATION:NICKNAME",
  BIRTH_DATE: "REGISTRATION:BIRTH_DATE",
  FULL_NAME: "REGISTRATION:FULL_NAME",
}
export const DETAIL_CATEGORIES = {
  BASIC_INFO: "BASIC_INFO",
  PREFERENCES: "PREFERENCES",
  INTERESTS: "INTERESTS",
  PHOTOS: "PHOTOS",
  LOCATION: "LOCATION",
  GIFTS: "GIFTS",
}
export const MUST_HAVE_REGISTRATION_CHECKPOINTS = {
  [DETAIL_CATEGORIES.BASIC_INFO]: [
    REGISTRATION_CHECKPOINT.NICKNAME,
    REGISTRATION_CHECKPOINT.GENDER,
    REGISTRATION_CHECKPOINT.BIRTH_DATE,
    REGISTRATION_CHECKPOINT.FULL_NAME
  ],
  [DETAIL_CATEGORIES.PREFERENCES]: [
    REGISTRATION_CHECKPOINT.GENDER_PREF,
  ],
  [DETAIL_CATEGORIES.PHOTOS]: [
    REGISTRATION_CHECKPOINT.OTHER_PHOTOS
  ],
  [DETAIL_CATEGORIES.LOCATION]: [
    REGISTRATION_CHECKPOINT.LOCATION
  ]
}
export const BILLING_CHECKPOINTS = [
  REGISTRATION_CHECKPOINT.FULL_NAME,
  REGISTRATION_CHECKPOINT.EMAIL,
  REGISTRATION_CHECKPOINT.LOCATION
]
export const TWO_FA_PROCESS_FLOW_TYPE = {
  NULL: 0,
  RECOVERY: 1,
  REGISTER: 2
}
export const COUNTRIES_WITH_E164CODE = [
  { countryCode: "AF", e164Code: "+93" },
  { countryCode: "AL", e164Code: "+355" },
  { countryCode: "DZ", e164Code: "+213" },
  { countryCode: "AD", e164Code: "+376" },
  { countryCode: "AO", e164Code: "+244" },
  { countryCode: "AG", e164Code: "+1-268" },
  { countryCode: "AR", e164Code: "+54" },
  { countryCode: "AM", e164Code: "+374" },
  { countryCode: "AU", e164Code: "+61" },
  { countryCode: "AT", e164Code: "+43" },
  { countryCode: "AZ", e164Code: "+994" },
  { countryCode: "BS", e164Code: "+1-242" },
  { countryCode: "BH", e164Code: "+973" },
  { countryCode: "BD", e164Code: "+880" },
  { countryCode: "BB", e164Code: "+1-246" },
  { countryCode: "BY", e164Code: "+375" },
  { countryCode: "BE", e164Code: "+32" },
  { countryCode: "BZ", e164Code: "+501" },
  { countryCode: "BJ", e164Code: "+229" },
  { countryCode: "BT", e164Code: "+975" },
  { countryCode: "BO", e164Code: "+591" },
  { countryCode: "BA", e164Code: "+387" },
  { countryCode: "BW", e164Code: "+267" },
  { countryCode: "BR", e164Code: "+55" },
  { countryCode: "BN", e164Code: "+673" },
  { countryCode: "BG", e164Code: "+359" },
  { countryCode: "BF", e164Code: "+226" },
  { countryCode: "BI", e164Code: "+257" },
  { countryCode: "KH", e164Code: "+855" },
  { countryCode: "CM", e164Code: "+237" },
  { countryCode: "CA", e164Code: "+1" },
  { countryCode: "CV", e164Code: "+238" },
  { countryCode: "CF", e164Code: "+236" },
  { countryCode: "TD", e164Code: "+235" },
  { countryCode: "CL", e164Code: "+56" },
  { countryCode: "CN", e164Code: "+86" },
  { countryCode: "CO", e164Code: "+57" },
  { countryCode: "KM", e164Code: "+269" },
  { countryCode: "CD", e164Code: "+243" },
  { countryCode: "CG", e164Code: "+242" },
  { countryCode: "CR", e164Code: "+506" },
  { countryCode: "CI", e164Code: "+225" },
  { countryCode: "HR", e164Code: "+385" },
  { countryCode: "CU", e164Code: "+53" },
  { countryCode: "CY", e164Code: "+357" },
  { countryCode: "CZ", e164Code: "+420" },
  { countryCode: "DK", e164Code: "+45" },
  { countryCode: "DJ", e164Code: "+253" },
  { countryCode: "DM", e164Code: "+1-767" },
  { countryCode: "DO", e164Code: "+1-809" },
  { countryCode: "EC", e164Code: "+593" },
  { countryCode: "EG", e164Code: "+20" },
  { countryCode: "SV", e164Code: "+503" },
  { countryCode: "GQ", e164Code: "+240" },
  { countryCode: "ER", e164Code: "+291" },
  { countryCode: "EE", e164Code: "+372" },
  { countryCode: "ET", e164Code: "+251" },
  { countryCode: "FJ", e164Code: "+679" },
  { countryCode: "FI", e164Code: "+358" },
  { countryCode: "FR", e164Code: "+33" },
  { countryCode: "GA", e164Code: "+241" },
  { countryCode: "GM", e164Code: "+220" },
  { countryCode: "GE", e164Code: "+995" },
  { countryCode: "DE", e164Code: "+49" },
  { countryCode: "GH", e164Code: "+233" },
  { countryCode: "GR", e164Code: "+30" },
  { countryCode: "GD", e164Code: "+1-473" },
  { countryCode: "GT", e164Code: "+502" },
  { countryCode: "GN", e164Code: "+224" },
  { countryCode: "GW", e164Code: "+245" },
  { countryCode: "GY", e164Code: "+592" },
  { countryCode: "HT", e164Code: "+509" },
  { countryCode: "HN", e164Code: "+504" },
  { countryCode: "HU", e164Code: "+36" },
  { countryCode: "IS", e164Code: "+354" },
  { countryCode: "IN", e164Code: "+91" },
  { countryCode: "ID", e164Code: "+62" },
  { countryCode: "IR", e164Code: "+98" },
  { countryCode: "IQ", e164Code: "+964" },
  { countryCode: "IE", e164Code: "+353" },
  { countryCode: "IL", e164Code: "+972" },
  { countryCode: "IT", e164Code: "+39" },
  { countryCode: "JM", e164Code: "+1-876" },
  { countryCode: "JP", e164Code: "+81" },
  { countryCode: "JO", e164Code: "+962" },
  { countryCode: "KZ", e164Code: "+7" },
  { countryCode: "KE", e164Code: "+254" },
  { countryCode: "KI", e164Code: "+686" },
  { countryCode: "KP", e164Code: "+850" },
  { countryCode: "KR", e164Code: "+82" },
  { countryCode: "KW", e164Code: "+965" },
  { countryCode: "KG", e164Code: "+996" },
  { countryCode: "LA", e164Code: "+856" },
  { countryCode: "LV", e164Code: "+371" },
  { countryCode: "LB", e164Code: "+961" },
  { countryCode: "LS", e164Code: "+266" },
  { countryCode: "LR", e164Code: "+231" },
  { countryCode: "LY", e164Code: "+218" },
  { countryCode: "LI", e164Code: "+423" },
  { countryCode: "LT", e164Code: "+370" },
  { countryCode: "LU", e164Code: "+352" },
  { countryCode: "MK", e164Code: "+389" },
  { countryCode: "MG", e164Code: "+261" },
  { countryCode: "MW", e164Code: "+265" },
  { countryCode: "MY", e164Code: "+60" },
  { countryCode: "MV", e164Code: "+960" },
  { countryCode: "ML", e164Code: "+223" },
  { countryCode: "MT", e164Code: "+356" },
  { countryCode: "MH", e164Code: "+692" },
  { countryCode: "MR", e164Code: "+222" },
  { countryCode: "MU", e164Code: "+230" },
  { countryCode: "MX", e164Code: "+52" },
  { countryCode: "FM", e164Code: "+691" },
  { countryCode: "MD", e164Code: "+373" },
  { countryCode: "MC", e164Code: "+377" },
  { countryCode: "MN", e164Code: "+976" },
  { countryCode: "ME", e164Code: "+382" },
  { countryCode: "MA", e164Code: "+212" },
  { countryCode: "MZ", e164Code: "+258" },
  { countryCode: "MM", e164Code: "+95" },
  { countryCode: "NA", e164Code: "+264" },
  { countryCode: "NR", e164Code: "+674" },
  { countryCode: "NP", e164Code: "+977" },
  { countryCode: "NL", e164Code: "+31" },
  { countryCode: "NZ", e164Code: "+64" },
  { countryCode: "NI", e164Code: "+505" },
  { countryCode: "NE", e164Code: "+227" },
  { countryCode: "NG", e164Code: "+234" },
  { countryCode: "NO", e164Code: "+47" },
  { countryCode: "OM", e164Code: "+968" },
  { countryCode: "PK", e164Code: "+92" },
  { countryCode: "PW", e164Code: "+680" },
  { countryCode: "PA", e164Code: "+507" },
  { countryCode: "PG", e164Code: "+675" },
  { countryCode: "PY", e164Code: "+595" },
  { countryCode: "PE", e164Code: "+51" },
  { countryCode: "PH", e164Code: "+63" },
  { countryCode: "PL", e164Code: "+48" },
  { countryCode: "PT", e164Code: "+351" },
  { countryCode: "QA", e164Code: "+974" },
  { countryCode: "RO", e164Code: "+40" },
  { countryCode: "RU", e164Code: "+7" },
  { countryCode: "RW", e164Code: "+250" },
  { countryCode: "KN", e164Code: "+1-869" },
  { countryCode: "LC", e164Code: "+1-758" },
  { countryCode: "VC", e164Code: "+1-784" },
  { countryCode: "WS", e164Code: "+685" },
  { countryCode: "SM", e164Code: "+378" },
  { countryCode: "ST", e164Code: "+239" },
  { countryCode: "SA", e164Code: "+966" },
  { countryCode: "SN", e164Code: "+221" },
  { countryCode: "RS", e164Code: "+381" },
  { countryCode: "SC", e164Code: "+248" },
  { countryCode: "SL", e164Code: "+232" },
  { countryCode: "SG", e164Code: "+65" },
  { countryCode: "SK", e164Code: "+421" },
  { countryCode: "SI", e164Code: "+386" },
  { countryCode: "SB", e164Code: "+677" },
  { countryCode: "SO", e164Code: "+252" },
  { countryCode: "ZA", e164Code: "+27" },
  { countryCode: "ES", e164Code: "+34" },
  { countryCode: "LK", e164Code: "+94" },
  { countryCode: "SD", e164Code: "+249" },
  { countryCode: "SR", e164Code: "+597" },
  { countryCode: "SZ", e164Code: "+268" },
  { countryCode: "SE", e164Code: "+46" },
  { countryCode: "CH", e164Code: "+41" },
  { countryCode: "SY", e164Code: "+963" },
  { countryCode: "TW", e164Code: "+886" },
  { countryCode: "TJ", e164Code: "+992" },
  { countryCode: "TZ", e164Code: "+255" },
  { countryCode: "TH", e164Code: "+66" },
  { countryCode: "TL", e164Code: "+670" },
  { countryCode: "TG", e164Code: "+228" },
  { countryCode: "TO", e164Code: "+676" },
  { countryCode: "TT", e164Code: "+1-868" },
  { countryCode: "TN", e164Code: "+216" },
  { countryCode: "TR", e164Code: "+90" },
  { countryCode: "TM", e164Code: "+993" },
  { countryCode: "TV", e164Code: "+688" },
  { countryCode: "UG", e164Code: "+256" },
  { countryCode: "UA", e164Code: "+380" },
  { countryCode: "AE", e164Code: "+971" },
  { countryCode: "GB", e164Code: "+44" },
  { countryCode: "US", e164Code: "+1" },
  { countryCode: "UY", e164Code: "+598" },
  { countryCode: "UZ", e164Code: "+998" },
  { countryCode: "VU", e164Code: "+678" },
  { countryCode: "VA", e164Code: "+379" },
  { countryCode: "VE", e164Code: "+58" },
  { countryCode: "VN", e164Code: "+84" },
  { countryCode: "YE", e164Code: "+967" },
  { countryCode: "ZM", e164Code: "+260" },
  { countryCode: "ZW", e164Code: "+263" }
];
export const LANGUAGES = [
  { code: 'en', nativeWord: 'English' },
  { code: 'zh', nativeWord: '中文' },
  { code: 'de', nativeWord: 'Deutsch' },
  { code: 'es', nativeWord: 'Español' },
  { code: 'fr', nativeWord: 'Français' },
  { code: 'it', nativeWord: 'Italiano' },
  { code: 'hu', nativeWord: 'Magyar' },
  { code: 'ru', nativeWord: 'Русский' },
  { code: 'tr', nativeWord: 'Türkçe' },
  { code: 'ja', nativeWord: '日本語' },
];
export const COUNTRIES_WITH_CODE = [
  { value: 'AF', title: 'Afghanistan' },
  { value: 'AX', title: 'Åland Islands' },
  { value: 'AL', title: 'Albania' },
  { value: 'DZ', title: 'Algeria' },
  { value: 'AS', title: 'American Samoa' },
  { value: 'AD', title: 'Andorra' },
  { value: 'AO', title: 'Angola' },
  { value: 'AI', title: 'Anguilla' },
  { value: 'AQ', title: 'Antarctica' },
  { value: 'AG', title: 'Antigua and Barbuda' },
  { value: 'AR', title: 'Argentina' },
  { value: 'AM', title: 'Armenia' },
  { value: 'AW', title: 'Aruba' },
  { value: 'AU', title: 'Australia' },
  { value: 'AT', title: 'Austria' },
  { value: 'AZ', title: 'Azerbaijan' },
  { value: 'BS', title: 'Bahamas' },
  { value: 'BH', title: 'Bahrain' },
  { value: 'BD', title: 'Bangladesh' },
  { value: 'BB', title: 'Barbados' },
  { value: 'BY', title: 'Belarus' },
  { value: 'BE', title: 'Belgium' },
  { value: 'BZ', title: 'Belize' },
  { value: 'BJ', title: 'Benin' },
  { value: 'BM', title: 'Bermuda' },
  { value: 'BT', title: 'Bhutan' },
  { value: 'BO', title: 'Bolivia (Plurinational State of)' },
  { value: 'BQ', title: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', title: 'Bosnia and Herzegovina' },
  { value: 'BW', title: 'Botswana' },
  { value: 'BV', title: 'Bouvet Island' },
  { value: 'BR', title: 'Brazil' },
  { value: 'IO', title: 'British Indian Ocean Territory' },
  { value: 'BN', title: 'Brunei Darussalam' },
  { value: 'BG', title: 'Bulgaria' },
  { value: 'BF', title: 'Burkina Faso' },
  { value: 'BI', title: 'Burundi' },
  { value: 'CV', title: 'Cabo Verde' },
  { value: 'KH', title: 'Cambodia' },
  { value: 'CM', title: 'Cameroon' },
  { value: 'CA', title: 'Canada' },
  { value: 'KY', title: 'Cayman Islands' },
  { value: 'CF', title: 'Central African Republic' },
  { value: 'TD', title: 'Chad' },
  { value: 'CL', title: 'Chile' },
  { value: 'CN', title: 'China' },
  { value: 'CX', title: 'Christmas Island' },
  { value: 'CC', title: 'Cocos (Keeling) Islands' },
  { value: 'CO', title: 'Colombia' },
  { value: 'KM', title: 'Comoros' },
  { value: 'CG', title: 'Congo' },
  { value: 'CD', title: 'Congo (Democratic Republic of the)' },
  { value: 'CK', title: 'Cook Islands' },
  { value: 'CR', title: 'Costa Rica' },
  { value: 'CI', title: 'Côte dIvoire' },
  { value: 'HR', title: 'Croatia' },
  { value: 'CU', title: 'Cuba' },
  { value: 'CW', title: 'Curaçao' },
  { value: 'CY', title: 'Cyprus' },
  { value: 'CZ', title: 'Czech Republic' },
  { value: 'DK', title: 'Denmark' },
  { value: 'DJ', title: 'Djibouti' },
  { value: 'DM', title: 'Dominica' },
  { value: 'DO', title: 'Dominican Republic' },
  { value: 'EC', title: 'Ecuador' },
  { value: 'EG', title: 'Egypt' },
  { value: 'SV', title: 'El Salvador' },
  { value: 'GQ', title: 'Equatorial Guinea' },
  { value: 'ER', title: 'Eritrea' },
  { value: 'EE', title: 'Estonia' },
  { value: 'ET', title: 'Ethiopia' },
  { value: 'FK', title: 'Falkland Islands (Malvinas)' },
  { value: 'FO', title: 'Faroe Islands' },
  { value: 'FJ', title: 'Fiji' },
  { value: 'FI', title: 'Finland' },
  { value: 'FR', title: 'France' },
  { value: 'GF', title: 'French Guiana' },
  { value: 'PF', title: 'French Polynesia' },
  { value: 'TF', title: 'French Southern Territories' },
  { value: 'GA', title: 'Gabon' },
  { value: 'GM', title: 'Gambia' },
  { value: 'GE', title: 'Georgia' },
  { value: 'DE', title: 'Germany' },
  { value: 'GH', title: 'Ghana' },
  { value: 'GI', title: 'Gibraltar' },
  { value: 'GR', title: 'Greece' },
  { value: 'GL', title: 'Greenland' },
  { value: 'GD', title: 'Grenada' },
  { value: 'GP', title: 'Guadeloupe' },
  { value: 'GU', title: 'Guam' },
  { value: 'GT', title: 'Guatemala' },
  { value: 'GG', title: 'Guernsey' },
  { value: 'GN', title: 'Guinea' },
  { value: 'GW', title: 'Guinea-Bissau' },
  { value: 'GY', title: 'Guyana' },
  { value: 'HT', title: 'Haiti' },
  { value: 'HM', title: 'Heard Island and McDonald Islands' },
  { value: 'VA', title: 'Holy See' },
  { value: 'HN', title: 'Honduras' },
  { value: 'HK', title: 'Hong Kong' },
  { value: 'HU', title: 'Hungary' },
  { value: 'IS', title: 'Iceland' },
  { value: 'IN', title: 'India' },
  { value: 'ID', title: 'Indonesia' },
  { value: 'IR', title: 'Iran (Islamic Republic of)' },
  { value: 'IQ', title: 'Iraq' },
  { value: 'IE', title: 'Ireland' },
  { value: 'IM', title: 'Isle of Man' },
  { value: 'IL', title: 'Israel' },
  { value: 'IT', title: 'Italy' },
  { value: 'JM', title: 'Jamaica' },
  { value: 'JP', title: 'Japan' },
  { value: 'JE', title: 'Jersey' },
  { value: 'JO', title: 'Jordan' },
  { value: 'KZ', title: 'Kazakhstan' },
  { value: 'KE', title: 'Kenya' },
  { value: 'KI', title: 'Kiribati' },
  { value: 'KP', title: 'Korea (Democratic Peoples Republic of)' },
  { value: 'KR', title: 'Korea (Republic of)' },
  { value: 'KW', title: 'Kuwait' },
  { value: 'KG', title: 'Kyrgyzstan' },
  { value: 'LA', title: 'Lao Peoples Democratic Republic' },
  { value: 'LV', title: 'Latvia' },
  { value: 'LB', title: 'Lebanon' },
  { value: 'LS', title: 'Lesotho' },
  { value: 'LR', title: 'Liberia' },
  { value: 'LY', title: 'Libya' },
  { value: 'LI', title: 'Liechtenstein' },
  { value: 'LT', title: 'Lithuania' },
  { value: 'LU', title: 'Luxembourg' },
  { value: 'MO', title: 'Macao' },
  { value: 'MK', title: 'Macedonia (the former Yugoslav Republic of)' },
  { value: 'MG', title: 'Madagascar' },
  { value: 'MW', title: 'Malawi' },
  { value: 'MY', title: 'Malaysia' },
  { value: 'MV', title: 'Maldives' },
  { value: 'ML', title: 'Mali' },
  { value: 'MT', title: 'Malta' },
  { value: 'MH', title: 'Marshall Islands' },
  { value: 'MQ', title: 'Martinique' },
  { value: 'MR', title: 'Mauritania' },
  { value: 'MU', title: 'Mauritius' },
  { value: 'YT', title: 'Mayotte' },
  { value: 'MX', title: 'Mexico' },
  { value: 'FM', title: 'Micronesia (Federated States of)' },
  { value: 'MD', title: 'Moldova (Republic of)' },
  { value: 'MC', title: 'Monaco' },
  { value: 'MN', title: 'Mongolia' },
  { value: 'ME', title: 'Montenegro' },
  { value: 'MS', title: 'Montserrat' },
  { value: 'MA', title: 'Morocco' },
  { value: 'MZ', title: 'Mozambique' },
  { value: 'MM', title: 'Myanmar' },
  { value: 'NA', title: 'Namibia' },
  { value: 'NR', title: 'Nauru' },
  { value: 'NP', title: 'Nepal' },
  { value: 'NL', title: 'Netherlands' },
  { value: 'NC', title: 'New Caledonia' },
  { value: 'NZ', title: 'New Zealand' },
  { value: 'NI', title: 'Nicaragua' },
  { value: 'NE', title: 'Niger' },
  { value: 'NG', title: 'Nigeria' },
  { value: 'NU', title: 'Niue' },
  { value: 'NF', title: 'Norfolk Island' },
  { value: 'MP', title: 'Northern Mariana Islands' },
  { value: 'NO', title: 'Norway' },
  { value: 'OM', title: 'Oman' },
  { value: 'PK', title: 'Pakistan' },
  { value: 'PW', title: 'Palau' },
  { value: 'PS', title: 'Palestine, State of' },
  { value: 'PA', title: 'Panama' },
  { value: 'PG', title: 'Papua New Guinea' },
  { value: 'PY', title: 'Paraguay' },
  { value: 'PE', title: 'Peru' },
  { value: 'PH', title: 'Philippines' },
  { value: 'PN', title: 'Pitcairn' },
  { value: 'PL', title: 'Poland' },
  { value: 'PT', title: 'Portugal' },
  { value: 'PR', title: 'Puerto Rico' },
  { value: 'QA', title: 'Qatar' },
  { value: 'RE', title: 'Réunion' },
  { value: 'RO', title: 'Romania' },
  { value: 'RU', title: 'Russian Federation' },
  { value: 'RW', title: 'Rwanda' },
  { value: 'BL', title: 'Saint Barthélemy' },
  { value: 'SH', title: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KN', title: 'Saint Kitts and Nevis' },
  { value: 'LC', title: 'Saint Lucia' },
  { value: 'MF', title: 'Saint Martin (French part)' },
  { value: 'PM', title: 'Saint Pierre and Miquelon' },
  { value: 'VC', title: 'Saint Vincent and the Grenadines' },
  { value: 'WS', title: 'Samoa' },
  { value: 'SM', title: 'San Marino' },
  { value: 'ST', title: 'Sao Tome and Principe' },
  { value: 'SA', title: 'Saudi Arabia' },
  { value: 'SN', title: 'Senegal' },
  { value: 'RS', title: 'Serbia' },
  { value: 'SC', title: 'Seychelles' },
  { value: 'SL', title: 'Sierra Leone' },
  { value: 'SG', title: 'Singapore' },
  { value: 'SK', title: 'Slovakia' },
  { value: 'SI', title: 'Slovenia' },
  { value: 'SB', title: 'Solomon Islands' },
  { value: 'SO', title: 'Somalia' },
  { value: 'ZA', title: 'South Africa' },
  { value: 'GS', title: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', title: 'South Sudan' },
  { value: 'ES', title: 'Spain' },
  { value: 'LK', title: 'Sri Lanka' },
  { value: 'SD', title: 'Sudan' },
  { value: 'SR', title: 'Suriname' },
  { value: 'SJ', title: 'Svalbard and Jan Mayen' },
  { value: 'SZ', title: 'Swaziland' },
  { value: 'SE', title: 'Sweden' },
  { value: 'CH', title: 'Switzerland' },
  { value: 'SY', title: 'Syrian Arab Republic' },
  { value: 'TW', title: 'Taiwan, Province of China[a]' },
  { value: 'TJ', title: 'Tajikistan' },
  { value: 'TZ', title: 'Tanzania, United Republic of' },
  { value: 'TH', title: 'Thailand' },
  { value: 'TL', title: 'Timor-Leste' },
  { value: 'TG', title: 'Togo' },
  { value: 'TK', title: 'Tokelau' },
  { value: 'TO', title: 'Tonga' },
  { value: 'TT', title: 'Trinidad and Tobago' },
  { value: 'TN', title: 'Tunisia' },
  { value: 'TR', title: 'Turkey' },
  { value: 'TM', title: 'Turkmenistan' },
  { value: 'TC', title: 'Turks and Caicos Islands' },
  { value: 'TV', title: 'Tuvalu' },
  { value: 'UG', title: 'Uganda' },
  { value: 'UA', title: 'Ukraine' },
  { value: 'AE', title: 'United Arab Emirates' },
  { value: 'GB', title: 'United Kingdom of Great Britain and Northern Ireland' },
  { value: 'UM', title: 'United States Minor Outlying Islands' },
  { value: 'US', title: 'United States of America' },
  { value: 'UY', title: 'Uruguay' },
  { value: 'UZ', title: 'Uzbekistan' },
  { value: 'VU', title: 'Vanuatu' },
  { value: 'VE', title: 'Venezuela (Bolivarian Republic of)' },
  { value: 'VN', title: 'Viet Nam' },
  { value: 'VG', title: 'Virgin Islands (British)' },
  { value: 'VI', title: 'Virgin Islands (U.S.)' },
  { value: 'WF', title: 'Wallis and Futuna' },
  { value: 'EH', title: 'Western Sahara' },
  { value: 'YE', title: 'Yemen' },
  { value: 'ZM', title: 'Zambia' },
  { value: 'ZW', title: 'Zimbabwe' }
];
export const FACEBOOK_APP_ID = '277787417911162';
export const API_BASE_URL = 'https://d14szcjletu2tu.cloudfront.net';
export const CHAT_WEBSOCKET_URL = 'wss://w773115zd5.execute-api.eu-west-1.amazonaws.com/test';
export const APPSFLYER_DEV_KEY = 'NaW7GfKBLrJ6uJ3t5hX9ET';
export const VENDURE_SHOP_API_URL = "https://vendure.ichdich.eu/shop-api";
export const APPSFLYER_DEV_MODE = true;
export const APPENV_URL = "https://ichdich-app-store.s3.eu-west-1.amazonaws.com/appenv.json"
export const MABBOX_ACCESS_TOKEN = "pk.eyJ1IjoiZXJ2aW5lZHoiLCJhIjoiY2xoZ2lveHQ1MTJtZDNrbzQ0YzFldW0xdiJ9.XyFP3QnZuQAJUGR6ozQfDQ"
export const STRIPE_PUBLIC_KEY = "pk_live_51MpsZrEkknMHBXFLfGSFtuUj1aE3M5qpeDpInQuiKcoWmZr1jVgCPcY8mf2XFmiaw53uU9DZvmqGMSEQYnYSYzip00xR6x0iUP"
export const RESPONSIVENESS_PARAMETER = {
  DESKTOP:"desktop",
  MOBILE:"mobile"
}