import axios from "axios"

const { VENDURE_SHOP_API_URL, API_BASE_URL } = require('../../constants');
const { getUserAccessKey, getDeviceId } = require('../store/global');



async function getStoresInCountry(countryCode) {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://api.ichdich.eu/vendure/v1/sellers/${countryCode}`,
        headers: {}
    };

    console.log(config);
    try {
        const response = await axios.request(config);
        return response.data.items;
    } catch (error) {
        console.error(error, "getStoresInCountry");
        return null;
    }
}

async function authenticateUser(userAccesKey, deviceId) {
    const query = `
      mutation Authenticate($userId: UserIdAuthInput!) {
        authenticate(
          input: {
            userId: $userId,
          },
          rememberMe: true
        ) {
          ... on CurrentUser {
            id
            identifier
            channels {
              id
              token
              code
              permissions
            }
          }
          ... on InvalidCredentialsError {
            errorCode
            message
            authenticationError
          }
        }
      }
    `;

    const variables = {
        userId: {
            key: userAccesKey,
            deviceId: deviceId,
        },
    };

    try {
        const response = await axios.post(
            VENDURE_SHOP_API_URL,
            { query, variables },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            }
        );
        console.log(response.headers);
    } catch (error) {
        console.error(error, variables, VENDURE_SHOP_API_URL, 'authenticateUser');
    }
}
async function getProducts(vendureToken) {
    const query = `
      query Products {
        products {
            totalItems
            items {
                id
                createdAt
                updatedAt
                languageCode
                name
                slug
                description
                customFields
                featuredAsset {
                    id
                    createdAt
                    updatedAt
                    name
                    type
                    fileSize
                    mimeType
                    width
                    height
                    source
                    preview
                    customFields
                }
                assets {
                    id
                    createdAt
                    updatedAt
                    name
                    type
                    fileSize
                    mimeType
                    width
                    height
                    source
                    preview
                    customFields
                }
                collections {
                    id
                    createdAt
                    updatedAt
                    languageCode
                    name
                    slug
                    position
                    description
                    customFields
                    featuredAsset {
                        id
                        createdAt
                        updatedAt
                        name
                        type
                        fileSize
                        mimeType
                        width
                        height
                        source
                        preview
                        customFields
                    }
                }
                variantList {
                    totalItems
                    items {
                        id
                        productId
                        createdAt
                        updatedAt
                        languageCode
                        sku
                        name
                        price
                        currencyCode
                        priceWithTax
                        stockLevel
                        customFields
                    }
                }
            }
        }
      }
    `;

    const headers = {
        'Content-Type': 'application/json',
        'vendure-token': vendureToken
    };

    return (await axios.post(VENDURE_SHOP_API_URL, { query }, {
        headers,
        withCredentials: true
    })).data.data.products.items;

}

async function createStripePaymentIntent(vendureToken) {
    const createStripePaymentIntentMutation = `
    mutation CreateStripePaymentIntent {
        createStripePaymentIntent 
    }
    
  `;
    const headers = {
        'Content-Type': 'application/json',
        'vendure-token': vendureToken,
    };

    try {
        console.log("RSPS");
        const response = (await axios.post(VENDURE_SHOP_API_URL, {
            query: createStripePaymentIntentMutation,
        }, {
            headers,
            withCredentials: true
        }))
        console.log(response.data);
        return JSON.parse(response.data.data.createStripePaymentIntent);
    } catch (error) {
        return null;
        console.log(error);
    }
}

async function createNewOrder() {
    const query = `
      mutation CreateNewOrder {
        createNewOrder {
          id
        }
      }
    `;

    try {
        const response = await axios.post(VENDURE_SHOP_API_URL, { query }, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        });
        return response.data.data.createNewOrder.id;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to create new order');
    }
}

async function removeCurrentActiveOrder() {
    const query = `
      mutation SetOrderCustomFields {
        setOrderCustomFields(input: {customFields: {activated: false}}) {
          ... on Order {
            id
            customFields {
                activated
            }
          }
        }
      }
    `;

    return axios.post(VENDURE_SHOP_API_URL, { query }, {
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true
    })
}
async function prepareOrderRequest(vendureToken, orderNumber) {
    const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/payment/v1/prepare-order/${vendureToken}/${orderNumber}`,
        headers: {
            'x-key': await getUserAccessKey(),
            'x-device-id': await getDeviceId(),
        },
        withCredentials: true
    };

    try {
        await axios.request(config)
        console.log("AFTER PREPARE ORDER");
        return true
    } catch (error) {
        console.log(error);
        return false
    }
}

async function getProduct(id, vendureToken) {
    console.log(id, vendureToken);
    const query = `
    query Product {
        product(id: ${id}) {
            id
            createdAt
            updatedAt
            languageCode
            name
            slug
            description
            customFields
            variants {
                id
                productId
                createdAt
                updatedAt
                languageCode
                sku
                name
                price
                currencyCode
                priceWithTax
                stockLevel
                customFields
            }
            assets {
                id
                createdAt
                updatedAt
                name
                type
                fileSize
                mimeType
                width
                height
                source
                preview
                customFields
            }
            featuredAsset {
                id
                createdAt
                updatedAt
                name
                type
                fileSize
                mimeType
                width
                height
                source
                preview
                customFields
            }
            variantList {
                totalItems
                items {
                    id
                    productId
                    createdAt
                    updatedAt
                    languageCode
                    sku
                    name
                    price
                    currencyCode
                    priceWithTax
                    stockLevel
                    customFields
                }
            }
        }
    }
    
    `;

    const headers = {
        'Content-Type': 'application/json',
        'vendure-token': vendureToken,
    };

    return (await axios.post(VENDURE_SHOP_API_URL, { query }, {
        headers,
        withCredentials: true
    })).data.data.product;

}
async function getStoreViaChannelId(vendureToken) {
    console.log(vendureToken);
    const query = `
    query ActiveChannel {
        activeChannel {
            id
            createdAt
            updatedAt
            code
            token
            defaultLanguageCode
            currencyCode
            defaultCurrencyCode
            pricesIncludeTax
            seller {
                id
                createdAt
                updatedAt
                name
                customFields
            }
            customFields {
                channelName
                channelDescription
                operatingCountries
                channelHeroImage {
                    id
                    createdAt
                    updatedAt
                    name
                    type
                    fileSize
                    mimeType
                    width
                    height
                    source
                    preview
                    customFields
                }
            }
        }
    }
    
    `;

    const headers = {
        'Content-Type': 'application/json',
        'vendure-token': vendureToken,
    };

    return (await axios.post(VENDURE_SHOP_API_URL, { query }, {
        headers,
        withCredentials: true
    })).data.data.activeChannel;
}

async function fetchActiveChannelData(vendureToken) {
    const query = `
      query ActiveChannel {
        activeChannel {
          id
          createdAt
          updatedAt
          code
          token
          defaultLanguageCode
          currencyCode
          defaultCurrencyCode
          pricesIncludeTax
          customFields {
            channelName
            channelDescription
            operatingCountries
            channelHeroImage {
              id
              createdAt
              updatedAt
              name
              type
              fileSize
              mimeType
              width
              height
              source
              preview
              customFields
            }
          }
        }
      }
    `;

    const variables = {};
    const headers = {
        'Content-Type': 'application/json',
        'vendure-token': vendureToken,
    };

    return (await axios.post(VENDURE_SHOP_API_URL, { query }, {
        headers,
        withCredentials: true
    })).data.data.activeChannel;
}

async function doesActiveOrderExists(vendureToken) {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'vendure-token': vendureToken,
        };

        const response = await axios.post(VENDURE_SHOP_API_URL, {
            query: `
          query ActiveOrder {
            activeOrder {
              id
            }
          }
        `
        }, {
            headers,
            withCredentials: true
        });

        const activeOrder = response.data.data.activeOrder;
        return Boolean(activeOrder);
    } catch (error) {
        console.error(error);
        return false;
    }
}

async function attachPromtToOrder(promptId, vendureToken) {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'vendure-token': vendureToken,
        };

        const response = await axios.post(VENDURE_SHOP_API_URL, {
            query: `
                mutation SetOrderCustomFields($promptId: String!) {
                    setOrderCustomFields(
                        input: {customFields: {promptId: $promptId}}
                    ) {
                        ... on Order {
                            id
                        }
                        ... on NoActiveOrderError {
                            errorCode
                            message
                        }

                    }
                }
            `,
            variables: { promptId }
        },
            {
                headers,
                withCredentials: true,
            });

        return response.data.data.setOrderCustomFields;
    } catch (error) {
        console.log(error);
        console.error(JSON.stringify(error, null, 2));
    }
}

async function addItemToOrder(quantity, productVariantId, vendureToken) {
    console.log(quantity, productVariantId, vendureToken);
    try {
        const headers = {
            'Content-Type': 'application/json',
            'vendure-token': vendureToken,
        };
        const response = await axios.post(VENDURE_SHOP_API_URL, {
            query: `
            mutation AddItemToOrder($quantity: Int!, $productVariantId: ID!) {
                addItemToOrder(quantity: $quantity, productVariantId: $productVariantId) {
                ... on Order {
                    id
                    code
                }
                }
            }
        `,
            variables: { quantity, productVariantId }
        }, {
            headers,
            withCredentials: true
        });
        return response.data.data.addItemToOrder;
    } catch (error) {
        console.log(error);
        return false;
    }
}

async function removeAllOrderLines(orderId, vendureToken) {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'vendure-token': vendureToken,
        };


        const response = await axios.post(VENDURE_SHOP_API_URL, {
            query: `
                mutation RemoveAllOrderLines {
                    removeAllOrderLines {
                        ... on Order {
                            id
                        }
                    }
                }
            `,
        }, {
            headers,
            withCredentials: true
        });
        return true
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const vendureAPI = {
    getStoresInCountry,
    authenticateUser,
    getProducts,
    fetchActiveChannelData,
    getProduct,
    addItemToOrder,
    attachPromtToOrder,
    prepareOrderRequest,
    createStripePaymentIntent,
    createNewOrder,
    removeCurrentActiveOrder,
    getStoreViaChannelId
}
