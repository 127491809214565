import axios from 'axios';
import { getDeviceId, getUserAccessKey, setUserAccessKey } from '../store/global';
import { API_BASE_URL } from '../../constants';

const AUTH_API_BASE = "/auth/v1"

const loginUserViaFacebook = async (token) => {
  try {
    const data = JSON.stringify({ clientSecret: token });

    const config = {
      method: 'post',
      url: API_BASE_URL + AUTH_API_BASE + '/auth/facebook',
      headers: {
        'x-device-id': await getDeviceId(),
        'Content-Type': 'application/json',
      },
      data,
    };


    const response = await axios(config);

    await setUserAccessKey(response.data.data.key)

    return {
      succes: true,
      data: {
        ...response.data
      }
    }
  } catch (error) {
    console.log(error, "loginUserViaFacebook");
  }
}

const loginUser = async (phone, password) => {
  try {
    const data = JSON.stringify({ phone, password });

    const config = {
      method: 'post',
      url: API_BASE_URL + AUTH_API_BASE + '/auth/phone',
      headers: {
        'x-device-id': await getDeviceId(),
        'content-Type': 'application/json',
      },
      data,
    };

    console.log(config);
    const response = await axios(config);
    await setUserAccessKey(response.data.data.key);

    return {
      success: true,
      data: {
        ...response.data.data
      }
    };
  } catch (error) {
    console.error(JSON.stringify(error, null, 2), "loginUser");
    return {
      success: false,
    };
  }
};

async function setPushToken(token, platform) {
  var myHeaders = new Headers();
  myHeaders.append("x-device-id", await getDeviceId());
  myHeaders.append("x-key", await getUserAccessKey());
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    token,
    "tokenPlatform": platform
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(API_BASE_URL + AUTH_API_BASE + "/push/token", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.error('r', error));
}

const checkAuth = async (key) => {
  try {

    const config = {
      method: 'get',
      url: API_BASE_URL + AUTH_API_BASE + '/auth',
      headers: {
        'x-device-id': await getDeviceId(),
        'x-key': key,
      }
    };

    console.log(config);
    const response = await axios(config);
    console.log(response);
    return {
      success: true,
      data: response.data.data
    };
  } catch (error) {
    console.log(error,"checkAuth");
    return {
      success: false,
    };
  }
};

const recoveryStepOneGetCode = async (phone) => {
  const headers = {
    'x-device-id': await getDeviceId(),
    'Content-Type': 'application/json'
  };
  const data = {
    phone
  };
  const requestOptions = {
    method: 'post',
    url: API_BASE_URL + AUTH_API_BASE + '/recovery/password/reset/via-sms/begin-two-fa',
    headers,
    data,
    redirect: 'follow'
  };
  try {
    const res = await axios(requestOptions);
    const dat = res.data;
    return {
      twoFactorTransactionId: dat.data.twoFactorTransactionId
    };
  } catch (error) {
    console.log(error, "recoveryStepOneGetCode");
    return null;
  }
}

const twoFactorSMSVeriy = async (twoFactorTransactionId, code) => {
  const data = {
    twoFactorTransactionId,
    code: code.toString(),
  };

  const headers = {
    'x-device-id': await getDeviceId(),
    'Content-Type': 'application/json',
  };

  try {
    const response = await axios.post(
      API_BASE_URL + AUTH_API_BASE + '/two-factor/sms/step-two-verify',
      data,
      { headers }
    );

    const responseData = response.data;
    console.log(responseData);
    return {
      twoFactorTransactionId: responseData.data.twoFactorTransactionId,
      twoFactorTransactionCode: responseData.data.twoFactorTransactionCode,
    };
  } catch (error) {
    console.error(error, "twoFactorSMSVeriy");
    return null;
  }
};

const recoverySetPassword = async (twoFactorTransactionId, twoFactorTransactionCode, newPassword) => {
  var data = JSON.stringify({
    twoFactorTransactionId,
    twoFactorTransactionCode,
    newPassword
  });

  var config = {
    method: 'post',
    url: API_BASE_URL + AUTH_API_BASE + '/recovery/password/reset/sms-two-fa-verify-and-reset',
    headers: {
      'x-device-id': await getDeviceId(),
      'content-Type': 'application/json'
    },
    data: data
  };

  console.log(config);
  try {
    const response = await axios(config)

    setUserAccessKey(response.data.data.key)
    return {
      key: response.data.data.key
    }

  } catch (error) {
    console.log(error);
    return null
  }

}

const doLogout = async (key, deviceId) => {
  var config = {
    method: 'post',
    url: API_BASE_URL + AUTH_API_BASE + '/auth/logout',
    headers: {
      'x-device-id': deviceId,
      'x-key': key
    }
  };
  await axios(config)
  return
}

const authAPI = {
  loginUser,
  checkAuth,
  twoFactorSMSVeriy,
  recoverySetPassword,
  doLogout,
  recoveryStepOneGetCode,
  authChat: () => { }, //Deprecated
  setPushToken,
  loginUserViaFacebook
};

export default authAPI