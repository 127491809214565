import React, { useEffect, useContext, createContext } from 'react';
import { useRecoilState } from 'recoil';

// import your language data files as JSON here
import en from '../en.json';
import zh from '../zh.json';
import de from '../de.json';
import es from '../es.json';
import it from '../it.json';
import fr from '../fr.json';
import hu from '../hu.json';
import ru from '../ru.json';
import tr from '../tr.json';
import ja from '../ja.json'
import { appAtoms } from '../../logic/store/store';


// define a function to get language data from the JSON files
const getLanguageData = (code) => {
    switch (code) {
        case 'en':
            return en;
        case 'zh':
            return zh;
        case 'de':
            return de;
        case 'es':
            return es;
        case 'it':
            return it;
        case 'fr':
            return fr;
        case 'hu':
            return hu;
        case 'ru':
            return ru;
        case 'tr':
            return tr;
        case "ja":
            return ja;
        default:
            return en;
    }
};

// define a new context for the language data
const LanguageContext = createContext(null);

const LanguageRoot = ({ children }) => {
    // get the current language code from Recoil state
    const [languageCode, setLanguageCode] = useRecoilState(appAtoms.langAtom);

    // add an effect to load language data files into memory when the component mounts
    useEffect(() => {
        getLanguageData('en');
        getLanguageData('zh');
        getLanguageData('de');
        getLanguageData('ar');
        // ... add more calls to getLanguageData() for other language codes ...
    }, []);

    // return the child components wrapped in a context provider
    return (
        <LanguageContext.Provider value={languageCode}>
            {children}
        </LanguageContext.Provider>
    );
};

// define a function to get the translation for a given path
const useLanguage = (path) => {
    const languageCode = useContext(LanguageContext);
    const languageData = getLanguageData(languageCode);
    const parts = path.split('.');
    let value = languageData;
    for (let i = 0; i < parts.length; i++) {
        value = value[parts[i]];
    }
    return value;
};

export { LanguageRoot, useLanguage };
