import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import axios from 'axios';

const WebLottie = ({ url, children,lottieStyle }) => {
    const [animationData, setAnimationData] = useState(null);

    useEffect(() => {
        const fetchAnimationData = async () => {
            try {
                const response = await axios.get(url);
                setAnimationData(response.data);
            } catch (error) {
                console.error("Error fetching animation data: ", error);
            }
        };

        fetchAnimationData();
    }, [url]);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            {animationData && <Lottie style={lottieStyle} isClickToPauseDisabled options={defaultOptions} />}
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                {children}
            </div>
        </div>
    );
};

export default WebLottie;
