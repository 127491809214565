import React, { useEffect } from 'react'
import SideNav from '../../components/loggedIn/navigation/sideNav'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import userAPI from '../../logic/services/user'
import * as AntdIcons from '@ant-design/icons';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useRecoilState } from 'recoil';
import { appAtoms } from '../../logic/store/store';
import { RESPONSIVENESS_PARAMETER } from '../../constants';
import TopNavBar from '../../components/loggedIn/navigation/topNav';
import { useLanguage } from '../../lang/utils/utils';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export default function Profile() {
    const [searchParams] = useSearchParams();
    const [userProfile, setUserProfile] = React.useState({})
    const [isLightBoxOpen, setIsLightBoxOpen] = React.useState(false)
    const [photoIndex, setPhotoIndex] = React.useState(0)
    const [responsiveness] = useRecoilState(appAtoms.responsiveNessSwitchAtom)

    const pageLange = useLanguage("screens.profile")

    const userId = searchParams.get('userId');

    const navigation = useNavigate();

    const openLigthBox = (index) => {
        setPhotoIndex(index)
        setIsLightBoxOpen(true)
    }

    let userImages = userProfile?.otherImages && Object.keys(userProfile?.otherImages).map((imageKey) => `https://ichdich-images-prod.s3.eu-west-1.amazonaws.com/${userProfile?.otherImages[imageKey]?.s3Key}`)


    useEffect(() => {
        if (!userId) {
            navigation('/')
        } else {
            userAPI.getUserProfile(userId).then(res => {
                if (res) {
                    setUserProfile(res)
                }
            })
        }

    }, [userId])

    if (responsiveness == RESPONSIVENESS_PARAMETER.DESKTOP) {
        return (
            <>
                <Lightbox
                    open={isLightBoxOpen}
                    close={() => setIsLightBoxOpen(false)}
                    slides={userImages?.map(x => { return { src: x } })}
                />
                <div className='h-screen w-screen'>
                    <div className='flex-1 flex flex-row h-full'>
                        <div className='h-full w-24'>
                            <SideNav />
                        </div>
                        <div className='w-full h-min m-2 rounded-3xl my-6 p-5 flex flex-col space-y-2'>
                            <div className='bg-red-50 rounded-3xl flex p-4 flex-1  flex-row space-x-3 justify-start items-center max-w-7xl mx-auto w-full'>
                                <Link to={"/"} className='text-xs flex  items-baseline'>
                                    <AntdIcons.ArrowLeftOutlined
                                        className='text-red-500 hover:text-red-300 cursor-pointer text-lg'
                                    />
                                </Link>
                                <div
                                    className='w-8 h-8 rounded-full bg-cover bg-center'
                                    style={{
                                        backgroundImage: `url(${userImages?.[0] ? userImages[0] : null})`,
                                    }}
                                />
                                <h5 className='text-gray-500 font-bold'>
                                    {userProfile?.nickName}
                                </h5>
                            </div>
                            <div className='w-full flex flex-col space-y-2 max-w-7xl mx-auto'>
                                <div className='flex-1 flex-row flex'>
                                    {userImages?.slice(0, 3).map((image, index) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    openLigthBox(index)
                                                }}
                                                className='
                                                    w-1/4 aspect-square rounded-3xl bg-cover bg-center mr-2 mb-2
                                                    cursor-pointer transition-all duration-300 hover:opacity-90
                                                '
                                                style={{
                                                    backgroundImage: `url(${image})`,
                                                }}
                                            />
                                        )
                                    })}
                                    {userImages?.length > 3 && <div
                                        onClick={() => {
                                            openLigthBox(3)
                                        }}
                                        className='
                                    w-1/4 aspect-square rounded-3xl bg-cover bg-center mr-2 mb-2 bg-gray-200 flex justify-center items-center flex-col
                                    cursor-pointer hover:bg-gray-300 transition-all duration-300
                                    '>
                                        <AntdIcons.PlusOutlined className='text-red-400 text-4xl' />
                                        <p

                                            className='text-red-400  font-bold'
                                        >+{
                                                userImages.length - 3
                                            } {pageLange.images}</p>
                                    </div>}
                                </div>
                                <div className='space-y-2'>
                                    <div>
                                        <h3 className='text-2xl font-bold text-gray-800'>{pageLange.about}</h3>
                                    </div>
                                    <div>
                                        <h3 className='text-xl font-bold text-gray-600'>{pageLange.bio}</h3>
                                        <p>
                                            {userProfile?.bio || pageLange.no_biography}
                                        </p>
                                    </div>
                                    <div>
                                        <h3 className='text-xl font-bold text-gray-600'>{pageLange.interests}</h3>
                                        {userProfile?.interests ? userProfile?.interests?.map((interest) => {
                                            return <div>
                                                <p>{interest}</p>
                                            </div>
                                        }) : pageLange.no_interests}
                                    </div>


                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <Lightbox
                    open={isLightBoxOpen}
                    close={() => setIsLightBoxOpen(false)}
                    slides={userImages?.map(x => { return { src: x } })}
                />
                <div className='h-screen w-screen'>
                    <div className='flex-1 flex flex-col h-full'>
                        <TopNavBar
                            title={pageLange.title}
                            hasBack
                            hasSupport

                        />

                        <div className='w-full flex flex-col space-y-2 max-w-7xl mx-auto p-8'>
                            <div className='flex-1 flex-row flex'>
                                {userImages?.slice(0, 3).map((image, index) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                openLigthBox(index)
                                            }}
                                            className='
                                                    w-1/4 aspect-square rounded-xl bg-cover bg-center mr-2 mb-2
                                                    cursor-pointer transition-all duration-300 hover:opacity-90
                                                '
                                            style={{
                                                backgroundImage: `url(${image})`,
                                            }}
                                        />
                                    )
                                })}
                                {userImages?.length > 3 && <div
                                    onClick={() => {
                                        openLigthBox(3)
                                    }}
                                    className='
                                    w-1/4 aspect-square rounded-xl bg-cover bg-center mr-2 mb-2 bg-gray-200 flex justify-center items-center flex-col
                                    cursor-pointer hover:bg-gray-300 transition-all duration-300
                                    '>
                                    <AntdIcons.PlusOutlined className='text-red-400 text-xl' />
                                    <p

                                        className='text-red-400 text-xs  font-bold'
                                    >+{
                                            userImages.length - 3
                                        } {pageLange.images}</p>
                                </div>}
                            </div>
                            <div className='space-y-2'>
                                <div>
                                    <h3 className='text-2xl font-bold text-gray-800'>{pageLange.about}</h3>
                                </div>
                                <div>
                                    <h3 className='text-xl font-bold text-gray-600'>{pageLange.bio}</h3>
                                    <p>
                                        {userProfile?.bio || pageLange.no_biography}
                                    </p>
                                </div>
                                <div>
                                    <h3 className='text-xl font-bold text-gray-600'>{pageLange.interests}</h3>
                                    {userProfile?.interests ? userProfile?.interests.map((interest) => {
                                        return <div>
                                            <p>{interest}</p>
                                        </div>
                                    }) : pageLange.no_interests}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}
