import React, { useState } from 'react'
import { GENDERS } from '../../../../constants'
import { Select } from 'antd'

export default function GenderSCSelect({
    defaultValue,
    placeholder,
    onChange,
    inputMode,
    label,
    mode,
}) {
    const [value, setValue] = useState(defaultValue ?? [])

    return (
        <div className='flex-1'>
            <p className='text-xs font-semibold text-gray-500 mb-1 ml-0.5 '>
                {label}
            </p>
            <Select
                mode={mode}
                allowClear
                style={{ width: '100%' }}
                placeholder={placeholder}
                defaultValue={value}
                onChange={(value) => {
                    setValue(value)
                    onChange(value)
                }}
                options={GENDERS}
            />
        </div>
    )
}



