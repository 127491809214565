import { Conversation, ConversationList } from '@chatscope/chat-ui-kit-react'
import React from 'react'
import { useLanguage } from '../../../lang/utils/utils'
import { Avatar } from 'antd'
import { useRecoilState } from 'recoil'
import { userAtoms } from '../../../logic/store/store'

export default function Converstaions({
    channels,
    setSelectedChannel,
    selectedChannel,
    onChannel
}) {
    const screenLang = useLanguage("screens.messages")
    const [selfUserId, setSelfuserId] = useRecoilState(userAtoms.userIdAtom)


    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str
        }
        return str.slice(0, num) + '...'
    }
    const formatLastMessage = (lastMessage, otherUserData) => {
        if (lastMessage == null) {
            return {
                messageContent: screenLang.no_messages_yet
            };
        }
        let messageContent
        const { messageType } = lastMessage
        console.log(JSON.stringify(lastMessage, null, 2));

        switch (messageType) {
            case 0:
                try {
                    messageContent = truncateString(String(lastMessage?.content?.plainText)?.replaceAll("\n", " "), 20);
                } catch (error) {
                    messageContent = lastMessage?.content?.plainText
                }
                break;
            case 1:
                messageContent = screenLang.image;
                break;
            case 2:
                messageContent = screenLang.video;
                break;
            case 3:
                messageContent = screenLang.new_request;
            default:
                break;
        }
        const sender = lastMessage.fromUserId === selfUserId ? screenLang.you : otherUserData.nickName;


        return {
            sender,
            messageContent
        }
    };
    return (
        <ConversationList 
        style={{
            border:0,
            boxShadow:0,
            WebkitBoxShadow:0,
            textShadow:0
        }}
        >
            {channels.map(channel => {
                const lastMessage = formatLastMessage(channel.lastMessage, channel.otherUserData)
                return (
                    <Conversation
                        onClick={(e) => {
                            if (typeof onChannel == 'function') {
                                onChannel()
                            }
                        }}
                        name={channel.otherUserData.nickName} lastSenderName={
                            lastMessage.sender
                        } info={
                            lastMessage.messageContent
                        }>

                    </Conversation>
                )
            })}
        </ConversationList>
    )
}
