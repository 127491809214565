import React, { useEffect, useState } from 'react'
import { vendureAPI } from '../../../logic/services/vendure'
import userAPI from '../../../logic/services/user'
import { useRecoilState } from 'recoil'
import { userAtoms } from '../../../logic/store/store'
import { removeHtmlTags } from '../../../logic/helpers/html'
import { Button, Modal, Skeleton } from 'antd'
import { Loader } from '@chatscope/chat-ui-kit-react'
import { REGISTRATION_CHECKPOINT, STRIPE_PUBLIC_KEY } from '../../../constants'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from '../stripe/chechkout'
import { getDeviceId, getUserAccessKey } from '../../../logic/store/global'
import { extractImageData } from '../../../logic/helpers/imageData'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../../../lang/utils/utils'
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export default function Prompt({
    message,
    otherUserData,
    otherUserStores,
    timedDelay = 0
}) {
    const [store, setStore] = useState(null)
    const [product, setProduct] = useState(null)
    const [selfUserId, setSelfuserId] = useRecoilState(userAtoms.userIdAtom)
    const [prompt, setPrompt] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const componentLang = useLanguage("components.promt_chat")

    const isMine = message.fromUserId === selfUserId

    const getProductWithRetry = async (
        productId,
        sellerVendureToken,
        delay = timedDelay,
        maxRetries = 5
    ) => {
        for (let i = 0; i <= maxRetries; i++) {
            try {
                const res = await vendureAPI.getProduct(productId, sellerVendureToken);
                if (res) {
                    setProduct(res);
                    return res; // If the request is successful, return the response
                }
            } catch (error) {
                console.error(`Attempt ${i} failed, retrying in ${delay / 1000}s...`);
                // If the request fails, wait for a delay then retry
                await new Promise(resolve => setTimeout(resolve, delay));
                // Exponential backoff: double the delay for the next attempt
                delay *= 2;
            }
        }
        // After maxRetries, throw an exception
        throw new Error('Failed to get product after maximum retries');
    };
    const getData = async () => {
        await userAPI.getPrompt(message.content.globalPromptId.split("#")[1]).then(res => {
            if (res) {
                setPrompt(res.data)
            }
        })
    }

    const sendPromptUpdate = async () => {

    }
    React.useEffect(() => {
        setTimeout(async () => {
            await getData()
            await getProductWithRetry(
                message.content.content.productId,
                message.content.content.sellerVendureToken
            )
                .then(product => console.log('Product retrieved successfully:', product))
                .catch(err => console.error('Failed to retrieve product:', err));
            setIsLoading(false)
        }, timedDelay)
    }, []);
    const [isLoadingPayment, setisLoadingPayment] = useState(false)
    const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false)
    const [paymentOptions, setPaymentOptions] = useState(null)

    if (isLoading) {
        return (
            <div className='p-5 bg-gray-100 rounded-lg flex justify-center items-center flex-col space-y-2'>
                <Loader />
            </div>
        )
    }


    const onBuy = async () => {
        setisLoadingPayment(true)
        setIsPaymentModalVisible(true)
        await vendureAPI.authenticateUser(
            await getUserAccessKey(),
            await getDeviceId()
        )
        vendureAPI.removeCurrentActiveOrder().then(async x => {
            console.log(x.data.data);
            const order = await vendureAPI.addItemToOrder(1, prompt.content.variantId, prompt.content.sellerVendureToken)
            console.log(order, "order");
            console.log(message, "message");
            const { id } = await vendureAPI.attachPromtToOrder(
                message.content.globalPromptId.split("#")[1],
                prompt.content.sellerVendureToken,
            )
            console.log(id, "id");
            vendureAPI.prepareOrderRequest(
                prompt.content.sellerVendureToken,
                id
            ).then(res => {
                console.log(res, "res");
                if (res) {
                    vendureAPI.createStripePaymentIntent(prompt.content.sellerVendureToken).then(async stripeRes => {
                        console.log("PPS", {
                            clientSecret: stripeRes.client_secret,
                            customerId: stripeRes.customerId,
                            ephemeralKey: stripeRes.empherialKey,
                        });
                        if (stripeRes) {
                            setPaymentOptions({
                                clientSecret: stripeRes.client_secret,
                                customerId: stripeRes.customerId,
                                ephemeralKey: stripeRes.empherialKey,
                            })
                        }
                        setTimeout(() => {
                            setisLoadingPayment(false)
                            sendPromptUpdate()
                        }, 2000);
                        const promtId = message?.contentRaw?.globalPromptId
                        if (!promtId) return
                        getData()

                    })
                }
                setisLoadingPayment(false)
            }).catch(err => {
                console.log(err);
                setisLoadingPayment(false)
            })
        }).catch(err => {
            console.log(err);
            setisLoadingPayment(false)
            setIsPaymentModalVisible(false)
        })
    }


    const acceptGift = async () => {
        const checkPoints = await userAPI.getCheckpoints()

        if (checkPoints.success == false) return

        const isFullnameMissing = checkPoints?.missingCheckpoints?.includes(REGISTRATION_CHECKPOINT.FULL_NAME)
        const isGiftAddressMissing = checkPoints?.missingCheckpoints?.includes(REGISTRATION_CHECKPOINT.GIFT_ADDRESS)

        if (isFullnameMissing || isGiftAddressMissing) {
            return navigate("/set-gift-address")
        }

        userAPI.setPromptStatus(prompt.globalPromptId.split("#")[1], "accepted").then(res => {
            getData()
        })

    }
    const rejectGift = () => {
        userAPI.setPromptStatus(prompt.globalPromptId.split("#")[1], "rejected").then(res => {
            getData()
        })
    }

    if (isMine) {
        return (
            <>
                <Modal open={isPaymentModalVisible}>
                    {isLoadingPayment == false && paymentOptions && <Elements stripe={stripePromise} options={paymentOptions}>
                        <CheckoutForm />
                    </Elements>}
                    {isLoadingPayment && <div className='flex-1 flex justify-center items-center'>
                        <Loader />
                    </div>}
                </Modal>
                <div className='p-5 bg-gray-100 rounded-lg flex justify-center items-center flex-col space-y-2'>
                    <h5
                        className='text-gray-500 text-sm font-semibold'
                    >
                        {componentLang.mystery_gift}
                    </h5>
                    <img
                        className='w-36 aspect-square object-contain rounded-3xl'
                        src={"https://ichdich-images-prod.s3.eu-west-1.amazonaws.com/" + extractImageData(product?.featuredAsset?.preview)}
                    />
                    <h5
                        className='text-gray-500 text-sm font-semibold max-w-[12rem] text-center'
                    >{product?.name}</h5>
                    <p
                        className='text-gray-400 text-xs  max-w-[12rem] text-center'
                    >
                        {removeHtmlTags(product?.description)?.slice(0, 100)}
                    </p>
                    {prompt && prompt.status == "accepted" && <div>
                        <Button type="primary" danger className='w-full my-2' loading={isLoadingPayment} onClick={onBuy}>
                            {componentLang.buy_it}
                        </Button>
                        <p className='max-w-[12rem] text-center text-xs text-gray-500'>
                            {componentLang.shippingF}
                        </p>
                    </div>}
                    {prompt && prompt.status == "pending" && <p>
                        {componentLang.waiting_for} {otherUserData.nickName} 
                    </p>}
                    {prompt && prompt.status == "rejected" && <p>
                        {otherUserData.nickName} {componentLang.user_rejected}
                    </p>}
                    {prompt && prompt.status == "paid" && <p>
                        {componentLang.on_its_way}
                    </p>}
                    {prompt && prompt.status == "fulfilled" && <p>
                        {componentLang.fulfilled}
                    </p>}
                </div>
            </>
        )
    } else {
        return (
            <div className='p-5 bg-gray-100 rounded-lg flex justify-center items-center flex-col space-y-2'>
                <h5
                    className='text-gray-500 text-sm font-semibold'
                >Gift from {otherUserData.nickName}</h5>
                <img
                    className='w-36 aspect-square object-contain rounded-3xl'
                    src={"https://ichdich-images-prod.s3.eu-west-1.amazonaws.com/" + extractImageData(product?.featuredAsset?.preview)}
                />
                <h5
                    className='text-gray-500 text-sm font-semibold max-w-[12rem] text-center'
                >{product?.name}</h5>
                <p
                    className='text-gray-400 text-xs  max-w-[12rem] text-center'
                >
                    {removeHtmlTags(product?.description)?.slice(0, 100)}
                </p>
                {prompt && prompt.status == "pending" && <div>
                    <Button type="primary" className='w-full my-2' onClick={() => {
                        acceptGift()
                    }}>
                        {componentLang.accept}
                    </Button>
                    <Button type="primary" danger className='w-full my-2' onClick={() => {
                        rejectGift()
                    }}>
                        {componentLang.reject}
                    </Button>
                </div>}
                {prompt && prompt.status == "accepted" && <p>
                    {componentLang.waiting_for} {otherUserData.nickName}
                </p>}
                {prompt && prompt.status == "rejected" && <p>
                    {componentLang.rejected}
                </p>}
                {prompt && prompt.status == "paid" && <p>
                    {componentLang.on_its_way}
                </p>}
                {prompt && prompt.status == "fulfilled" && <p>
                    {componentLang.fulfilled}
                </p>}
            </div>
        )
    }
}
